import React from 'react'
import BlurOnChangeInput from '../../../components/BlurOnChangeInput';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';

const PrevEmployeSalary = (props) => {
    const { data, handleInputChange, handleNumberInputOnBlur } = props;

    return (
        <>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                        <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">

                            <tbody>


                                <tr className="">
                                    <td width="35">
                                    </td>
                                    <td width="100" className="salaryprompre_textfs pl-2" colspan="2">
                                        Salary From previous Employer
                                    </td>

                                    <td width="130" className="text-right">
                                        <BlurOnChangeInput
                                            type="text"
                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                            value={FormatNumberDecimal(data?.previous_employer_amount_received_amtp ?? "", ...Array(2), false)}
                                            data-toggle="tooltip" data-placement="top" title={""}
                                            placeholder=""
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                if (convertNum(value) || data?.previous_employer_tds_amtp) {
                                                    data.previous_employer = true;
                                                } else {
                                                    data.previous_employer = false;
                                                }
                                                handleInputChange("previous_employer_amount_received_amtp", value)
                                            }}
                                            onBlur={(e) => handleNumberInputOnBlur("previous_employer_amount_received_amtp", data?.previous_employer_amount_received_amtp)}
                                        />
                                    </td>
                                    <td width="230">
                                        TDS already deducted on such salary
                                    </td>
                                    <td width="150" className="text-right">
                                        <BlurOnChangeInput
                                            type="text"
                                            className={`form-control salarytaxregime_edit_inputbox text-right`}
                                            value={FormatNumberDecimal(data?.previous_employer_tds_amtp ?? "", ...Array(2), false)}
                                            data-toggle="tooltip" data-placement="top" title={""}
                                            placeholder=""
                                            onChange={(e) => {
                                                const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                if (convertNum(value) || data?.previous_employer_amount_received_amtp) {
                                                    data.previous_employer = true;
                                                } else {
                                                    data.previous_employer = false;
                                                }
                                                handleInputChange("previous_employer_tds_amtp", value)
                                            }}
                                            onBlur={(e) => handleNumberInputOnBlur("previous_employer_tds_amtp", data?.previous_employer_tds_amtp)}
                                        />
                                    </td>

                                </tr>


                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default PrevEmployeSalary
/* eslint-disable import/prefer-default-export */
import store from '../app/store';
import history from '../app/history';
import { BASE_PATH, initialHeaderData , ACCOUNT_LOCKED_MESSAGE} from '../app/constants';
import { setHeader } from '../features/header/headerSlice';
import { redirectToLoginPage, saveRedirectUrl } from '../utils/UtilityFunctions';
import { clearAuthToken, updateAuthToken } from '../utils/AuthToken';

function setHeaderValues(header) {
  store.dispatch(setHeader({ ...initialHeaderData, ...header }));
}

export function handleSuccess(response, set_header = true) {
  if (set_header && response?.header) {
    setHeaderValues(response?.header);
  }

  // We may receive a new JWT Token back. So handle it
  handleRefreshToken(response);

  return response;
}
export function handleRefreshToken(response) {
  if (response?.header && response?.header?.refresh_token) {
    updateAuthToken(response?.header?.refresh_token);
  }
}

export function handleAllSuccess(response) {
  setHeaderValues({ ...response?.header, ...response?.data, gst_number: response?.header?.gst_number });

  // We may receive a new JWT Token back. So handle it
  handleRefreshToken(response);

  return response;
}
export function getFirstError(errors) {
  if (typeof errors === 'string') {
    return errors;
  }
  if (Array.isArray(errors)) {
    return errors[0];
  }

  const newError = errors[Object.keys(errors)[0]];
  return getFirstError(newError);
}

export function getFirstErrorWithCode(errors) {
  if (typeof errors === 'string') {
    return {
      code: 200,
      message: errors,
    };
  }
  if (Array.isArray(errors)) {
    return {
      code: 200,
      message: errors[0],
    };
  }

  const newError = errors[Object.keys(errors)[0]];
  return getFirstErrorWithCode(newError);
}

export function handleError(err) {
  if (typeof err === 'string') {
    return err;
  }

  if (!err?.response) return err;

  if (err?.response?.status === 401) {
    clearAuthToken()
    setHeaderValues({ header_fail: err?.response?.data?.errors });
    saveRedirectUrl();
    redirectToLoginPage();
  }

  if (err?.response?.status === 403) {
    history.push(`${BASE_PATH}`);
  }

  if (err?.response?.status === 500) {
    return 'Something went wrong. Please try again after sometime!';
  }

  if (err?.response?.data) {
    return getFirstError(err?.response?.data?.errors ? err.response.data.errors : '');
  }
  return err;
}

export function handleErrorWithCode(err) {
  if (typeof err === 'string') {
    return {
      code: 200,
      message: err,
    };
  }

  if (!err?.response) {
    return {
      code: 404,
      message: err.toString(),
    };
  }

  if (err?.response?.status === 401) {
    clearAuthToken()
    setHeaderValues({ header_fail: err?.response?.data?.errors });
    saveRedirectUrl();
    redirectToLoginPage();
  }

  if (err?.response?.status === 403) {
    history.push(`${BASE_PATH}`);
  }

  if (err?.response?.status === 423) { //invalid password handling    
    const url = `${BASE_PATH}forgetpass?error=${encodeURIComponent(err?.response?.data?.errors?.[0] ?? ACCOUNT_LOCKED_MESSAGE)}`;
    history.push(url);    
  }

  if (err?.response?.status === 500) {
    return {
      code: 500,
      message: 'Something went wrong. Please try again after sometime!',
    };
  }

  if(err?.response?.data instanceof Blob &&  err?.response?.data?.type === "application/json") { 
    return err;
  }

  if (err?.response?.data) {
    return {
      code: err?.response?.status,
      message: getFirstError(err?.response?.data?.errors),
    };
  }

  return {
    code: 500,
    message: err.toString(),
  };
}

export function handleAllErrorWithCode(err) {
  if (typeof err === 'string') {
    return {
      code: 200,
      message: err,
    };
  }

  if (!err?.response) {
    return {
      code: 500,
      message: err.toString(),
    };
  }

  if (err?.response?.status === 401) {
    clearAuthToken()
    setHeaderValues({ header_fail: err?.response?.data?.errors });
    saveRedirectUrl();
    redirectToLoginPage();
  }

  if (err?.response?.status === 403) {
    history.push(`${BASE_PATH}`);
  }

  if (err?.response?.status === 500) {
    return {
      code: 500,
      message: 'Something went wrong. Please try again after sometime!',
    };
  }

  if (err?.response?.data) {
    return {
      code: err?.response?.status,
      errors: err?.response?.data?.errors,
      status: err?.response?.data?.status,
    };
  }

  return {
    code: 500,
    message: err.toString(),
  };
}


export function handleConnectorError(err) {
  if (typeof err === 'string') {
    return {
      code: 200,
      message: err,
    };
  }

  if (!err?.response) { 
    return {
      code: 404,
      message: err.toString(),
    };
  }

  if (err?.response?.status === 500) {
    return {
      code: 500,
      message: 'Something went wrong. Please try again after sometime!',
    };
  }

  if (err?.response?.status === 424 || err?.response?.status === 401) {
    return {
      code: 400,
      message: err?.response?.data?.errors,
    };
  }

  if (err?.response?.data) {
    return {
      code: err?.response?.status,
      message: err?.response?.data?.messsage || err?.response?.data?.errors?.[0],
    };
  }

  return {
    code: 500,
    message: err.toString(),
  };
}

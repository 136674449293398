import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions';

const ViewIncHouseProperty = (props) => {
    const { data } = props;

    return (
        <>
            <tr className="salarytaxregime_edit_alternetbgclrtoprow_grey">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_incomeforhouse_collap01" aria-expanded="true"></span>

                </th>
                <th width="550" className="text-left">
                    Income From House Property
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data.total_house_property_income_amtp ?? 0)}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_incomeforhouse_collap01" className="in collapse show">


                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="150" className="text-left">
                                            Self Occupied
                                        </td>
                                        <td width="450" className="text-left"></td>
                                        <td width="120" className="text-right">
                                            {formatNumber(data.self_occupied_amtp ?? 0)}
                                        </td>

                                    </tr>

                                    <tr className="">

                                        <td width="">

                                        </td>
                                        <td width="" className="text-left">
                                            Let Out Property
                                        </td>
                                        <td width="" className="text-left "></td>
                                        <td width="" className="text-right">
                                            {formatNumber(data.let_out_amtp ?? 0)}
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>



                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewIncHouseProperty
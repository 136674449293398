import React from 'react';
import { Skeleton } from '@mui/material'
import { Link } from 'react-router-dom';
import { BASE_PATH } from '../../../app/constants';
import { Tooltip } from '@progress/kendo-react-tooltip'


const ShortDeductionCert = (props) => {

  const { deductorId, returnId, showSkeleton = false, shortDeductionSummaryData, ldcSummary, formType } = props;

  return (
    <>
      <div className="col-md-7 pt-1">
        <fieldset className="shortdeduction_fs h-100">
          <legend className="shortdeduction_leg">
            <div className="shortdeduction_inndev">
              <div className="shortdeduction_leftside">
                <h4>
                Short {formType === '27EQ' ? 'Collection' : ' Deduction'} 
                </h4>
              </div>
              <div className="shortdeduction_innerhieghtdiv"></div>
              <div className="shortdeduction_rightsidediv">
                <span className="shortdeduction_amttextclr_red">
                  {
                    Number(shortDeductionSummaryData?.lower_ded_cert_trnxs ?? 0) +
                    Number(shortDeductionSummaryData?.short_deduction_amtp_trnxs ?? 0) +
                    Number(shortDeductionSummaryData?.short_deduction_non_filer_trnxs ?? 0) +
                    Number(shortDeductionSummaryData?.short_deduction_pannotavbl_trnxs ?? 0) +
                    Number(shortDeductionSummaryData?.short_deduction_others_trnxs ?? 0) +
                    Number(shortDeductionSummaryData?.short_deduction_inoperative_trnxs ?? 0)+
                    Number((ldcSummary?.relooked ?? 0))
                  } {' '}
                </span>
                <span className="shortdeduction_transtextfs">
                  Trans. for
                </span>
                <span className="shortdeduction_amttextclr_red">
                  {' '} {shortDeductionSummaryData?.short_deduction_amtp ?? 0} Short TDS
                </span>
              </div>
              <div className="shortdeduction_middborder"></div>
            </div>
          </legend>
          <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
            <Tooltip anchorElement="pointer" position="top">
              <table className="table table-borderless pan_sumamry_table  mb-0">
                <tbody>
                  {
                    showSkeleton
                      ?
                      <>
                        <tr>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                        </tr>
                        <tr>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                          <td width="150"><Skeleton animation="wave" variant="rounded" width={"100%"} height={"40px"} /></td>
                        </tr>
                      </>
                      :
                      <>
                        <tr>
                          <td width="150">
                            {
                              shortDeductionSummaryData?.short_deduction_non_filer_trnxs > 0
                                ?
                                <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=43`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {shortDeductionSummaryData?.short_deduction_non_filer_trnxs ?? 0} {' '}
                                        <span className="trans_textfs black_txt">Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                          Non Filer
                                        </span><br />
                                        <span className=" comput_health_review_btnbox comp_health_reviewtop3box" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                : <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="Update Insights Credentials to see this information">0 
                                      <span className="trans_textfs black_txt"> Trans.</span></span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                        Non Filer
                                      </span>
                                    </div>
                                  </div>
                                </>

                            }

                          </td>
                          <td width="150">
                            {
                              shortDeductionSummaryData?.short_deduction_inoperative_trnxs > 0
                                ?
                                <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=42`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {shortDeductionSummaryData?.short_deduction_inoperative_trnxs ?? 0} {' '}
                                        <span className="trans_textfs black_txt">Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                          Inoperative
                                        </span><br />
                                        <span className=" comput_health_review_btnbox comp_health_reviewtop3box" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                :
                                <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="Run BULK PAN verification to see this information">0
                                      <span className="trans_textfs black_txt"> Trans.</span>
                                      </span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                        Inoperative
                                      </span>
                                    </div>
                                  </div>
                                </>
                            }

                          </td>
                          <td width="150">
                            {
                              shortDeductionSummaryData?.short_deduction_others_trnxs > 0
                                ? <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=44`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {shortDeductionSummaryData?.short_deduction_others_trnxs ?? 0} {' '}
                                        <span className="trans_textfs black_txt">Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                        Other Reasons
                                        </span><br />
                                        <span className=" comput_health_review_btnbox comp_health_reviewtop3box" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                : <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="No such transactions">0
                                      <span className="trans_textfs black_txt"> Trans.</span>
                                      </span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                      Other Reasons
                                      </span>
                                    </div>
                                  </div>
                                </>
                            }

                          </td>
                        </tr>

                        <tr>
                          <td width="150">
                            {
                              shortDeductionSummaryData?.short_deduction_amtp_trnxs > 0
                                ? <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=35`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {shortDeductionSummaryData?.short_deduction_amtp_trnxs ?? 0} {' '}
                                        <span className="trans_textfs black_txt">Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                          Due to Amount
                                        </span><br />
                                        <span className=" comput_health_review_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                : <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="No such transactions">0
                                      <span className="trans_textfs black_txt"> Trans.</span>
                                      </span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                        Due to Amount
                                      </span>
                                    </div>
                                  </div>
                                </>
                            }

                          </td>
                          <td width="150">
                            {
                              shortDeductionSummaryData?.short_deduction_pannotavbl_trnxs > 0
                                ? <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&additional_filter=41`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {shortDeductionSummaryData?.short_deduction_pannotavbl_trnxs ?? 0} {' '}
                                        <span className="trans_textfs black_txt">Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                          PANNOTAVBL
                                        </span><br />
                                        <span className=" comput_health_review_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                : <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="No such transactions">0
                                      <span className="trans_textfs black_txt"> Trans.</span>
                                      </span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                        PANNOTAVBL
                                      </span>
                                    </div>
                                  </div>
                                </>
                            }

                          </td>
                          <td width="150">
                            {
                              (ldcSummary?.relooked ?? 0) > 0
                                ? <>
                                  <Link
                                    target="_blank"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${deductorId}&return_id=${returnId}&duetoldc=true`}
                                    tabIndex="-1"
                                  >
                                    <div className="cardbox_contentborder">
                                      <span className="textnofsclr black_txt">
                                        {(ldcSummary?.relooked ?? 0)} {' '}
                                        <span className="trans_textfs black_txt"> Trans.</span>
                                      </span>

                                      <div className="invlid_textbox">
                                        <span className="invlid_textfsclr">
                                          Due to LDC
                                        </span><br />
                                        <span className=" comput_health_review_btnbox" data-toggle="tooltip" data-placement="top" title="Click to Review">
                                          Review
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                                : <>
                                  <div style={{ height: '55px' }} className="cardbox_contentborder_withouthoverbox" >
                                    <div className="textnofsclr black_txt">
                                      <span className="textnofsclr black_txt" data-toggle="tooltip" data-placement="top" title="No such transactions">0
                                      <span className="trans_textfs black_txt"> Trans.</span>                                      
                                      </span>
                                    </div>
                                    <div className="invlid_textbox" style={{ paddingTop: '2px' }}>
                                      <span className="invlid_textfsclr">
                                        Due to LDC
                                      </span>
                                    </div>
                                  </div>
                                </>
                            }
                          </td>
                        </tr>
                      </>
                  }
                </tbody>
              </table>
            </Tooltip>
          </div>
        </fieldset>
      </div>

    </>

  );
};

export default ShortDeductionCert;

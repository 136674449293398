import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from 'react-bootstrap/Spinner';
import { getPanWiseList } from '../../api/deductorAPI';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { BASE_PATH, DEDUCTOR_CATEGORIES, DEDUCTOR_CATEGORIES_TYPES } from '../../app/constants';
import { CustomCellList } from '../../components/grid/CustomCell';
import "./PanWiseDeductor.scss";

const PanWiseDeductor = () => {

    const location = useLocation();
    const searchStr = location.search;
    const history = useHistory();
    const params = queryString.parse(location.search);
    const limit = 19;
    const defaultcursor = { current_page: 1, next_page_url: null }
    const [dataState, setDataState] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [showMoreLoading, setShowMoreLoading] = useState([]);
    const [error, setError] = useState([]);
    const [cursor, setCursor] = useState(defaultcursor)
    const [scrollPagnation, setScrollPagnation] = useState(false);

    const createHistoryUrl = (filters) => {

        let filterStr = `?page=1`
        if (params.pan) {
            filterStr += `&pan=${params.pan}`;

        }
        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }

        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState(data_state);
    }

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const getParamsHistoryUrl = () => {

        const postData = {
            deductor_id: params.deductor_id,
            return_id: params.return_id,
        };

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
            postData.sort_on = params.sort_on ? params.sort_on : '';
            postData.order_by = params.order_by ? params.order_by : "";
        }

        if (params.filters) {
            dataState.filter = { filters: [] };
            dataState.filter.filters = JSON.parse(atob(params.filters));
            postData.filters = JSON.parse(atob(params.filters));
        }

        if (params.page) {
            postData.page = params.page;
        }


        postData.limit = params.limit ?? limit;

        setDataState(dataState);
        return postData;
    };

    const getPanWiseDeductorList = async (postData) => {
        if (postData.page > 1) {
            setShowMoreLoading(true);
        } else {
            setLoading(true);
        }
        try {
            const result = await getPanWiseList(postData);
            if (result?.data?.cursor?.current_page > 1) {
                setData(data.concat(result?.data?.data ?? []));
            } else {
                setData(result?.data?.data ?? []);
            }
            setCursor(result?.data?.cursor ?? defaultcursor);
        } catch (err) {
            if (err.message) {
                if (Array.isArray(err.message)) {
                    setError(err.message[0]);
                } else {
                    setError(err.message);
                }
            } else {
                setError(err);
            }
        } finally {
            setShowMoreLoading(false);
            setLoading(false);
            setScrollPagnation(false)
        }
    }

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem ?? {};
        const dataIndex = props.dataIndex ?? 0;
        return (
            <>
                <tr key={dataIndex}>
                    <td class={`text-center ${dataItem?.pan ? "cursor_pointer" : ""} dark_color_text `} style={{ paddingRight: "40px" }}>
                        {dataItem?.pan ? <span onClick={() => {
                            history.push(`${BASE_PATH}form3CD/incomplete-deductors?pan=${dataItem?.pan ?? ""}&deductor_name=${dataItem?.deductor_name ?? ""}`)
                        }}>
                            {dataItem?.pan ?? ""}
                        </span>
                            :
                            <span>
                                {dataItem?.pan ?? ""}
                            </span>}
                    </td>
                    <td class="text-left Panwisedeductor_master_textoverflow">
                        <span>
                            {dataItem?.deductor_name ?? ""}
                        </span>
                    </td>
                    <td class="text-left">
                        {DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? ""}
                    </td>

                </tr>
            </>
        )
    }

    $(function () {
        $(".tds_panwisedmaster_tb .k-grid-content").scroll(function () {
            if (getScrollbarHeight(document.querySelector('.tds_panwisedmaster_tb .k-grid-content')) < $(".tds_panwisedmaster_tb .k-grid-content").scrollTop() + $(".tds_panwisedmaster_tb .k-grid-content").height() + 3) {
                if (!scrollPagnation && cursor?.next_page > 1) {
                    setScrollPagnation(true);
                }
            }
        });
    });

    const getScrollbarHeight = (el) => {
        return el.scrollHeight
    };


    useEffect(() => {
        if (cursor?.next_page > 1) {
            const postData = getParamsHistoryUrl();
            postData.page = cursor.next_page ?? 1;
            getPanWiseDeductorList(postData);
            setShowMoreLoading(true);
        }
        // eslint-disable-next-line
    }, [scrollPagnation]);

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        getPanWiseDeductorList(postData);
        // eslint-disable-next-line
    }, [searchStr]);

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />
            <div className="panwise_deductorlist">
                <div class=" container-fluid mt-2">
                    <div class="row">
                        <div class="col-md-5">
                            <Link class="btn btn-default tds_panwise_deductmaster_backbtn" to={BASE_PATH}>&lt; Back to Deductor Master</Link>
                        </div>
                        <div class="col-md-7">
                            <h4 class="tds_panwise_deductmaster_backbtn">
                                Deductor Master (PAN Wise)
                            </h4>
                        </div>
                    </div>
                </div>

                <div class=" container mt-2">

                    <div class="row">

                        <div class="col-12 col-md-12 pt-2">

                            <div class="card tds_panwisedmaster_card p-0">
                                <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                    <Grid
                                        className="table table-striped tds_panwisedmaster_tb mb-0"
                                        data={data}
                                        {...dataState}
                                        sortable={false}
                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                            dropdown: [
                                                { text: 'grid.filterEqOperator', operator: 'in' },
                                            ],
                                            numeric: [
                                                { text: 'grid.filterGteOperator', operator: 'gte' },
                                                { text: 'grid.filterLteOperator', operator: 'lte' },
                                                { text: 'grid.filterLtOperator', operator: 'lt' },
                                                { text: 'grid.filterGtOperator', operator: 'gt' },
                                                { text: 'grid.filterEqOperator', operator: 'eq' },
                                                { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                            ],
                                            textWithEmpty: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                            ],
                                        }}
                                        rowRender={rowRender}
                                        style={{ maxHeight: "454px" }}
                                    >

                                        <Column
                                            width="205"
                                            field="pan"
                                            title={<CustomCellList title="PAN" field="pan" dataState={dataState} dataStateChange={dataStateChange} />}
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={`headingwiseclass ${isColumnActive('pan') ? 'active' : ''}`}
                                        />

                                        <Column
                                            field="deductor_name"
                                            title={<CustomCellList title="Name" field="deductor_name" dataState={dataState} dataStateChange={dataStateChange} />}
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={`headingwiseclass ${isColumnActive('deductor_name') ? 'active' : ''}`}
                                        />

                                        <Column
                                            width="305"
                                            field="deductor_category"
                                            title={<CustomCellList title="Deductor Type" field="deductor_category" dataState={dataState} dataStateChange={dataStateChange} />}
                                            filter="dropdown"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter filterList={DEDUCTOR_CATEGORIES_TYPES} {...props} />)}
                                            headerClassName={`headingwiseclass ${isColumnActive('deductor_category') ? 'active' : ''}`}
                                        />

                                    </Grid>
                                    <div className="col-sm-12 pb-1 text-center">
                                        {showMoreLoading
                                            &&
                                            (
                                                <span style={{ color: '#31719b' }}>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {' '}
                                                    Loading...
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panwiseded_master_SPwrapper">
                    <div class="panwiseded_master_innerwrapper">
                        <p class="panwiseded_master_textfs">Click on PAN to start 3CD report</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PanWiseDeductor
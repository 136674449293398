import React, { useEffect, useState } from 'react'
import ShowAlert from '../../../components/ShowAlert';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { convertNum, formatNumber } from '../../../utils/UtilityFunctions';
import { gocallApi } from '../../../api/issueCertificateAPI';
import SkeletonNoticeOrder from '../../../components/Skeleton/SkeletonNoticeOrder';
import useIntersection from '../../../utils/useIntersection';

const OutstandingDemand = (props) => {
    const { deductor_Id, financialYear, outStandingRef } = props;

    const inViewport = useIntersection(outStandingRef, "0px");


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [outStandingSkeleton, setOutStandingSkeleton] = useState(false);

    const skeletonColgroup = ["100", "100", "100", "100", "100", "100", "100", "100", "100", "100"];
    const skeletonMainHead = [
        { colSpan: 1, },
        { colSpan: 7 },
        { colSpan: 1, },
        { colSpan: 1, },
    ];

    const skeletonSubHead = [
        { type: "none" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "none" },
        { type: "none" },
    ];

    const skeletonMinSubHead = [
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
        { type: "skeleton" },
    ]

    const getOutstandingDemand = async (parameter) => {

        const { deductor_Id = null, cursor = {}, financialYear = null } = parameter;
        let url = `/api/v1/report/deductor/outstanding-demands?financial_year=${financialYear}&deductor_id=${deductor_Id}`;

        try {
            const result = await gocallApi("get", url);
            setData(result?.data ?? []);
        } catch (err) {
            if (err?.message) setError(err.message);
            else setError(err);
        } finally {
            setOutStandingSkeleton(false);
            if (cursor?.next_page) setLoading(false);
        }
    }

    const totalProcesDemand = (dataItem) => {
        let total = convertNum(dataItem.short_pymt_amtp) + convertNum(dataItem.short_ded_amtp) +
            convertNum(dataItem.intrst_pymt_amtp) + convertNum(dataItem.intrst_ded_amtp) + convertNum(dataItem.late_fill_amtp) + convertNum(dataItem.intrst_220_2_amtp);
        return total
    }

    useEffect(() => {
        if(inViewport){
            getOutstandingDemand({ deductor_Id: deductor_Id, financialYear })
        }
    }, [deductor_Id, financialYear, inViewport])


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />
            

            {loading && <LoadingIndicator />}

            {outStandingSkeleton ? <SkeletonNoticeOrder title="Outstanding Demand" row={5} cell={10} colgroup={skeletonColgroup} subHead={skeletonSubHead} mainHead={skeletonMainHead} skeletonMinSubHead={skeletonMinSubHead} /> :
                <div class="col-md-12">
                    <fieldset class="Deductor_dashboard_outstdemand_fs">
                        <legend class="Deductor_dashboaroutstandingdemand">
                            <h4>Outstanding Demand</h4>
                        </legend>
                        <div
                            class={`table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs ${data.length > 5 ? "outstandingdemandscroll" : ""}`}>
                            <table class="table table-striped Deductor_dashboard_outstdemand_tb mb-0">
                                <colgroup>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                    <col width="100"></col>
                                </colgroup>

                                <thead>
                                    <tr class="Deductor_dashboard_outstdemand_toprow">
                                        <th>
                                            Form | Qtr
                                        </th>
                                        <th colspan="7" class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Processed Demand (Justification available from CPC)
                                        </th>
                                        <th>
                                            Manual Demand
                                            (Justification
                                            Available with AO)
                                        </th>
                                        <th>
                                            Total
                                        </th>
                                    </tr>

                                    <tr class="Deductor_dashboard_outstdemand_2row">
                                        <th></th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Short Payment
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Short Deduction
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Int. on Payments Default u/s 201
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Int. on Deduction Default u/s 201
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Late Filing Fee u/s 234E
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Interest u/s 220(2)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            Total Processed
                                            Demand
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr class="Deductor_dashboard_outstdemand_3row">
                                        <th></th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (A)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (B)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (C)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (D)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (E)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (F)
                                        </th>
                                        <th class="Deductor_dashboard_outstdemand_rowbgclr_yell">
                                            (G = Sum of A:F)
                                        </th>
                                        <th>(H)</th>
                                        <th>(I = G + H)</th>
                                    </tr>
                                </thead>
                                <tbody className="outstandintbody"  ref={outStandingRef}>
                                    {data?.length > 0 ? data.map((item) => {
                                        return (
                                            <tr >
                                                <td class="text-center">
                                                    {item?.form_type ? `${item.form_type} | ${item.quarter}` : ""}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item?.short_pymt_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item?.short_ded_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item?.intrst_pymt_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item?.intrst_ded_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item?.late_fill_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(item.intrst_220_2_amtp ?? "", "-", false)}
                                                </td>
                                                <td class="text-right Deductor_dashboard_outstdemand_rowbgclr_yell">
                                                    {formatNumber(totalProcesDemand(item), "-", false)}
                                                </td>
                                                <td class="text-right ">
                                                    {formatNumber(item.manual_demand_amtp ?? "", "-", false)}
                                                </td>
                                                <td className="text-right">
                                                    {formatNumber(totalProcesDemand(item) + item.manual_demand_amtp, "-", false)}
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <tr><td colSpan={10} className="text-center">No Outstanding Demand for the selected Financial year.</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>}
        </>
    )
}

export default OutstandingDemand
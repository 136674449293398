import React, { useState, useEffect } from 'react';
import dwnloadPdf from "../../../images/downloadpdf.png";
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../../header/headerSlice';
import dwnloadExcel from "../../../images/download_excel.png";
import { gocallApi } from '../../../api/issueCertificateAPI';
import Skeleton6ColumnGrid from '../../../components/Skeleton/Skeleton6ColumnGrid';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { downloadReturnFilingReceipt } from '../../../api/ReturnRegister';
import ShowAlert from '../../../components/ShowAlert';
import FillingListPopup from './FillingListPopup';
import PanStatus from './PanStatus';
import { deepClone } from '../../../utils/UtilityFunctions';
import moment from 'moment';
import { downloadCorrectionTxtFile, downloadTxtFile, getReturnsFilingData } from '../../../api/interestFeesCalculatorAPI';
import { checkConso, exportRegularReturn, exportCorretionReturn } from '../../../api/returnAPI';
import { FILE_LIST_PROTOTYPE } from '../../../app/constants';
import { batchHeaderRecordColumns, challanDetailsColumns, deducteeDetailsColumns, fileHeaderRecordColumns, salaryDetailsColumns } from '../../returnDashboard/pdfTemplate/ReturnPdfKeys';
import PDF24Q from '../../returnDashboard/pdfTemplate/PDF24Q';
import PDF26Q from '../../returnDashboard/pdfTemplate/PDF26Q';
import PDF27EQ from '../../returnDashboard/pdfTemplate/PDF27EQ';
import PDF27Q from '../../returnDashboard/pdfTemplate/PDF27Q';
import CorrectionConfirmationPopup from './CorrectionConfirmationPopup';
import RaisedFileImportPopup from './RaisedFileImportPopup';
import ConfirmPopupNew from '../../../components/ConfirmPopupNew';
import DownloadFile from '../../../components/DownloadFile';
import Spinner from 'react-bootstrap/Spinner';

import CRhelp_IconW from "../../../images/CRhelp_IconW.png";
import ReurnDashboardVPopup from '../ReurnDashboardVPopup';



const ReturnSummary = (props) => {

    const { deductorId, financialYear, panStatusData, indiviualQuarterSync, startFilingPopup, setStartFilingPopup } = props;

    const headerData = useSelector(selectHeaderData);
    const processingStatus = ["Rejected at e-filing", "Processed With Defaults"];
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [GovtPortdata, setGovtPortData] = useState([]);
    const [skeletonIndicadtor, setSkeletonIndicadtor] = useState(true);
    const [filterFormType, setFilterFormType] = useState(new Set());
    const [filterQuarterType, setFilterQuarterType] = useState(new Set());
    const [filingData, setFilingData] = useState(FILE_LIST_PROTOTYPE);
    const [filingCursor, setFilingCursor] = useState({ current_page: 1, next_page_url: null });
    const [filingPopup, setFilingPopup] = useState(false);
    const [stickyNotesStatsData, setStickyNotesStatsData] = useState([]);
    const [stickyNotesReturnsData, setStickyNotesReturnsData] = useState();
    const [defaultData, setDefaultData] = useState(null);
    const [prnData, setPrnData] = useState({});
    const [formType, setFormType] = useState(false);
    const [PDFTemplateData, setPDFTemplateData] = useState(false);
    const [consoFileAlready, setConsoFileAlready] = useState(false);
    const [importFilePopup, setImportFilePopup] = useState(false);
    const [returnPayload, setReturnPayload] = useState({});
    const [ShowToastMessage, setShowToastMessage] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [returnId, setReturnId] = useState(null);
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadReturnId, setDownloadReturnId] = useState(false);
    const [downloadReturnType, setDownloadReturnType] = useState(false);
    const [skeletonList, setSkeletonList] = useState(true);

    const [videoDemoPopup, setVideoDemoPopup] = useState(false);

    const clickCorrectionWorkOnThis = (dataItem) => {
        if (dataItem.return_type === "REGULAR") {
            if (dataItem.return_id && indiviualQuarterSync) {
                indiviualQuarterSync(dataItem);
            }
        } else if (dataItem.return_type === "CORRECTION") {
            const Payload = {
                deductor_id: deductorId,
                form_type: dataItem.form_type ?? "",
                quarter: dataItem.quarter ?? "",
                return_type: "CORRECTION",
                year: financialYear,
                return_id: dataItem?.return_id ?? "",
                efiling_status: dataItem?.efiling_status ?? "",
                ack_no: dataItem?.ack_no ?? "",
                filing_date: dataItem.filing_date ?? ""

            }
            setReturnId(dataItem?.return_id ?? "")
            setReturnPayload(Payload);
            if (dataItem.return_id) {
                clickCorrectionCheckConsoOnServer(dataItem)
            }

        } else {
            setError("Return Not Created");
        }
    }

    const clickCorrectionCheckConsoOnServer = async (returnItem) => {
        setLoading(true);
        let returnID = returnItem.return_id;

        try {
            const request = `?deductor_id=${deductorId}&return_id=${returnID}`;
            const results = await checkConso(request);
            openConsoFileFoundInSystem(results);

        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(''), 1500);
        } finally {
            setLoading(false);
        }
    };

    const openConsoFileFoundInSystem = (results = {}) => {
        if (['CONSO_IMPORTED', 'REQUEST_SUBMITTED', 'IMPORT_IN_PROGRESS'].includes(results?.data?.conso_file_status) && results?.data?.file_found) {
            setConsoFileAlready(true);
        } else {
            setImportFilePopup(true);
        }

    };

    const getSummaryData = (deductorId, financialYear, updateFiling, startFilingformType) => {
        async function fetchEverything(deductorId, financialYear) {
            const url = `/api/v1/report/deductor/return-summary?deductor_id=${deductorId}&financial_year=${financialYear}`;
            setLoading(true);
            const rtnSumData = [];
            const rtnSumgovData = [];
            let return_progress = [];

            try {
                const result = await gocallApi("get", url);

                if (result?.data?.length > 0) {
                    result.data = result?.data.sort((a, b) =>
                        (a.filing_date !== null && b.filing_date === null) ? -1 : 1)
                        .sort((a, b) => a.quarter < b.quarter ? 1 : -1)
                        .sort((a, b) => a.form_type < b.form_type ? -1 : 1);

                    result?.data?.forEach((item) => {
                        if (item.return_id) {
                            rtnSumData.push(item);
                            if (!item.filing_date) return_progress.push(item);
                        }
                        else if (!item.return_id) {
                            rtnSumgovData.push(item);
                        }

                    })

                }
                
                if (return_progress?.length > 0) {
                    return_progress = return_progress.sort((a, b) =>
                        moment(a?.updated_at ?? 0).unix() > moment(b?.updated_at ?? 0).unix() ? -1 : 1)
                }    

                setData(rtnSumData);
                setGovtPortData(rtnSumgovData);
                setDefaultData(result?.data ?? []);

                setStickyNotesReturnsData([...return_progress]);

                if (updateFiling) {
                    startFilingformType(result?.data ?? []);
                }
  
            } catch (err) {
                console.error('Error', err);
                if (err?.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
                setSkeletonIndicadtor(false);
            }
        }
        fetchEverything(deductorId, financialYear)
    }

    const getReturnWiseStatsData = (deductorId, financialYear) => {
        async function fetchEverything(deductorId, financialYear) {
            const url = `/api/v1/report/deductor/return-in-progress-stats?deductor_id=${deductorId}&financial_year=${financialYear}`;
            setLoading(true);
            setSkeletonList(true)

            try {
                const result = await gocallApi("get", url);

                if (result?.data?.length > 0) {
                    result.data = result?.data.sort((a, b) =>
                        moment(a?.updated_at ?? 0).unix() > moment(b?.updated_at ?? 0).unix() ? -1 : 1)
                }

                // setStickyNotesStatsData(result?.data ?? []); 
                setStickyNotesStatsData((prev) => {
                    prev = result?.data ?? [];
                    return [...prev]
                })       
  
            } catch (err) {
                console.error('Error', err);
                if (err?.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
                setSkeletonList(false)
            }
        }
        fetchEverything(deductorId, financialYear);
    }

    const formFilterFun = (formTypes, quarterTypes) => {

        let fltrArray = [];

        if (defaultData.length > 0) {
            if (formTypes?.size === 0 && quarterTypes?.size === 0) {

                const manualData = [];
                const govtPortData = [];
                defaultData.forEach((item) => {
                    if (item.return_id) manualData.push(item);
                    else if (!item.return_id) govtPortData.push(item);
                })

                setData(manualData);
                setGovtPortData(govtPortData);

            } else {

                defaultData.forEach((item) => {
                    if (formTypes?.size > 0 && formTypes.has(item.form_type)) {
                        if (quarterTypes?.size > 0 && quarterTypes.has(item.quarter)) {
                            fltrArray.push(item);
                        } else if (quarterTypes?.size === 0) {
                            fltrArray.push(item);
                        }
                    } else if (formTypes?.size === 0 && quarterTypes?.size > 0 && quarterTypes.has(item.quarter)) {
                        fltrArray.push(item);
                    }
                })

                if (fltrArray.length > 0) {
                    const manualData = [];
                    const govtPortData = [];
                    fltrArray.forEach((item) => {
                        if (item.return_id) manualData.push(item);
                        else if (!item.return_id) govtPortData.push(item);
                    })

                    setData(manualData);
                    setGovtPortData(govtPortData);

                } else {
                    setData(fltrArray);
                    setGovtPortData([]);

                }

            }
        }
    };


    const startFilingformType = (defaultData) => {
        if (defaultData.length > 0) {
            const filingList = deepClone(FILE_LIST_PROTOTYPE);
            defaultData.forEach((item) => {
                const formtype = filingList[item.form_type] ?? null;
                const qtrtype = filingList?.[item.form_type]?.[item.quarter] ?? null;
                const dataItem = deepClone(item);

                if (formtype && !qtrtype) {
                    if (item.return_type === "REGULAR" && item.efiling_status && item.efiling_status !== "Rejected at e-filing") {
                        dataItem.return_type = "CORRECTION";
                        dataItem.return_id = null;
                    }

                    filingList[item.form_type][item.quarter] = dataItem;

                } else if (qtrtype && qtrtype?.return_type === "REGULAR" && item.return_type === "CORRECTION" && item.return_id) {

                    filingList[item.form_type][item.quarter] = dataItem;
                } else if (
                    qtrtype && qtrtype?.return_type === "CORRECTION"
                    && item.return_type === "CORRECTION"
                    && item.return_id
                    && (moment(qtrtype?.created_at ?? 0).unix() < moment(item?.created_at ?? 0).unix())
                ) {
                    filingList[item.form_type][item.quarter] = dataItem;
                }

                if (item?.return_type === "REGULAR" && filingList?.[item.form_type]?.[item.quarter]) {
                    filingList[item.form_type][item.quarter]["regular_date"] = dataItem?.filing_date ?? "";
                }
            });


            setFilingData(filingList);
        }
        setFilingPopup(true);
    }


    const filterChange = (type, value) => {

        if (!defaultData) return;

        const formTypes = new Set(filterFormType);
        const quarterTypes = new Set(filterQuarterType)

        if (type === 'form_type') {
            if (formTypes.has(value)) {
                formTypes.delete(value);
            } else {
                formTypes.add(value)
            }
            setFilterFormType((prev) => {
                prev = formTypes
                return prev
            })
        } else if (type === 'quarter') {
            if (quarterTypes.has(value)) {
                quarterTypes.delete(value);
            } else {
                quarterTypes.add(value)
            }
            setFilterQuarterType((prev) => {
                prev = quarterTypes
                return prev
            })
        }
        formFilterFun(formTypes, quarterTypes);
        // startFilingformType();
    }

    const downloadReciept = (dataItem) => {
        async function fetchEverything() {
            setLoading(true);
            const payload = `?deductor_id=${deductorId ?? ""}&ack_no=${dataItem.ack_no ?? ""}`;
            const filePath = `${headerData?.tan ?? ""}_${financialYear ?? ""}_${dataItem?.form_type ?? ""}_${dataItem?.quarter ?? ""}_`
            try {
                await downloadReturnFilingReceipt(payload, filePath);
            } catch (err) {
                console.error(err)
                if (err?.message) setError(err.message);
                else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    }

    async function downloadFilingData(return_id, form_Type, return_type) {
        async function callDownloadAPI() {
            async function downloadTxtFileFnc() {
                const downloadFileStr = `?deductor_id=${deductorId}&return_id=${return_id}&file_format=txt`;
                const result = return_type === "CORRECTION" ? await downloadCorrectionTxtFile(downloadFileStr) : await downloadTxtFile(downloadFileStr);
                return result;
            }
            try {
                const result = await downloadTxtFileFnc();
                const csiData = new Blob([result.data], { type: 'application/plain' });
                downloadReturnPdf(csiData, form_Type);
            } catch (err) {
                setLoading(false);
                setError(err.message);
            }
        }
        setLoading(true);
        getReturnsPRNNumberData(return_id);
        callDownloadAPI();
    }

    async function getReturnsPRNNumberData(return_id) {
        async function callPRNNumberAPI() {
            async function getReturnsPRNNumberFnc() {
                return await getReturnsFilingData(`?deductor_id=${deductorId}&return_id=${return_id}`);
            }
            try {
                const prn_data = await getReturnsPRNNumberFnc();
                setPrnData(prn_data?.data ?? {});

            } catch (err) {
                setError(err.message);
            }
        }
        setLoading(true);
        callPRNNumberAPI();
    }

    const downloadReturnPdf = (file, form_type) => {
        let result = [];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async (e) => {
            setLoading(true);
            let text = (e.target.result);
            var lines = text.split('\n');
            let col_data = [];
            var i = 0;

            result['file_header'] = [];
            result['batch_header'] = [];

            for (var line = 0; line < lines.length; line++) {
                col_data = lines[line].split("^");


                let challan_details = [];
                let deductee_details = [];
                let salary_details = [];

                for (i = 0; i < col_data.length; i++) {

                    // set file header data
                    if (col_data[1] === 'FH') {
                        if (form_type === '24Q') {
                            result['file_header'][fileHeaderRecordColumns.keys24Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '26Q') {
                            result['file_header'][fileHeaderRecordColumns.keys26Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '27EQ') {
                            result['file_header'][fileHeaderRecordColumns.keys27EQ[i]] = col_data[i].trim();
                        }
                        if (form_type === '27Q') {
                            result['file_header'][fileHeaderRecordColumns.keys27Q[i]] = col_data[i].trim();
                        }
                    }

                    // set batch header data
                    if (col_data[1] === 'BH') {
                        if (form_type === '24Q') {
                            result['batch_header'][batchHeaderRecordColumns.keys24Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '26Q') {
                            result['batch_header'][batchHeaderRecordColumns.keys26Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '27EQ') {
                            result['batch_header'][batchHeaderRecordColumns.keys27EQ[i]] = col_data[i].trim();
                        }
                        if (form_type === '27Q') {
                            result['batch_header'][batchHeaderRecordColumns.keys27Q[i]] = col_data[i].trim();
                        }
                    }

                    // set challan detail data
                    if (col_data[1] === 'CD') {
                        if (form_type === '24Q') {
                            challan_details[challanDetailsColumns.keys24Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '26Q') {
                            challan_details[challanDetailsColumns.keys26Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '27EQ') {
                            challan_details[challanDetailsColumns.keys27EQ[i]] = col_data[i].trim();
                        }
                        if (form_type === '27Q') {
                            challan_details[challanDetailsColumns.keys27Q[i]] = col_data[i].trim();
                        }
                    }

                    // set deductee detail data
                    if (col_data[1] === 'DD') {
                        if (form_type === '24Q') {
                            deductee_details[deducteeDetailsColumns.keys24Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '26Q') {
                            deductee_details[deducteeDetailsColumns.keys26Q[i]] = col_data[i].trim();
                        }
                        if (form_type === '27EQ') {
                            deductee_details[deducteeDetailsColumns.keys27EQ[i]] = col_data[i].trim();
                        }
                        if (form_type === '27Q') {
                            deductee_details[deducteeDetailsColumns.keys27Q[i]] = col_data[i].trim();
                        }
                    }

                    // set salary detail data
                    if (col_data[1] === 'SD') {
                        salary_details[salaryDetailsColumns.keys24Q[i]] = col_data[i];
                    }
                }


                if (Object.keys(challan_details).length > 0) {
                    if (result['challan_details']) {
                        result['challan_details'][result['challan_details'].length] = challan_details;
                    }
                    else {
                        result['challan_details'] = [challan_details];
                    }
                }

                if (Object.keys(deductee_details).length > 0) {

                    if (result['challan_details'][result['challan_details'].length - 1]['deductee_details']) {
                        result['challan_details'][result['challan_details'].length - 1]['deductee_details'][result['challan_details'][result['challan_details'].length - 1]['deductee_details'].length] = deductee_details;
                    }
                    else {
                        result['challan_details'][result['challan_details'].length - 1]['deductee_details'] = [deductee_details];
                    }
                }

                if (Object.keys(salary_details).length > 0) {
                    if (result['salary_details']) {
                        result['salary_details'][result['salary_details'].length] = salary_details;
                    }
                    else {
                        result['salary_details'] = [salary_details];
                    }
                }


                // set salary extra detail data
                if (col_data[1] === 'S16') {

                    if (col_data[5] === '16(ia)') {
                        result['salary_details'][result['salary_details'].length - 1]['deduction16_ia'] = col_data[6];
                    }

                    if (col_data[5] === '16(ii)') {
                        result['salary_details'][result['salary_details'].length - 1]['deduction16_ii'] = col_data[6];
                    }

                    if (col_data[5] === '16(iii)') {
                        result['salary_details'][result['salary_details'].length - 1]['deduction16_iii'] = col_data[6];
                    }

                }
                if (col_data[1] === 'C6A') {

                    if (col_data[5] === '80C') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80C_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80C_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80C_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80CCC') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCC_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCC_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCC_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80CCD(1)') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80CCD(1B)') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1B_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1B_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD1B_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80CCD(2)') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD2_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD2_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCD2_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80D') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80D_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80D_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80D_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80E') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80E_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80E_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80E_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === '80G') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80G_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80G_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80G_dedu_amt'] = col_data[9];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80G_quli_amt'] = col_data[8];
                    }

                    if (col_data[5] === '80TTA') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80TTA_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80TTA_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80TTA_dedu_amt'] = col_data[9];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80TTA_quli_amt'] = col_data[8];

                    }
                    if (col_data[5] === '80CCH') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_dedu_amt'] = col_data[9];
                    }
                    if (col_data[5] === '80CCH(1)') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_1_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_1_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_80CCH_1_dedu_amt'] = col_data[9];
                    }

                    if (col_data[5] === 'OTHERS') {
                        result['salary_details'][result['salary_details'].length - 1]['VIA_OTHERS_deductible'] = col_data[6];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_OTHERS_gross_amt'] = col_data[7];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_OTHERS_dedu_amt'] = col_data[9];
                        result['salary_details'][result['salary_details'].length - 1]['VIA_OTHERS_quli_amt'] = col_data[8];

                    }

                }
            };
            setPDFTemplateData(result);
        }
    }


    useEffect(() => {
        getSummaryData(deductorId, financialYear);
        getReturnWiseStatsData(deductorId, financialYear);
        setFilterFormType(new Set());
        setFilterQuarterType(new Set());
    }, [deductorId, financialYear])


    const capitalizeFLetter = (string) => {
        string = string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const exportReturn = (return_id, return_type) => {
        setDownloadReturnId(return_id)
        setDownloadExcelFile(true);
        setDownloadReturnType(return_type);
    }

    useEffect(() => {
        if(startFilingPopup){
            if (defaultData) {
                startFilingformType(defaultData)
            }
        }
    },[startFilingPopup, defaultData])


    return (
        <>
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {!skeletonIndicadtor && loading && <LoadingIndicator />}

            {downloadExcelFile && downloadReturnId !== null &&
                <DownloadFile
                    apiFunction={downloadReturnType === 'CORRECTION' ?  exportCorretionReturn : exportRegularReturn}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS-ReturnData'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-return-ref-key-${deductorId}-${financialYear}`}
                    params={`?deductor_id=${deductorId}&return_id=${downloadReturnId}&year=${financialYear}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                        setDownloadReturnId(null);
                        setDownloadReturnType(null);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                        setDownloadReturnId(null);
                        setDownloadReturnType(null);
                    }}
                />
            }

            {showPopup &&
                <ConfirmPopupNew
                    show={showPopup}
                    handleClose={() => {
                        setShowPopup(false);
                        setShowToastMessage(null);

                    }}
                    confirmText={ShowToastMessage}
                    confirmButtonText={"Ok"}
                    handleProceed={() => {
                        setShowPopup(false);
                        setShowToastMessage(null);
                    }}
                />
            }

            {filingPopup &&
                <FillingListPopup
                    data={filingData}
                    setData={setFilingData}
                    cursor={filingCursor}
                    setFilingCursor={setFilingCursor}
                    show={filingPopup}
                    handleClose={() => {
                        setFilingPopup(false);
                        if(setStartFilingPopup)setStartFilingPopup(false)
                    }}
                    financialYear={financialYear}
                    deductorId={deductorId}
                    allReturnData={defaultData}
                    successProced={() => {
                        getSummaryData(deductorId, financialYear);
                        getReturnWiseStatsData(deductorId, financialYear);
                        setFilterQuarterType(new Set());
                        setFilterFormType(new Set());
                        if(setStartFilingPopup)setStartFilingPopup(false)
                    }}
                    updateFilingData={(notFilingUpdate) => {
                        getSummaryData(deductorId, financialYear, true, startFilingformType);
                        getReturnWiseStatsData(deductorId, financialYear);
                    }}
                    indiviualQuarterSync={indiviualQuarterSync}
                    startFilingPopup={startFilingPopup}
                />
            }

            {consoFileAlready &&
                <CorrectionConfirmationPopup
                    show={consoFileAlready}
                    handleClose={() => setConsoFileAlready(false)}
                    createNewReturn={() => {
                        setImportFilePopup(true);
                    }}
                    returnId={returnId}
                    allReturnData={defaultData}
                    returnPayload={returnPayload}
                    successProced={() => {
                        getSummaryData(deductorId, financialYear);
                        getReturnWiseStatsData(deductorId, financialYear);
                        setFilterQuarterType(new Set());
                        setFilterFormType(new Set());
                    }}
                    deductorId={deductorId}
                    financialYear={financialYear}
                />
            }

            {importFilePopup &&
                <RaisedFileImportPopup
                    show={importFilePopup}
                    handleClose={() => setImportFilePopup(false)}
                    deductorId={deductorId}
                    returnPayload={returnPayload}
                    returnId={returnId}
                    financialYear={financialYear}
                />
            }

                {videoDemoPopup && 
                    <ReurnDashboardVPopup 
                    show={videoDemoPopup}
                    onHide={() => setVideoDemoPopup(false)}
                    />
                }

            <div className="row">
                <div className="col-xl-7 col-lg-12 col-md-12">
                    <fieldset className="Deductor_dashboard_fs">
                        <legend className="Deductor_dashboard_leg pb-0 mb-0">
                            <div className="Deductor_dashboard_inndev">
                                <div className="Deductor_dashboard_lefts">
                                    <h4>Return Summary</h4>
                                </div>
                                <div className="Deductor_dashboard_inner"></div>
                                <div className="Deductor_dashboard_right">
                                    <button className='btn btn-default Deductor_dashboard_infoiconbox ml-1'>
                                        <span data-toggle="tooltip" data-placement="top" title="Show Create Return Help">
                                            <img alt="Create Return Help" src={CRhelp_IconW} width={25} className='Deductor_dashboard_infoiconclr' onClick={() => setVideoDemoPopup(true)}/>
                                        </span>
                                    </button>
                                    <button className="Deductor_dashboard_startfilingbtn zps_dd_btn_start_filing planexpiredalert" onClick={() => {
                                        if (defaultData) {
                                            startFilingformType(defaultData)
                                        }
                                    }}>
                                        Start Filing
                                    </button>
                                </div>
                                <div className="Deductor_dashboard_middleborder">
                                    <span className="Deductor_dashboard_qbox">
                                        <span style={{ cursor: 'pointer' }} className={`${filterFormType.has('24Q') ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'}`} onClick={() => filterChange('form_type', '24Q')}>24Q</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterFormType.has('26Q') ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} mx-1`} onClick={() => filterChange('form_type', '26Q')}>26Q</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterFormType.has('27Q') ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} `} onClick={() => filterChange('form_type', '27Q')}>27Q</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterFormType.has('27EQ') ? 'Quarterbox_border_coralclr' : 'Quarterbox_border_greyclr'} mx-1`} onClick={() => filterChange('form_type', '27EQ')}>27EQ</span>
                                        <span className='px-1' style={{ fontSize: '13px', fontWeight: '800' }}>
                                            <span className='mr-1'>|</span>
                                        </span>
                                        <span style={{ cursor: 'pointer', paddingRight: "8.2px" }} className={`${filterQuarterType.has('Q1') ? 'Quarterbox_border_greenlclr' : 'Quarterbox_border_greenclr'}`} onClick={() => filterChange('quarter', 'Q1')}>Q1</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterQuarterType.has('Q2') ? 'Quarterbox_border_greenlclr' : 'Quarterbox_border_greenclr'} mx-1`} onClick={() => filterChange('quarter', 'Q2')}>Q2</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterQuarterType.has('Q3') ? 'Quarterbox_border_greenlclr' : 'Quarterbox_border_greenclr'} mx-1`} onClick={() => filterChange('quarter', 'Q3')}>Q3</span>
                                        <span style={{ cursor: 'pointer' }} className={`${filterQuarterType.has('Q4') ? 'Quarterbox_border_greenlclr' : 'Quarterbox_border_greenclr'} mx-1`} onClick={() => filterChange('quarter', 'Q4')}>Q4</span>

                                    </span>
                                </div>
                            </div>
                        </legend>

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ minHeight: "171px", maxHeight: "171px" }}>
                            <div className='tds_returnsumm_createret_textwarp createret_toptbwrap'>
                                Created Returns
                            </div>
                            {
                                skeletonIndicadtor
                                    ? <Skeleton6ColumnGrid rowNo={8} />
                                    :
                                    <table className="table table-striped Deductor_dashboard_tb mb-0">
                                        <thead>
                                            <tr>
                                                <th width="120">Form | Qtr</th>
                                                <th width="100">Filing Date</th>
                                                <th width="90">Filing Type</th>
                                                <th width="120">Processing Status</th>
                                                <th width="90">Ack Receipt</th>
                                                <th width="120">Download Return</th>
                                                <th width="90"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.length > 0
                                                    ?
                                                    data.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{item?.form_type ?? '-'} | {item?.quarter ?? '-'}</td>
                                                                <td className="text-center">
                                                                    {item?.filing_date ? moment(item?.filing_date).format("DD/MM/YYYY") : "In progress"}</td>

                                                                {item.return_id ?
                                                                    <td className="text-left notice_order_textclr_blue hyperlink planexpiredalert" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            clickCorrectionWorkOnThis(item);
                                                                        }}
                                                                    >
                                                                        {item?.return_type ? capitalizeFLetter(item?.return_type) : '-'}
                                                                    </td>
                                                                    :
                                                                    <td className="text-left">
                                                                        {item?.return_type ? capitalizeFLetter(item?.return_type) : '-'}
                                                                    </td>}

                                                                <td className={`text-left ${processingStatus.includes(item?.efiling_status) ? "Processingst_clrred" : ""}`}>
                                                                    {item?.efiling_status ?? 'NA'}
                                                                </td>

                                                                <td className="text-center">
                                                                    {item.ack_no && item?.efiling_status !== 'Rejected at e-filing' ?
                                                                        <img title={"Download Receipt"} alt="download pdf"
                                                                            src={dwnloadPdf} width="18" className="cursor_pointer planexpiredalert"
                                                                            onClick={() => downloadReciept(item)} />
                                                                        : "NA"}
                                                                </td>

                                                                <td class="text-center">
                                                                    <div className="row" style={{ justifyContent: "center" }}>
                                                                        {

                                                                            item.return_id ?
                                                                                <>
                                                                                    {downloadLoading && downloadReturnId === item.return_id ?
                                                                                        <span style={{ color: '#31719b', paddingLeft: '3px' }}>
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                            {' '}
                                                                                        </span>
                                                                                        :
                                                                                        <span className="text-center" style={{ width: "30px" }}> <img src={dwnloadExcel} alt="download excel"
                                                                                            class="downloadicon_pointer planexpiredalert" width="18"
                                                                                            onClick={() => {
                                                                                                exportReturn(item.return_id, item.return_type);
                                                                                                //setDownloadReturnId(item.return_id)
                                                                                                //setDownloadExcelFile(true);
                                                                                            }}
                                                                                        /></span>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <span className="notice_order_textclr_blue hyperlink pointercur text-center planexpiredalert" style={{ width: "30px" }}
                                                                                    onClick={() => {
                                                                                        setShowPopup(true);
                                                                                        setShowToastMessage("You have filed this return outside Express TDS, hence Download Data Excel is not available")
                                                                                    }}
                                                                                    title="You have filed this return outside Express TDS, hence Download Data Excel is not available">
                                                                                    {"NA"}
                                                                                </span>}

                                                                        {

                                                                            item.return_id
                                                                                && item.return_type !== "CORRECTION" ?
                                                                                <span className="text-center" style={{ width: "30px" }}>
                                                                                    <img src={dwnloadPdf} alt="download icon"
                                                                                        class="downloadicon_pointer planexpiredalert"
                                                                                        width="18"
                                                                                        onClick={() => {
                                                                                            setFormType(item.form_type ?? "")
                                                                                            downloadFilingData(item.return_id, item.form_type, item.return_type)
                                                                                        }}

                                                                                    />
                                                                                </span>
                                                                                : !item.return_id && item.return_type === "REGULAR"
                                                                                    ? <span className="notice_order_textclr_blue hyperlink pointercur text-center planexpiredalert" style={{ width: "30px" }}
                                                                                        onClick={() => {
                                                                                            setShowPopup(true);
                                                                                            setShowToastMessage("You have filed this return outside Express TDS, hence Download Data PDF is not available")
                                                                                        }}
                                                                                        title="You have filed this return outside Express TDS, hence Download Data PDF is not available">
                                                                                        {"NA"}
                                                                                    </span> :
                                                                                    <span className="text-center" style={{ width: "30px" }}>-</span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                {item.return_id ?
                                                                    <td className="text-center" >
                                                                        <span className="viewIconreturnsummary planexpiredalert"
                                                                            onClick={() => {
                                                                                clickCorrectionWorkOnThis(item);
                                                                            }}
                                                                        >View</span>
                                                                    </td>
                                                                    :
                                                                    <td className="text-center notice_order_textclr_blue hyperlink pointercu">
                                                                        <span
                                                                            className="text-center notice_order_textclr_blue hyperlink pointercu planexpiredalert"
                                                                            onClick={() => {
                                                                                setShowPopup(true);
                                                                                setShowToastMessage("You have filed this return outside Express TDS, hence View button is not show")
                                                                            }}
                                                                            title="You have filed this return outside Express TDS, hence View button is not show"
                                                                        >
                                                                            NA
                                                                        </span>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )
                                                    })

                                                    : <tr><td colSpan={7} className='text-center'> No Record Found </td></tr>
                                            }


                                        </tbody>
                                    </table>
                            }

                        </div>
                        <br></br>
                        <div className='trbottom_border'></div>

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ minHeight: "148px", maxHeight: "148px" }}>
                            <div className='tds_returnsumm_createret_textwarp createret_2tbwrap'>
                                Other Returns Filed outside ExpressTDS
                            </div>
                            {
                                skeletonIndicadtor
                                    ? <Skeleton6ColumnGrid rowNo={8} />
                                    :
                                    <table className="table table-striped Deductor_dashboard_tb mb-0 mt-0">

                                        <tbody>
                                            {
                                                GovtPortdata.length > 0 ?
                                                    GovtPortdata.map((item, index) => (
                                                        <tr key={index}>
                                                            <td width="120" className="text-center">{item?.form_type ?? '-'} | {item?.quarter ?? '-'}</td>
                                                            <td width="100" className="text-center">
                                                                {item?.filing_date ? moment(item?.filing_date).format("DD/MM/YYYY") : "In progress"}
                                                            </td>
                                                            <td width="90" className="text-left">
                                                                {item?.return_type ? capitalizeFLetter(item?.return_type) : '-'}
                                                            </td>
                                                            <td width="120" className={`text-left ${processingStatus.includes(item?.efiling_status) ? "Processingst_clrred" : ""}`}>
                                                                {item?.efiling_status ?? 'NA'}
                                                            </td>
                                                            <td width="90" className="text-center">
                                                                {item.ack_no && item?.efiling_status !== 'Rejected at e-filing' ?
                                                                    <img title={"Download Receipt"} alt="download pdf"
                                                                        src={dwnloadPdf} width="18" className="cursor_pointer planexpiredalert"
                                                                        onClick={() => downloadReciept(item)} />
                                                                    : "NA"}
                                                            </td>
                                                            <td width="120" className='text-center'>
                                                                <div class="row" style={{ justifyContent: 'center' }}>
                                                                    <span className="notice_order_textclr_blue hyperlink pointercur text-center planexpiredalert" style={{ width: "30px" }}
                                                                        onClick={() => {
                                                                            setShowPopup(true);
                                                                            setShowToastMessage("You have filed this return outside Express TDS, hence download Excel is not available.")
                                                                        }}
                                                                        title="You have filed this return outside Express TDS, hence download Excel is not available.">
                                                                        {"NA"}
                                                                    </span>
                                                                    <span className="notice_order_textclr_blue hyperlink pointercur text-center planexpiredalert" style={{ width: "30px" }}
                                                                        onClick={() => {
                                                                            setShowPopup(true);
                                                                            setShowToastMessage("You have filed this return outside Express TDS, hence download PDF is not available.")
                                                                        }}
                                                                        title="You have filed this return outside Express TDS, hence download PDF is not available."
                                                                    >
                                                                        {"NA"}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td width="90" className="text-center notice_order_textclr_blue hyperlink pointercu">
                                                                <span
                                                                    className="text-center notice_order_textclr_blue hyperlink pointercu planexpiredalert" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setShowPopup(true);
                                                                        setShowToastMessage("This return was filed outside ExpressTDS,  So no data is available. To keep the data, you can Create return and import txt files")
                                                                    }}
                                                                    title="This return is not filed from ExpressTDS, so no data available"
                                                                >
                                                                    NA
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )) : <tr><td colSpan={7} className='text-center'> No Record Found </td></tr>
                                            }

                                        </tbody>
                                    </table>
                            }

                        </div>

                    </fieldset>
                </div>
                <PanStatus
                    deductorId={deductorId}
                    financialYear={financialYear}
                    stickyNotesReturnsData={stickyNotesReturnsData}
                    stickyNotesStatsData={stickyNotesStatsData}
                    panStatusData={panStatusData}
                    defaultData={defaultData}
                    successProcedSumm={() => {
                        getSummaryData(deductorId, financialYear);
                        getReturnWiseStatsData(deductorId, financialYear);
                    }}
                    indiviualQuarterSync={indiviualQuarterSync}
                    headerData={headerData}
                    skeletonList={skeletonList}
                />
            </div>
            {PDFTemplateData && formType === '24Q' ?
                <PDF24Q
                    handleClose={(refresh) => {
                        setPDFTemplateData(false);
                        setLoading(false);
                    }}
                    PDFTemplateData={PDFTemplateData}
                    prnData={prnData}
                />
                : null}

            {PDFTemplateData && formType === '26Q' ?
                <PDF26Q
                    handleClose={(refresh) => {
                        setPDFTemplateData(false);
                        setLoading(false);
                    }}
                    PDFTemplateData={PDFTemplateData}
                    prnData={prnData}
                />
                : null}

            {PDFTemplateData && formType === '27EQ' ?
                <PDF27EQ
                    handleClose={(refresh) => {
                        setPDFTemplateData(false);
                        setLoading(false);
                    }}
                    PDFTemplateData={PDFTemplateData}
                    prnData={prnData}
                />
                : null}
            {PDFTemplateData && formType === '27Q' ?
                <PDF27Q
                    handleClose={(refresh) => {
                        setPDFTemplateData(false);
                        setLoading(false);
                    }}
                    PDFTemplateData={PDFTemplateData}
                    prnData={prnData}
                />
                : null}

        </>
    )
}

export default ReturnSummary

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { ColumnMenu } from '../../components/grid/ColumnMenuFrontEnd';
import Form3CDTimeLine from './Form3CDTimeLine';
import queryString from 'query-string';
import { getPanToTanDetails } from '../../api/form3cdApi.js';
import { FORM_3CD_START_YEAR } from '../../app/constants';
import searchIcon from "../../images/search.png";
import './PanWiseTanDetails.scss';

const initialFilter = {
    logic: "and",
    filters: [],
};

const PanWiseTanDetails = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [panInput, setPanInput] = useState(params.pan); // State to hold the PAN input value
    const history = useHistory();
    const fy = params?.finacial_year ? params?.finacial_year : FORM_3CD_START_YEAR;
    const [filter, setFilter] = React.useState(initialFilter);
    const [dataState, setDataState] = useState({});

    const panToTanDetails = async (data) => {
        setLoading(true);
        try {
            const result = await getPanToTanDetails(data.pan); // Fetch data based on PAN                
            setReportData(result.data ?? []);
            setError(null);
        } catch (err) {
            setError(err);
            setReportData([]);
        } finally {
            setLoading(false);
        }
    };

    const handlePanChange = (event) => {
        setPanInput(event.target.value); // Update PAN input state
    };

    const handleToGetPanDetails = (event) => {
        if (panInput !== params.pan) {
            history.push(`?pan=${panInput}&fy=${fy}`);
            panToTanDetails({ pan: panInput });
        }
    };



    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
            // If Enter key is pressed, trigger API call
            handleToGetPanDetails();
        }
    };

    useEffect(() => {
        if (params.pan) {
            panToTanDetails({ pan: params.pan }); // Initial data fetch based on URL params
        }
    }, [params.pan]); // Trigger when PAN changes in URL


    const dataStateChange = (dataState) => {
        setDataState(dataState);
        setFilter(dataState?.filter);
    };


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };
    const rowRender = (trElement, props) => {
        const { dataItem, dataIndex } = props;
        return (
            <tr key={dataIndex}>
                {dataIndex === 0 && (
                    <td rowSpan={reportData.length} className="text-center text-center tan-summary-tancol-bglcr-white report34a_vertical">
                        {panInput}
                    </td>
                )}
                <td className="text-center">{dataItem.tan}</td>
                <td className="text-left" style={{paddingLeft:'60px'}}>{dataItem.name}</td>
            </tr>
        );
    };

    const renderHeadTextTitle = (title, txtNumber) => {
        return (
            <div>
                <div>
                    {title}
                </div>
                <div className='custom-subheader' style={{ textAlign: 'center' }}>
                    ({txtNumber})
                </div>
                
            </div>

        );
    }

    // Function to calculate count of unique TANs
  const calculateUniqueTANCount = () => {
    const uniqueTANs = [...new Set(reportData.map(item => item.tan))];
    return uniqueTANs.length;
  };

    return (
        <>
            {loading && <LoadingIndicator />}
            <ShowAlert
                error={error}
                onClose={() => setError(null)}
            />
            <Form3CDTimeLine currentPage="PartyWiseTanDetails" getPan={params.pan} getLocation={location.search} deductorName={params.deductor_name ?? ""} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center pt-2">
                        <div className="form-inline pan-input" autoComplete="off">
                            <input
                                className="form-control panwise-tan-input pr-3"
                                type="search"
                                placeholder="Enter PAN to generate report"
                                aria-label="Search"
                                value={panInput}
                                maxLength={10}
                                onChange={handlePanChange}
                                autoFocus={panInput ? false : true}
                                onKeyDown={(event) => {
                                    handleKeyPress(event);
                                }}
                            />
                            <img className="search_text_icon doflistsiconboxmrgin cursor-pointer" src={searchIcon} alt="search icon" onClick={(e) => handleKeyPress(e)} />
                        </div>
                        <div className="card tds_form3CDRevamp_genrepottyp_cardbox mt-2">   
                            <div className="table-responsive">
                                <Grid
                                    className="table table-striped report-gridpan-to-tan-table mb-0"
                                    data={filterBy(reportData, filter)}
                                    {...dataState}
                                    rowRender={rowRender}
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ]
                                    }}
                                >
                                    <Column
                                        width="100"
                                        field=""
                                        title='PAN'
                                    />
                                    <Column
                                        width="100"
                                        field="tan"
                                       
                                        title= {renderHeadTextTitle('TAN No.', calculateUniqueTANCount())}
                                        filter="text"
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        headerClassName={isColumnActive('tan') ? 'active' : ''}
                                    />
                                    <Column
                                       width="300"
                                        field="name"
                                        filter="text"
                                        title='Deductor Name'
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        headerClassName={isColumnActive('name') ? 'active text-left deductcol' : 'text-left deductcol'}
                                    />
                                </Grid>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PanWiseTanDetails;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ShowAlert from '../../components/ShowAlert';
import './PanWiseTanDetails.scss';
import queryString from 'query-string';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import DeductorSyncClient from '../createDeductor/DeductorSyncClient';
import { gocallApi } from '../../api/issueCertificateAPI';
import { getTracesCaptchaFromConnector } from '../../api/localConnectorAPI';
import { addDeductorFromPortal, verifyItCrendital } from '../../api/deductorAPI';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import BlurOnChangeInput from '../../components/BlurOnChangeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
//import ShowMorePagination from '../../components/pagination/ShowMorePagination';


const IncompleteDeductors = (props) => {
    const { params } = props;
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    //const [filterPostData, setFilterPostData] = useState(false);
   // const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [dataState, setDataState] = useState({});
    const searchStr = location.search;
    const [port, setPort] = useState();
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [data, setData] = useState([]);
    //const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null })
    const [isCaptchaCall, setIsCaptchaCall] = useState(false);
    const [userIndex, setUserIndex] = useState("");
    const [syncValidDeductor, setSyncValidDeductor] = useState(null);

    // Get parameters and trigger data fetch on mount or location change
    const getParamsHistoryUrl = () => {
        const postData = {
            pan: params.pan
        };
        if (params.fy) {
            postData.financial_year = params.fy;
        }

        postData.incomplete_deductors = true;
        setDataState(dataState);
        //setFilterPostData(postData);
        return postData;
    };


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };

    const createHistoryUrl = (dataState = []) => {
        let filterStr = `?pan=${params.pan}&fy=${params.fy}`;
        
        if(params.deductor_name){
            filterStr += `&deductor_name=${params.deductor_name ?? ""}`
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }

        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&sort_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };

    const handleChange = (value, index, name) => {
        data[index][name] = value;
        if (data?.[index]?.errObj?.[name]) data[index].errObj[name] = "";

        setData([...data]);
    };

    const validateCredential = (validateData, index) => {
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');

        let errorObj = {}

        // if(!validateData?.deductor_name?.trim()) errorObj.deductor_name = "This field is required"
        if (!validateData?.tan?.trim()) errorObj.tan = "This field is required";
        if (validateData?.tan?.trim() && !tanPattern.test(validateData?.tan?.trim())) errorObj.tan = "Invalid TAN Number";
        if (!validateData?.itdportal_password?.trim()) errorObj.itdportal_password = "This field is required";

        if (validateData?.traces_username?.trim() && validateData?.traces_password?.trim() && !validateData?.captcha_text?.trim()) errorObj.captcha_text = "Captcha is required";

        if (Object.keys(errorObj)?.length > 0) {
            validation = false;
            data[index].errObj = errorObj;
            setData([...data]);
        }
        return validation;
    }

    const saveCredentialOnserver = (data, index) => {
        const validation = validateCredential(data, index);
        if (validation) {
            setShowConnnectorCall(true);
        }

    }


    const getIncompletDeductorList = async (parameter) => {
        // if (cursor?.next_page > 1) {
        //     setShowMoreLoading(true);
        // } else {
        //     setLoading(true);
        // }
        setLoading(true);


        let filters = null;
        if (parameter?.filters?.length > 0) {
            filters = JSON.parse(atob(parameter.filters));
        }
        const url = "/api/v1/form3cd-annexures/incomplete-deductor-list";
        const payload = {
            filters: parameter.filters ? filters : null,
            sort_on: parameter.sort_on ? parameter.sort_on : '',
            sort_by: parameter.sort_by ?? "",
            //page: parseInt(parameter.page),
           // limit: 50,
            pan: parameter.pan ? parameter.pan : '',
        }
        try {
            const result = await gocallApi("post", url, payload);
            if (result?.data?.data?.length > 0) {
               // const filteredData = result?.data?.data.filter(item => item.tentative_deductor_id !== null);
                // let newFilterData;
                // if (result?.data?.cursor?.current_page > 1) {
                //     newFilterData = data.concat(filteredData)
                // } else {
                //     newFilterData = filteredData;
                // }

                setData(result?.data?.data);

               // if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result.data.cursor.next_page;
                //setCursor(result?.data?.cursor ?? { current_page: 1, next_page_url: null });
                //setIncompDedSummary(result?.data?.summary);
            } else {
                setData([]);
            }

        } catch (err) {
            if (err?.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
            //setShowMoreLoading(false);
        }
    }

    const getCaptcha = async (index, port) => {
        setLoading(true);
        async function fetchEverything() {
            try {
                const result = await getTracesCaptchaFromConnector(port);
                data[index]["captcha"] = result.data ?? {};
                data[index]["captcha_text"] = "";
                setData([...data]);
            } catch (err) {
                setError("Not able to fetch captcha, please try again after sometime. If the problem persists, then try with different internet");
                console.error(err);
                // if (err.message) setError(err.message);
                // else setError(err);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };



    const rowValidation = (rowData, index) => {
        let errObj = {};
        let validation = true;
        const tanPattern = new RegExp('[A-Z]{4}[0-9]{5}[A-Z]{1}');
        // if(!rowData?.deductor_name?.trim()) errObj.deductor_name = "This field is required"
        if (!rowData?.tan?.trim()) errObj.tan = "This field is required";
        if (rowData?.tan?.trim() && !tanPattern.test(rowData?.tan?.trim())) errObj.tan = "Invalid TAN Number";
        if (!rowData?.itdportal_password?.trim()) errObj.itdportal_password = "This field is required";

        if (Object.keys(errObj).length > 0) {
            validation = false;
            setData((prev) => {
                prev[index].errObj = errObj;
                return [...prev];
            })
        }
        return validation;
    }

    const updateRowData = (rowData, index, prevTan) => {
        const validation = rowValidation(rowData, index)

        async function fetchEverything() {
            const payload = {
                name: rowData.name ?? "",
                itdportal_password: rowData?.itdportal_password ?? "",
                tan: rowData?.tan ?? "",
                tentative_deductor_id: rowData?.tentative_deductor_id ?? "",
                traces_password: rowData?.traces_password ?? "",
                traces_username: rowData?.traces_username ?? "",
            }
            try {
                await gocallApi("put", "/api/v1/tentative-deductor", payload);

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
                rowData.tan = prevTan
            }
        }
        if (validation) {
            fetchEverything();
        }
    }

    const sendPortalFormRequest = async (datavalue, extraHeader) => {
        const payload = {
            data: datavalue,
        };
        try {
            const result = await addDeductorFromPortal(payload, extraHeader);
            setSuccess(result.message);
            if (result.data) {
                const obj = {
                    itdportal_username: userInfo.tan,
                    itdportal_password: userInfo.itdportal_password,
                    deductor_id: result?.data?.deductor_id
                }
                setTimeout(() => { setSyncValidDeductor(obj) }, 2000)
                // setTimeout(() => { setSyncValidDeductor(null) } ,4000)
            }
            if (data[userInfo.dataIndex]) {
                data.splice(userInfo.dataIndex, 1)
                setData([...data]);
                setUserInfo({});
            }
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            setLoading(false);
        }
    }


    const verfiyCredApi = () => {
        setError(null);
        setLoading(true);

        const request = {
            name: userInfo.name ?? "",
            itdportal_username: userInfo.tan ?? "",
            itdportal_password: userInfo.itdportal_password ?? "",
        };

        if (userInfo.traces_username && userInfo.traces_password) {
            request.traces_username = userInfo.traces_username ?? "";
            request.traces_password = userInfo.traces_password ?? "";
            request.captcha_text = userInfo.captcha_text ?? "";
            request.captcha_ref = userInfo?.captcha?.captcha_ref ?? "";
        }

        async function fetchEverything() {
            try {
                const result = await verifyItCrendital(port, request);
                if (result.data.status === 1) {
                    const responseHeader = result.headers ?? null;
                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                    sendPortalFormRequest(result.data.data, extraHeader)
                }
            } catch (err) {
                console.error('error: ', err);
                setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['default']);
                setLoading(false);
                getCaptcha(userInfo.dataIndex ?? "", port)
            } finally {
                setCheckItCredentials(false);
            }
        }
        fetchEverything();
    };

    const rowRender = (trElement, props) => {
        const dataItem = props.dataItem;
        const index = props.dataIndex;
        return (
            <tr>
                {index === 0 && (
                    <td rowSpan={data?.length} className='custom-background' >
                        <span className='vertical-text-center'> Incomplete Deductors </span>
                    </td>
                )}

                <td className="text-center " style={{ fontWeight: 'normal' }}>
                    {dataItem.deductor_name ? (
                        <>
                            {dataItem.deductor_name}
                            <br />
                        </>
                    ) : null}
                    {dataItem.tan ?? ""}

                </td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`itd${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                width="80%"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter ITD Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.itdportal_password ? "input-error" : ""}`}
                                placeholder="Enter ITD Password"
                                value={dataItem.itdportal_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.itdportal_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "itdportal_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`itd${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>
                    }
                </td>

                <td className="text-left">
                    <BlurOnChangeInput
                        type='text'
                        className={`form-control incompded_inputheight ${dataItem?.errObj?.traces_username ? "input-error" : ""}`}
                        value={dataItem.traces_username ?? ""}
                        maxLength={11}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.traces_username ?? ""}
                        onChange={(e) => handleChange(e.target.value, index, "traces_username", dataItem?.tentative_deductor_id ?? "")}
                        onBlur={() => { updateRowData(dataItem, index) }}
                        placeholder="Traces Username"
                    />

                </td>

                <td className="text-center form-groupposition">
                    {dataItem?.[`traces${dataItem.tentative_deductor_id}`] ?
                        <div className="input-group">
                            <BlurOnChangeInput type="text"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Traces Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}


                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = false;
                                        return [...prev]
                                    })
                                }}
                                style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </div>

                        </div> :
                        <div className="input-group">
                            <BlurOnChangeInput type="password"
                                autoComplete='new-password'
                                className={`form-control incompded_inputheight blukimp_credinputtextfs ${dataItem?.errObj?.traces_password ? "input-error" : ""}`}
                                placeholder="Traces Password"
                                value={dataItem.traces_password ?? ""}
                                data-toggle="tooltip"
                                data-placement="right" data-original-title={dataItem?.errObj?.traces_password ?? ""}
                                onChange={(e) => handleChange(e.target.value.trim(), index, "traces_password", dataItem?.tentative_deductor_id ?? "")}
                                onBlur={() => { updateRowData(dataItem, index) }}

                            />
                            <div className="input-group-prepend blukimp_updatecredisysicon eyeblue_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setData((prev) => {
                                        prev[index][`traces${dataItem.tentative_deductor_id}`] = true;
                                        return [...prev]
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </div>

                        </div>}
                </td>



                <td className="text-center">
                    <button className="btn btn-default blukimp_captchbtn " onClick={() => {
                        if (dataItem.traces_password && dataItem.traces_username) {
                            setIsCaptchaCall(true);
                            setUserIndex(index);
                        } else {
                            // setError("Enter Valid Traces credentials to get captcha");
                            validateCaptcha(dataItem, index);
                        }
                    }}>
                        Get Captcha
                    </button>

                </td>
                <td className="text-center">
                    <img
                        src={`data:image/png;base64, ${dataItem?.captcha?.captcha_image ?? ""}`}
                        width="100" alt="" />
                </td>

                <td className="text-center">
                    <input type="text"
                        className={`form-control incompded_inputheight mypc_credinputtextfs ${dataItem?.errObj?.captcha_text ? "input-error" : ""}`}
                        placeholder="Captcha"
                        value={dataItem.captcha_text ?? ""}
                        data-toggle="tooltip"
                        data-placement="right" data-original-title={dataItem?.errObj?.captcha_text ?? ""}
                        onChange={(e) => handleChange(e.target.value.trim(), index, "captcha_text", dataItem?.tentative_deductor_id ?? "")}
                    />


                </td>
                <td className="text-center  ">
                    <button className="btn btn-default blukimp_captchbtn create_deductor"
                        onClick={() => {
                            dataItem.dataIndex = index
                            setUserInfo(dataItem);
                            saveCredentialOnserver(dataItem, index);
                        }} >
                        Create Deductor
                    </button>
                </td>
            </tr>)
    }

    const validateCaptcha = (dataItem, index) => {
        if (!dataItem.errObj) dataItem.errObj = {};
        if (!dataItem.traces_password) dataItem.errObj.traces_password = "Traces Password is required"
        if (!dataItem.traces_username) dataItem.errObj.traces_username = "Traces UserName is required"
        data[index] = dataItem;
        setData([...data]);
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi()
        }
        //eslint-disable-next-line
    }, [port, checkItCredentials])

    useEffect(() => {
        const param = queryString.parse(searchStr)
        if (param.pan) {
            getIncompletDeductorList(param)
        }
        // eslint-disable-next-line
    }, [searchStr])

    useEffect(() => {
        getParamsHistoryUrl()
        // eslint-disable-next-line
    }, [location.search]);

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            {isCaptchaCall && <ConnectorAdapter
                show={true}
                handleClose={() => {
                    setIsCaptchaCall(false);
                }}
                setPort={setPort}
                // handleProceed={() => {
                //     getCaptcha(userIndex);
                // }}
                handleProceed={(getPort) => {
                    getCaptcha(userIndex,getPort);
                }}
            />}

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => {
                        setShowConnnectorCall(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        setCheckItCredentials(true);
                    }}
                />
                : null
            }

            {syncValidDeductor &&
                <DeductorSyncClient
                    syncDeductor={syncValidDeductor}
                />
            }

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                <Grid
                    className="table table-striped blukimport_tb mb-0 scrollvisible incvisibleover"
                    data={data}
                    {...dataState}
                    sortable
                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                    filterOperators={{
                        text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                        ],
                        dropdown: [
                            { text: 'grid.filterEqOperator', operator: 'in' },
                        ],
                        numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                        ],
                        textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                        ],
                    }}
                    rowRender={rowRender}
                >

                    <Column
                        width="29"
                        sortable={false}
                    />
                    <Column
                        width="300"
                        field="tan"
                        title={"Deductor Name(TAN)"}
                        filter="text"
                        columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                        headerClassName={isColumnActive('tan') ? 'active cursor-pointer' : 'cursor-pointer'}
                    />
                    <Column
                        width="138"
                        title={<span title='ITD  Password'>{"ITD  Password"}</span>}
                        sortable={false}
                    />
                    <Column
                        width="125"
                        field="traces_username"
                        title={<span title='Traces Username'>{"Traces Username"}</span>}
                        sortable={false}
                    />
                    <Column
                        width="137"
                        title={<span title='Traces Password'>{"Traces Password"}</span>}
                        sortable={false}
                    />
                    <Column
                        width="90"
                        sortable={false}
                    />
                    <Column
                        width="80"
                        sortable={false}
                    />
                    <Column
                        width="70"
                        sortable={false}
                    />
                    <Column
                        width="120"
                        sortable={false}
                    />
                </Grid>

                {/* <ShowMorePagination
                    cursor={cursor}
                    fetchData={getIncompletDeductorList}
                    postData={filterPostData}
                    loading={showMoreLoading}
                /> */}

            </div>

        </>
    );
};

export default IncompleteDeductors;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import $ from 'jquery';
import moment from 'moment';
import { getNatureOfRemittanceList, NATURE_OF_REMITTANCE_RATE, SECTION195_CHANGESRATES } from '../../app/constants';
import ErrorFocus from '../ErrorFocus';
import { deducteeTransactionsFormFields } from './DeducteeTransactionsFormHelper';
import { deducteeTransactionsFormValidationSchema } from './DeducteeTransactionsFormSchema';
import Addbtnround from '../../images/icons/add_btn_round.svg';

import DatePicker from '../datePicker/DatePickerV2';
import {
  getNatureOfPaymentCodes,
  getChallansDetails,
} from '../../api/challanAPI';
import { getDeducteesV2 } from '../../api/deducteeAPI';
import AlphaNumericInput from '../AlphaNumericInput';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import TdsRateInput from '../TdsRateInput';
import NumericInput from '../NumericInput';
import { calculation26QTaxRate, calculation27EQTaxRate, calculation27QTaxRate } from './calculationTaxRates'
import { getReasonList, getReasonListV2 } from './reasonList';
import AddDeductee from '../../features/deducteeList/AddDeductee';
import Select from 'react-select';
import ConnectorAdapter from '../kdkConnector/ConnectorAdapter';
import TracesConnector from '../kdkConnector/TracesConnector';
import SinglePanVerify from '../verifyPAN/SinglePanVerify';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import InsightsConnectorWithCaptcha from '../kdkConnector/InsightsConnectorWithCaptcha';
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import '@progress/kendo-theme-default/dist/all.css';
import './CreateDeducteeTransactionsFormV2.scss';
import { formatNumber, getCurrentFinancialYear, interestCalculationOnLateDeduction } from '../../utils/UtilityFunctions';
import ShowAlert from '../../components/ShowAlert';
import { filterBy } from "@progress/kendo-data-query";
import ConfirmAlertPopup from '../ConfirmAlertPopup';
import WindowWrapperFixed from '../grid/WindowWrapperFixed';
import exclamationIcon from "../../images/exclamation.png";

const ApplicabilityTDSRate = [
  { value: '', label: 'Select' },
  { value: 'A', label: 'Rate as per Income Tax' },
  { value: 'B', label: 'Rate as per DTAA' },
];
const GrossingUpIndicator = [
  { value: '', label: 'Select' },
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
]
const CollecteeIsLiableToTDS = [
  { value: '', label: 'Select' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
]

const INOPERATIVE_PAN_STATUS_RATE = "20";

export default function CreateDeducteeTransactionsFormV2(props) {
  const {
    deducteeTransactionFormInfo,
    createDeducteeTransactions,
    onClose,
    deducteeTransactionID,
    returnId,
    deductorId,
    headerDataDetails,
    autoSaveTriggerOnce,
    updateDeducteeInfos,
    deducteeTransactionsList,
    currentDeductee,
    setCurrentDeductee,
    setConfirmPopup,
    confirmPopup,
    setShowEditForm,
    formType,
    ldcListStatus = false,
  } = props;

  const PAN_STATUSES = ['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL'];


  const [paymentDate, setPaymentDate] = useState('');
  const [, setDeductionDate] = useState(paymentDate);
  const [tdsRateB100, setTdsRateB100] = useState(
    deducteeTransactionFormInfo.tds_rate_b100,
  );
  const [paidAmount, setPaidAmount] = useState(
    deducteeTransactionFormInfo.paid_amtp,
  );
  const [tdsAmount, setTdsAmount] = useState(
    deducteeTransactionFormInfo.tds_amtp,
  );
  const [surchargePaidAmtp, setSurchargePaidAmtp] = useState(
    deducteeTransactionFormInfo.surcharge_paid_amtp,
  );
  const [educationCessAmtp, setEducationCessAmtp] = useState(
    deducteeTransactionFormInfo.education_cess_amtp,
  );
  const firstTotalTdsValue = Number(deducteeTransactionFormInfo.tds_amtp)
    + Number(deducteeTransactionFormInfo.surcharge_paid_amtp)
    + Number(deducteeTransactionFormInfo.education_cess_amtp);
  const [totalTdsAmount, setTotalTdsAmount] = useState(firstTotalTdsValue);
  const [paymentCodes, setPaymentCodes] = useState({});
  const [paymentCodesArray, setPaymentCodesArray] = useState([]);
  const [challanOption, setChallanOption] = useState([]);
  const [, setCursor] = useState({
    current_page: 1,
    next_page_url: null,
  });
  // eslint-disable-next-line
  const [showDeducteeTransactionsDetail, setShowDeducteeTransactionsDetail] = useState(false);

  const [checkedChallanData, setCheckedChallanData] = useState({});

  const [challanInfo, setChallanInfo] = useState({});

  const [challanIdValue, setChallanIdValue] = useState(0);
  const [challanUnutilizedValue, setChallanUnutilizedValue] = useState(0);

  const [deducteeData, setDeducteeData] = useState([]);
  const [deducteeNonResidentStatus, setDeducteeNonResidentStatus] = useState(false);

  const [, setChallanListStrVal] = useState('');
  // eslint-disable-next-line
  const [deducteeName, setDeducteeName] = useState('');
  // eslint-disable-next-line
  const [paymentSection, setPaymentSection] = useState('');
  const [natureOfPaymentIDVal, setNatureOfPaymentIDVal] = useState('');
  const [challanVals, setChallanVals] = useState('');
  const [mapChallanValChecked, setMapChallanValChecked] = useState(0);
  const [, setPaymentDateCheck] = useState(paymentDate);
  const challanIDFor24QArray = ['192A', '192B', '192C', '194P'];

  const [errors, setErrors] = useState({
    paymentDate: '',
    deductionDate: '',
  });
  const [submitDisable, setSubmitDisable] = useState(false);
  const [submitEnableFor194NF, setSubmitEnableFor194NF] = useState(true);
  const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
  const [show27EQExtraFields, setShow27EQExtraFields] = useState(false);
  const [liableFieldDisable, setLiableFieldDisable] = useState(true);
  const [certificateEnable, setCertificateEnable] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [sectionErrorTitleMsg, setSectionErrorTitleMsg] = useState(false);

  const [autoChangeCall, setAutoChangeCall] = useState(0);
  const [verifySinglePan, setVerifySinglePan] = useState(false);
  const [complianceCheck, setComplianceCheck] = useState(null);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [pan, setPan] = useState(null);
  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [section, setSection] = useState(null);
  const [startConnector, setStartConnector] = useState(false);
  const [, setVerifyPANResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [panStatus, setPanStatus] = useState(null);
  // eslint-disable-next-line
  const [panStatusColor, setPanStatusColor] = useState(null);
  const [panComplianceStatus, setPanComplianceStatus] = useState(null);
  // eslint-disable-next-line
  const [panAsOnDate, setPanAsOnDate] = useState(null);
  const [, setInsightsCaptchaRef] = useState(null);

  const formikRef = useRef();
  const [customError, setCustomError] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isTdsRateDifference, setIsTdsRateDifference] = useState(false);
  // eslint-disable-next-line
  const [taxCalculatedMismatched, setTaxCalculatedMismatched] = useState(false);
  // const [rateMismatchedWithReason, setRateMismatchedWithReason] = useState(false);
  const [actualSectionRate, setActualSectionRate] = useState(null);
  // eslint-disable-next-line
  const [toggleAllOption, setToggleAllOption] = useState(true)
  // eslint-disable-next-line
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = React.useState();

  const fy = getCurrentFinancialYear();

  const updatedNatureOfRemittanceArray = getNatureOfRemittanceList(fy, headerDataDetails?.quarter);

  const handleFilterChange = (event) => {
    if (event) {
      setFilter(event.filter);
    }
  };

  const columns = [
    {
      field: "challan_no",
      header: "Challan No.",
      width: 110,
    },
    {
      field: "deposit_date",
      header: "Deposit Date",
      width: 110,
    },
    {
      field: "total_amtp",
      header: "Deposited Total",
      width: 110,
    },
    {
      field: "interest_amtp",
      header: "Int.(Deposited Total)",
      width: 140,
    },
    {
      field: "total_unconsumed_amtp",
      header: "Total Rem. Bal.",
      width: 130,
    },
  ];
  const itemRender1 = (li, props) => {
    const children = columns.map((col, i) => {
      return (
        <span
          className={`k-table-td`}
          style={{
            width: col.width,
            fontSize: '11px',
            textAlign: ['total_amtp', 'interest_amtp', 'total_unconsumed_amtp'].includes(col.field) ? 'right' : 'center',
            paddingRight: ['total_unconsumed_amtp'].includes(col.field) ? '25px' : '',
          }}
          key={col.width + i}
        >
          {props.dataItem[col.field]}
        </span>
      );
    });
    return React.cloneElement(
      li,
      {
        class: `k-item${challanVals?.["challan_id"] === props?.dataItem?.["challan_id"] ? ' k-state-selected k-state-focused' : ''}`,
        ...li.props,
      },
      children
    );
  };

  const getCashWithdrawal = (nature_of_payment_id) => {

    if ([53, 81].includes(parseInt(nature_of_payment_id))) {
      return [
        { value: '', label: 'Select' },
        { value: '20L-1CR', label: '20 Lakh to 1 Crore' },
        { value: '1CR-ABOVE', label: 'Excess of 1 Crore' },
      ]
    }
    return [
      { value: '', label: 'Select' },
      { value: '20L-3CR-COOP', label: '20 Lakh to 3 Crore-Co-operative Society' },
      { value: '3CR-ABOVE-COOP', label: 'Excess of 3 Crore-Co-operative Society' },
    ]
  }

  const resetComplianceStatus = (compliance_status) => {
    switch (compliance_status) {
      case 'REGULAR FILER': return 'Regular Filer';
      case 'NON FILER ': return 'Non Filer';
      case 'INVALID': return 'Invalid'
      // default: return 'Pending';
      default: return 'Unverified';
    }
  }

  const updateDeducteeInfo = () => {
    async function getDeducteeTrnsactionDetail() {
      async function getDetail() {
        if (headerDataDetails.form_type === "24Q") {
          deducteeTransactionFormInfo.tds_rate_b100 = 0;
        }
        if (deducteeTransactionFormInfo) {
          const tds_rate_b100 = deducteeTransactionFormInfo.tds_rate_b100;
          setDeducteeTrnsactnInfo(deducteeTransactionFormInfo);
          setTdsRateB100(tds_rate_b100);
          setTdsAmount(deducteeTransactionFormInfo.tds_amtp);
          setPanStatus(deducteeTransactionFormInfo?.pan_info_status);
          setPanStatusColor(deducteeTransactionFormInfo?.pan_info_status?.color);
          setPanComplianceStatus(resetComplianceStatus(deducteeTransactionFormInfo?.compliance_status));
          setPanAsOnDate(deducteeTransactionFormInfo?.as_on_date);
        }
      }
      try {
        await Promise.all([
          getDetail(),
        ]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    getDeducteeTrnsactionDetail();
  };

  useEffect(() => {
    updateDeducteeInfo();

    function initResizeElement() {
      const popups = document.getElementsByClassName('popup');
      let element = null;
      let startX;
      let startY;
      let startWidth;
      let startHeight;

      function doDragWidth(e) {
        element.style.width = `${startWidth - e.clientX + startX}px`;
      }

      function doDragHeight(e) {
        const cardHeight = $('.card').height() + 30;
        const newHeight = startHeight - e.clientY + startY;
        element.style.height = `${Math.min(cardHeight, newHeight)}px`;
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          'mousemove',
          doDragWidth,
          false,
        );
        document.documentElement.removeEventListener(
          'mousemove',
          doDragHeight,
          false,
        );
        document.documentElement.removeEventListener(
          'mouseup',
          stopDrag,
          false,
        );
      }

      function initDragWidth(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10,
        );
        document.documentElement.addEventListener(
          'mousemove',
          doDragWidth,
          false,
        );
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      function initDragHeight(e) {
        element = this.parentPopup;

        startY = e.clientY;
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10,
        );
        document.documentElement.addEventListener(
          'mousemove',
          doDragHeight,
          false,
        );
        document.documentElement.addEventListener('mouseup', stopDrag, false);
      }

      for (let i = 0; i < popups.length; i++) {
        const p = popups[i];

        const right = document.createElement('div');
        right.className = 'resizer-left';
        p.appendChild(right);
        right.addEventListener('mousedown', initDragWidth, false);
        right.parentPopup = p;

        const bottom = document.createElement('div');
        bottom.className = 'resizer-up';
        p.appendChild(bottom);
        bottom.addEventListener('mousedown', initDragHeight, false);
        bottom.parentPopup = p;
      }
    }

    $('#create-deductor').animate({
      opacity: '1',
    });

    const convertDateFormat = (date) => {
      const [day, month, year] = date.split('/');
      if (year !== undefined && month !== undefined) {
        return String(`${year}-${month}-${day}`);
      }
      return date;
    }
    const convertDateFormat2 = (date) => {
      const [year, month, day] = date.split('-');
      if (year !== undefined && month !== undefined) {
        return String(`${day}-${month}-${year}`);
      }
      return date;
    }

    async function fetchEverything() {
      async function fetchPaymentCodes() {
        let query_params = `?form_type=${headerDataDetails.form_type}`;
        query_params = query_params + `&quarter=${headerDataDetails.quarter}&financial_year=${headerDataDetails.financial_year_formatted}`;
        const result = await getNatureOfPaymentCodes(query_params);
        const paymentCodeData = {};
        result.data.forEach((paymentCode) => {
          if (parseInt(deducteeTransactionFormInfo?.nature_of_payment_id) === parseInt(paymentCode.nature_of_payment_id)) {
            setNatureOfPaymentIDVal(paymentCode.nature_of_payment_id);
          }
          if (
            headerDataDetails.form_type === '24Q'
            && challanIDFor24QArray.indexOf(paymentCode.code) >= 0
          ) {
            paymentCodeData[paymentCode.nature_of_payment_id] = paymentCode;
          } else if (headerDataDetails.form_type !== '24Q') {
            paymentCodeData[paymentCode.nature_of_payment_id] = paymentCode;
          }
        });
        setPaymentCodes(paymentCodeData);
        if (result?.data && result.data.length > 0) {
          const paymentCodeArray = [{ value: '', label: 'Select' }];
          result.data.forEach((item) => {
            paymentCodeArray.push({ ...item, value: item.nature_of_payment_id, label: `${item.code} - ${item.description}` })
          })
          setPaymentCodesArray(paymentCodeArray);
        }
      }
      try {
        await Promise.all([fetchPaymentCodes()]);
      } catch (err) {
        console.error('error: ', err);
      }

      async function fetchChallans() {
        const payload = {
          deductor_id: deductorId,
          return_id: returnId,
          limit: 200,
        }
        const result = await getChallansDetails(payload);
        let challan_list = [];
        let edit_challan_available = false;
        const allChallans = result?.data?.data ?? [];
        challan_list = allChallans.map((challandata, index) => {
          if (deducteeTransactionFormInfo && parseInt(deducteeTransactionFormInfo.challan_no) === challandata.challan_no && deducteeTransactionFormInfo.deposit_date === challandata.deposit_date) {
            edit_challan_available = true;
          }
          return challandata;
        })

        if (!edit_challan_available && deducteeTransactionFormInfo && deducteeTransactionFormInfo.challan_id) {
          challan_list.push({
            'challan_no': deducteeTransactionFormInfo.challan_no,
            'challan_id': deducteeTransactionFormInfo.challan_id,
            'total_amtp': deducteeTransactionFormInfo.total_amtp ?? 0,
            'deposit_date': convertDateFormat(deducteeTransactionFormInfo.deposit_date),
            'total_unconsumed_amtp': 0,
          })
        }

        setCursor(result.data.cursor);
        const checkedData = {};
        const challanOptions = [];
        let challanListStr = '';
        challan_list.forEach((challan) => {
          if (
            deducteeTransactionFormInfo
            && deducteeTransactionFormInfo?.cin === challan?.cin
          ) {
            const selChallan = { ...challan, text: `${challan.challan_no} - ${convertDateFormat2(challan.deposit_date)}` }
            setChallanVals(selChallan);
            setChallanUnutilizedValue(challan.total_unconsumed_amtp);
          }
          checkedData[challan.challan_id] = challan;
          if (challanListStr !== '') {
            challanListStr = `${challanListStr},${challan.challan_id}`;
          } else {
            challanListStr = challan.challan_id;
          }
          if ((Number(challan?.tds_amtp ?? 0)) > 0) {
            challanOptions.push({
              ...challan,
              deposit_date: convertDateFormat2(challan.deposit_date),
              text: `${challan.challan_no} - ${convertDateFormat2(challan.deposit_date)}`,
              interest_amtp: challan?.interest_amtp ?? '-',
              total_unconsumed_amtp: challan?.total_unconsumed_amtp ?? '-',
            })
          }
        });
        setChallanOption(challanOptions);
        setCheckedChallanData(checkedData);
        setChallanListStrVal(challanListStr);
      }
      try {
        await Promise.all([fetchChallans()]);
      } catch (err) {
        console.error('error: ', err);
      }
    }
    fetchEverything();
    initResizeElement();
    setMapChallanValChecked(deducteeTransactionFormInfo.mapChallanVal);
    setTimeout(function () {
      if (autoSaveTriggerOnce === true) {
        $('#save-deductee-trn').trigger('click');
      }
    }, 1000);
    // eslint-disable-next-line
  }, []);


  const checkUnconsumedValue = (amtVals) => {
    setDeducteeName($('#deductee_name').val());
    setPaymentSection($('#nature_of_payment_id option:selected').text());
    setSubmitDisable(false);
    if (Number(challanIdValue) === 0) {
    } else if (
      challanUnutilizedValue < amtVals
      && amtVals > 0
      && deducteeTransactionID
    ) {
      setShowDeducteeTransactionsDetail(true);
    } else if (
      challanUnutilizedValue < amtVals
      && amtVals > 0
      && Number(mapChallanValChecked) === 1
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  };

  const handleOnChange = (key, value) => {
    const startDate = new Date('2020-07-01');
    const endDate = new Date('2020-09-30');
    if (key === 'paymentDate') {
      if (value > endDate) {
        setPaymentDate('');
        setTimeout(() => {
          $('#paymentDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          paymentDate: 'Payment Date Not grater than quarter end date',
        };
        setErrors(newErrors);
      } else if (value < startDate) {
        setPaymentDate('');
        setTimeout(() => {
          $('#paymentDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          paymentDate: 'Payment Date Not less than quarter start date',
        };
        setErrors(newErrors);
      } else if (value <= endDate) {
        setPaymentDate(moment(value).format('YYYY-MM-DD'));
        const newErrors = { ...errors, paymentDate: '', deductionDate: '' };
        setErrors(newErrors);
      }
    } else if (key === 'deductionDate') {
      if (paymentDate === '') {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Payment should not empty',
        };
        setErrors(newErrors);
      } else if (value < startDate) {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Deduction Not less than quarter start date',
        };
        setErrors(newErrors);
      } else if (value > endDate) {
        setDeductionDate('');
        setTimeout(() => {
          $('#deductionDateDiv').find('input').val('');
        }, 10);
        const newErrors = {
          ...errors,
          deductionDate: 'Deduction Not grater than quarter end date',
        };
        setErrors(newErrors);
      } else if (value >= startDate) {
        setDeductionDate(moment(value).format('YYYY-MM-DD'));
        const newErrors = { ...errors, deductionDate: '', };
        setErrors(newErrors);
      }
    } else if (key === 'tdsRateB100') {
      setTdsRateB100(value);
      let tdsAmt = (value / 100) * paidAmount;
      if (tdsAmt !== 0) {
        tdsAmt = tdsAmt.toFixed(2);
      }
      setTdsAmount(tdsAmt);
      const calculationOfTotalTds = Number(tdsAmt) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'paidAmount') {
      setPaidAmount(value);
      if (deducteeTransactionFormInfo.tds_rate_b100 !== 0) {
        let tdsAmt = (tdsRateB100 / 100) * value;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setTdsAmount(tdsAmt);
        const calculationOfTotalTds = Number(tdsAmt) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
        setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
        checkUnconsumedValue(calculationOfTotalTds);
      } else {
        setTdsAmount(deducteeTransactionFormInfo.tds_amtp);
      }
    } else if (key === 'tdsAmount') {
      setTdsAmount(value);
      const calculationOfTotalTds = Number(value) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'surchargePaidAmtp') {
      setSurchargePaidAmtp(value);
      const calculationOfTotalTds = Number(tdsAmount) + Number(value) + Number(educationCessAmtp);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    } else if (key === 'educationCessAmtp') {
      setEducationCessAmtp(value);
      const calculationOfTotalTds = Number(tdsAmount) + Number(surchargePaidAmtp) + Number(value);
      setTotalTdsAmount(calculationOfTotalTds.toFixed(2));
      checkUnconsumedValue(calculationOfTotalTds);
    }


  };

  const handleChalanValueChange = (value) => {
    if (value !== '') {
      if (Object.keys(checkedChallanData).length > 0) {
        if (checkedChallanData[value]) {
          setChallanUnutilizedValue(
            checkedChallanData?.[value]?.total_unconsumed_amtp ?? 0,
          );
        }
      } else {
        setChallanUnutilizedValue(0);
      }
      setChallanInfo(checkedChallanData[value]);
    } else {
      setChallanInfo({});
      setChallanUnutilizedValue(0);
    }
  };

  const handleDeducteeNameChange = (event) => {
    const nameStr = event.target.value;
    async function fetchDeducteeName() {

      const form_type = headerDataDetails?.form_type ?? '';

      const payload = {
        deductor_id: deductorId,
        search_text: nameStr,
        limit: 17,
        filter_status: 1,
      }
      if (form_type === '24Q') {
        payload.deductee_type = "INDIVIDUAL_02,OTH_10";
      }

      const result = await getDeducteesV2(payload, false);
      const DeducteeDataName = [];
      const resultData = result?.data?.data ?? [];


      resultData.forEach((deductee) => {
        //const panDetail = deductee.pan && deductee.pan !== 'PANAPPLIED' && deductee.pan !== 'PANINVALID' && deductee.pan !== 'PANNOTAVBL' ? deductee.pan : '';
        const panDetail = deductee.pan && deductee.pan !== '' ? String(deductee.pan).toUpperCase() : '';
        let deductee_name = deductee.deductee_name;
        if (deductee_name.length > 25) {
          deductee_name = deductee_name.substring(0, 25) + '...';
        }

        if (form_type === '24Q' && (deductee.deductee_type === 'INDIVIDUAL_02' || deductee?.pan === 'PANNOTAVBL')) {
          DeducteeDataName.push({ id: deductee?.deductee_id, text: `${deductee_name} - ${String(deductee?.pan ?? '').toUpperCase()}`, pan: panDetail, processing_date: deductee?.processing_date ?? '', compliance_status: deductee?.compliance_status, pan_status: deductee?.pan_status, non_resident: deductee?.non_resident ?? false, });
        }
        if (form_type !== '24Q') {
          DeducteeDataName.push({ id: deductee?.deductee_id, text: `${deductee_name} - ${String(deductee?.pan ?? '').toUpperCase()}`, pan: panDetail, processing_date: deductee?.processing_date ?? '', compliance_status: deductee?.compliance_status, pan_status: deductee?.pan_status, non_resident: deductee?.non_resident ?? false, });
        }
        //DeducteeDataName.push({ id: deductee.deductee_id, text: `${deductee_name} - ${deductee.pan}`, pan: panDetail, as_on_date: deductee.as_on_date, compliance_status: deductee.compliance_status, pan_status: 'PAN_AVAILABLE_BUT_INACTIVE' });
      });
      setDeducteeData(DeducteeDataName);
      localStorage.setItem('deducteeList', JSON.stringify(DeducteeDataName));
    }

    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === nameStr; });
    if (nameStr.length !== 0 && nameStr.length > 0 && filterDeducteeData.length === 0) {
      fetchDeducteeName();
    } else {
      const DeducteeDataName = [];
      setDeducteeData(DeducteeDataName);
    }
  };

  $('#deductee_name').change(() => {
    $.each($('#deductee_name_list option'), (index, value) => {
      if (value.value === $('#deductee_name').val()) {
        $('#deductee_id').val(value.dataset.value);
        $('#deductee_id').trigger('click');
      }
    });
  });

  const change24QTaxDeducted = (setFieldValue, paid_amtp, deductee_name, field, tdsAmt) => {
    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === deductee_name; });
    if (filterDeducteeData.length > 0) {
      if (filterDeducteeData[0].pan === 'PANAPPLIED' || filterDeducteeData[0].pan === 'PANNOTAVBL' || filterDeducteeData[0].pan === 'PANINVALID') {

        setFieldValue('deduction_reason', "C", false);
        onChangeDeductionReason("C", setFieldValue);

        if (headerDataDetails.form_type === '24Q') {
          let newTdsAmt = (20 / 100) * paid_amtp;
          if (field !== 'tds_amtp') {
            setFieldValue('tds_amtp', newTdsAmt, false);
            setTdsAmount(newTdsAmt);
          }
          if (field === 'tds_amtp' && tdsAmt < newTdsAmt) {
            printErrMsg('errorMsgTds', 'TDS amount should be atleast 20% of the Amount Paid');
          }
        }

      }
    }
  }

  const formValueChange = (e, setFieldValue, values, field) => {
    if (field === 'tds_amtp') {
      if (e.target.id === 'TdsRateB100') {
        let tdsAmt = (e.target.value / 100) * paidAmount;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setFieldValue(field, tdsAmt);
        setTdsAmount(tdsAmt);
        //= ==deductee date=====//

        // deductionDateDiv deduction_date
        if (e.target.value <= 0) {
          setFieldValue('deduction_date', '', false);
          $('#deductionDateDiv input').val('');
        }
      } else if (e.target.id === 'paid_amtp') {

        let tdsAmt = (tdsRateB100 / 100) * e.target.value;
        if (tdsAmt !== 0) {
          tdsAmt = tdsAmt.toFixed(2);
        }
        setFieldValue(field, tdsAmt);
        setTdsAmount(tdsAmt);
        change24QTaxDeducted(setFieldValue, e.target.value, values.deductee_name, 'paid_amtp');
      }
    }
  };

  const formValueChange2 = (values, setFieldValue, field) => {
    if (field === 'tds_amtp') {
      let tdsAmt = (values / 100) * paidAmount;
      if (parseInt(tdsAmt) !== 0) {
        tdsAmt = tdsAmt.toFixed(2);
      }
      setFieldValue(field, tdsAmt, false);
    }
  };

  useEffect(() => {

    if (headerDataDetails.form_type === '27EQ') {
      setShow27EQExtraFields(true);
    }

    const calculationOfTotalTds = Number(tdsAmount) + Number(surchargePaidAmtp) + Number(educationCessAmtp);
    setTotalTdsAmount(calculationOfTotalTds);
    checkUnconsumedValue(calculationOfTotalTds);

    let deduction_reason = deducteeTransactionFormInfo.deduction_reason;
    if (deduction_reason === 'F' || deduction_reason === 'G') {
      setLiableFieldDisable(false);
    } else {
      setLiableFieldDisable(true);
    }
    // eslint-disable-next-line
  }, [challanInfo]);

  useEffect(() => {
    let reason_id = deducteeTransactionFormInfo.deduction_reason;
    let selected_reason = getReasonList(headerDataDetails).filter((reason, index) => { return reason.id === reason_id; });
    if (selected_reason.length > 0) {
      setCertificateEnable(selected_reason[0].certificate_applicable);
    } else if (deducteeTransactionFormInfo.lower_deduction_certificate_no !== '') {
      setCertificateEnable(true);
    } else {
      setCertificateEnable(false);
    }
    // eslint-disable-next-line
  }, [])

  const onChangeDeductionReasonForSpecific = (reason_id, nature_of_payment_id) => {
    const reasonPErrMsg = 'Reason P is applicable only for Section 194 and 195.'
    const reasonQErrMsg = 'Reason Q is applicable only for Section 194A.'
    const reasonUErrMsg = 'Reason U is not applicable for the selected Section.'
    if (reason_id === 'P' && nature_of_payment_id !== 2 && nature_of_payment_id !== 71) {
      printErrMsg('errorMsgSection', reasonPErrMsg);
      setSectionErrorTitleMsg(reasonPErrMsg);
    } else if (reason_id === 'Q' && nature_of_payment_id !== 25) {
      printErrMsg('errorMsgSection', reasonQErrMsg);
      setSectionErrorTitleMsg(reasonQErrMsg);
    } else if (reason_id === 'U' && (nature_of_payment_id === 5 || nature_of_payment_id === 26 || nature_of_payment_id === 36 || nature_of_payment_id === 41 || nature_of_payment_id === 46 || nature_of_payment_id === 53)) {
      printErrMsg('errorMsgSection', reasonUErrMsg);
      setSectionErrorTitleMsg(reasonUErrMsg);
    } else {
      printErrMsg('errorMsgSection', '');
      setSectionErrorTitleMsg('');
    }
  }


  const onChangeDeductionReason = (reason_id, setFieldValue) => {

    let selected_reason = getReasonList(headerDataDetails).filter((reason, index) => { return reason.id === reason_id; });
    if (selected_reason.length > 0) {
      setCertificateEnable(selected_reason[0].certificate_applicable);
      if (selected_reason[0].certificate_applicable === false) {
        setFieldValue('lower_deduction_certificate_no', '');
      }
    }
    else {
      setCertificateEnable(false);
      setFieldValue('lower_deduction_certificate_no', '');
    }

    let financial_year_formatted = headerDataDetails.financial_year_formatted;
    var getFYFromHeader = financial_year_formatted.split('-');

    if (headerDataDetails.form_type === '27EQ' &&
      (reason_id === 'F' || reason_id === 'G') &&
      ((parseInt(getFYFromHeader[0]) === 2020 && (headerDataDetails.quarter === 'Q3' || headerDataDetails.quarter === 'Q4')) ||
        parseInt(getFYFromHeader[0]) >= 2021)) {
      setLiableFieldDisable(false);
      setFieldValue('tds_liable', '0');
    } else {
      setLiableFieldDisable(true);
      setFieldValue('tds_liable', '');
      liableHandleChange('0', setFieldValue);
    }

    if (headerDataDetails.form_type === '27EQ' && reason_id === 'Y') {
      setTdsAmount(() => 0);
    }

  }

  const selectedDeducteeName = (value, setFieldValue, values) => {
    let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === value; });

    if (filterDeducteeData.length > 0) {
      setFieldValue('deductee_id', filterDeducteeData[0].id, false);
      setFieldValue('deductee_pan', filterDeducteeData[0].pan, false);
      setFieldValue('deductee_name', value, false);

      setDeducteeNonResidentStatus(filterDeducteeData?.[0]?.non_resident ?? false)

      setPanComplianceStatus(filterDeducteeData?.[0]?.compliance_status ?? 'Unverified');

      values.deductee_pan = filterDeducteeData[0].pan;
      tdsRateCalculation(values, value, 'deductee_name', setFieldValue, null, filterDeducteeData?.[0]?.non_resident ?? false);
      change24QTaxDeducted(setFieldValue, values.paid_amtp, value, 'deductee_name');

      if (filterDeducteeData?.[0]?.pan_status === "PAN_AVAILABLE_BUT_INACTIVE") {
        setFieldValue('tds_rate_b100', INOPERATIVE_PAN_STATUS_RATE);
        setTdsRateB100(INOPERATIVE_PAN_STATUS_RATE);
      }
    }
  };

  function tdsRateCalculation(values, value, field, setFieldValue, compliance_status = null, non_resident) {

    values[field] = value;
    let nature_of_payment_id = parseInt(values.nature_of_payment_id);
    let paymentDate = values.payment_date;
    let deductionDate = values.deduction_date;
    let payment_date = paymentDate.split('-').reverse().join('-');
    let deduction_date = deductionDate.split('-').reverse().join('-');
    let deductee_name = values.deductee_name;
    let deductee_pan = values.deductee_pan;
    let deduction_reason = values.deduction_reason;
    let applicable_tds_rate = values.applicable_tds_rate;
    let nature_of_remittance = values.nature_of_remittance ?? "";

    try {
      if (nature_of_payment_id === 53 || nature_of_payment_id === 82) {
        setSubmitEnableFor194NF(false);
      } else {
        setSubmitEnableFor194NF(true);
        setFieldValue('cash_withdrawal_option', '');
      }

      if (nature_of_payment_id !== 36 && nature_of_payment_id !== 53 && nature_of_payment_id !== 81 && nature_of_payment_id !== 82) {
        setFieldValue('cash_withdrawal_amtp', '0');
        values.cash_withdrawal_amtp = 0;
      }
    } catch (e) {
      console.warn("warning in checking nature id");
    }

    if (Object.keys(paymentCodes).length > 0) {
      let tax_rates = setTDSRateAuto(nature_of_payment_id, payment_date, deduction_date, setFieldValue, deductee_name, deductee_pan, deduction_reason, applicable_tds_rate, compliance_status, non_resident, nature_of_remittance);
      if (nature_of_payment_id === 36 || nature_of_payment_id === 53) {
        excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', tax_rates);
      }
    }

    setInOerativePanStatusRate(setFieldValue);
  }

  function getDeducteeCategory(val) {
    const panCheck = val.charAt(3);
    if (panCheck === 'C') {
      return 'K';
    } else if (panCheck === 'P') {
      return 'Q';
    } else if (panCheck === 'H') {
      return 'Q';
    } else if (panCheck === 'F') {
      return 'F';
    } else if (panCheck === 'A') {
      return 'P';
    } else if (panCheck === 'T') {
      return 'T';
    } else if (panCheck === 'B') {
      return 'B';
    } else if (panCheck === 'L') {
      return 'L';
    } else if (panCheck === 'J') {
      return 'J';
    } else if (panCheck === 'G') {
      return 'A';
    } else {
      return null;
    }
  }

  function setTDSRateAuto(
    nature_of_payment_id,
    payment_date,
    deduction_date,
    setFieldValue,
    deductee_name,
    deductee_pan,
    deduction_reason,
    applicable_tds_rate,
    compliance_status,
    non_resident,
    nature_of_remittance
  ) {

    let nonResident = non_resident ?? deducteeNonResidentStatus;

    let regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    deductee_pan = regexPan.test(deductee_pan) ? deductee_pan : ""


    //const reason = document.getElementById('deduction_reason').value;
    if ((headerDataDetails && headerDataDetails.form_type !== '27EQ')) {
      printErrMsg('errorMsgSurcharge', '');
      printErrMsg('errorMsgCess', '');
      printErrMsg('errorMsgTds', '');
      printErrMsg('cashWithdrawErr', '');
      printErrMsg('cashWithdrawErrs', '');
    }

    payment_date = parseInt(moment(payment_date, 'DD/MM/YYYY', true).unix());
    deduction_date = parseInt(moment(deduction_date, 'DD/MM/YYYY', true).unix());
    const startDate = parseInt(moment('2020-05-14').unix());
    const endDate = parseInt(moment('2021-03-31').unix());
    setPaymentDateCheck(payment_date);

    if (!nature_of_payment_id) {
      setRateValue(setFieldValue, 0, 0);
      return;
    }

    if (headerDataDetails.form_type === '24Q') {
      return;
    }

    if (headerDataDetails.form_type === '26Q') {
      const deductee_category = deductee_pan ? getDeducteeCategory(deductee_pan) : null;
      let tax_rates = calculation26QTaxRate(deductee_pan, deductee_category, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, compliance_status, headerDataDetails)
      if (parseInt(nature_of_payment_id) === 42) { //194LBB
        if (deductee_pan === '') {
          if (nonResident === false) tax_rates = 20
          else tax_rates = 30
        } else {
          if (nonResident === false) tax_rates = 10
          else tax_rates = 30
        }
      }
      if (parseInt(nature_of_payment_id) === 46 && deductee_pan !== '') { //194LBC
        const pan4thChar = deductee_pan.charAt(3) ?? '';
        if (['P', 'H'].includes(pan4thChar)) {
          tax_rates = 25;
        } else {
          tax_rates = 30
        }
      }
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }

    if (headerDataDetails.form_type === '27EQ') {
      let tax_rates = calculation27EQTaxRate(deductee_pan, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, compliance_status, headerDataDetails)
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }
    if (headerDataDetails.form_type === '27Q') {
      const deductee_category = deductee_pan ? getDeducteeCategory(deductee_pan) : null;
      const amendmentDate = moment("2024/07/23").unix();
      let tax_rates = calculation27QTaxRate(deductee_pan, deductee_category, payment_date, deduction_date, startDate, endDate, nature_of_payment_id, deduction_reason, applicable_tds_rate)
      if (deduction_reason === 'Y') {
        tax_rates = 0;
      }
      if (parseInt(nature_of_payment_id) === 42) { //194LBB
        if (deductee_pan === '') {
          if (nonResident === false) tax_rates = 20
          else tax_rates = 30
        } else {
          if (nonResident === false) tax_rates = 10
          else tax_rates = 30
        }
      }
      if (parseInt(nature_of_payment_id) === 46 && deductee_pan !== '') { //194LBC
        const pan4thChar = deductee_pan.charAt(3) ?? '';
        if (['P', 'H'].includes(pan4thChar)) {
          tax_rates = 25;
        } else {
          tax_rates = 30
        }
      }
      console.log("return", deductee_pan)
      if((payment_date >= amendmentDate || deduction_date >= amendmentDate) && deductee_pan && !PAN_STATUSES.includes(deductee_pan)){
         if(SECTION195_CHANGESRATES?.[nature_of_payment_id]){
             tax_rates = SECTION195_CHANGESRATES?.[nature_of_payment_id]?.rate;
         }else if(NATURE_OF_REMITTANCE_RATE?.[nature_of_remittance]){
             tax_rates = NATURE_OF_REMITTANCE_RATE?.[nature_of_remittance]?.rate;
         }
      }
      setRateValue(setFieldValue, tax_rates, nature_of_payment_id);
      return tax_rates;
    }

  }


  function setRateValue(setFieldValue, tdsRateSet, nature_of_payment_id) {
    try {
      if (tdsRateSet === undefined) {
        tdsRateSet = 0;
        setNatureOfPaymentIDVal('');
      }
      setFieldValue('tds_rate_b100', tdsRateSet);
      setActualSectionRate(tdsRateSet)
      handleOnChange('tdsRateB100', tdsRateSet);
      formValueChange2(tdsRateSet, setFieldValue, 'tds_amtp');
      if (parseInt(nature_of_payment_id) !== 53) {
        setFieldValue('cash_withdrawal_option', '');
      }
      // setFieldValue("cash_withdrawal_option_two", "");
    } catch (e) {
      console.warn('');
    }
  }

  function validateReasonSection(values, setFieldValue, fromErr) {
    const paidAmtp = parseInt(values.paid_amtp);
    const tdsAmtp = parseInt(values.tds_amtp);
    const { nature_of_payment_id } = values;
    const reason = document.getElementById('deduction_reason').value;
    const msg = 'For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Cess Amount cannot be greater than 0.';

    if (
      reason === 'B'
      && (parseInt(nature_of_payment_id) === 1
        || parseInt(nature_of_payment_id) === 2
        || parseInt(nature_of_payment_id) === 6
        || parseInt(nature_of_payment_id) === 7
        || parseInt(nature_of_payment_id) === 23
        || parseInt(nature_of_payment_id) === 25
        || parseInt(nature_of_payment_id) === 28
        || parseInt(nature_of_payment_id) === 33
        || parseInt(nature_of_payment_id) === 35
        || parseInt(nature_of_payment_id) === 48)
    ) {
      setFieldValue('tds_amtp', '0');
      handleOnChange('tdsAmount', '0');
      setFieldValue('education_cess_amtp', '0');
      handleOnChange('educationCessAmtp', '0');
      setFieldValue('surcharge_paid_amtp', '0');
      handleOnChange('surchargePaidAmtp', '0');
      switch (fromErr) {
        case 'errorMsgTds': {
          printErrMsg('errorMsgTds', msg);
          printErrMsg('errorMsgSurcharge', '');
          printErrMsg('errorMsgCess', '');
          return;
        }
        case 'errorMsgSurcharge': {
          printErrMsg('errorMsgSurcharge', msg);
          printErrMsg('errorMsgTds', '');
          printErrMsg('errorMsgCess', '');
          return;
        }
        case 'errorMsgCess': {
          printErrMsg('errorMsgCess', msg);
          printErrMsg('errorMsgTds', '');
          printErrMsg('errorMsgSurcharge', '');
          return;
        }
        default: {
          return;
        }
      }
    }

    if (tdsAmtp > paidAmtp && nature_of_payment_id !== '36' && nature_of_payment_id !== '53') {
      if (paidAmtp === 0 && tdsAmtp > 0) {
        return false
      } else {
        printErrMsg('errorMsgTds', 'TDS Amount cannot be greater than Amount Paid');
      }
    } else {
      printErrMsg('errorMsgTds', '');
    }
  }

  function printErrMsg(errID, msg) {
    if (errID && document.getElementById(errID)) {
      document.getElementById(errID).setAttribute("title", msg);
      document.getElementById(errID).innerHTML = msg;
    }
  }

  function excessWithdrawTdsCalculation(values, setFieldValue, field, value) {
    values[field] = value;
    let tdsAmtp = parseInt(values.tds_amtp);
    const { nature_of_payment_id, cash_withdrawal_amtp, surcharge_paid_amtp, education_cess_amtp } = values;
    const tdsRate = values.tds_rate_b100;

    if ([36, 53, 81, 82].includes(parseInt(nature_of_payment_id))) {
      // eslint-disable-next-line
      const tdsAmount = (tdsAmtp = (tdsRate / 100) * cash_withdrawal_amtp);
      setTdsAmount(parseFloat(tdsAmount).toFixed(2));
      setFieldValue('tds_amtp', parseFloat(tdsAmount).toFixed(2));
      setTdsAmount(parseFloat(tdsAmount).toFixed(2));

      const calculationOfTotalTds = Number(tdsAmount)
        + Number(surcharge_paid_amtp)
        + Number(education_cess_amtp);
      setTotalTdsAmount(parseFloat(calculationOfTotalTds).toFixed(2));
      checkUnconsumedValue(parseFloat(calculationOfTotalTds).toFixed(2));
    }
  }

  function setExcessTdsRate(values, setFieldValue, cash_withdrawal_option_selected = null) {
    //const cash_withdrawal_option = document.getElementById('cash_withdrawal_option').value;  
    const cash_withdrawal_option = cash_withdrawal_option_selected !== null ? cash_withdrawal_option_selected : values?.cash_withdrawal_option ?? '';
    if (cash_withdrawal_option === '20L-1CR') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '5');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("5");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 5);
    } else if (cash_withdrawal_option === '1CR-ABOVE') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '5');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("5");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 5);
    } else if (cash_withdrawal_option === '20L-3CR-COOP') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '2');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("2");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 2);
    } else if (cash_withdrawal_option === '3CR-ABOVE-COOP') {
      setSubmitEnableFor194NF(false);
      setSubmitDisable(true);
      setFieldValue('tds_rate_b100', '5');
      setFieldValue('tds_amtp', '0');
      setTdsRateB100("5");
      excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', 5);
    }
  }

  function dateValidation(values, payment_date) {
    const { nature_of_payment_id } = values;
    const { cash_withdrawal_amtp } = values;
    const { cash_withdrawal_option } = values;
    if (parseInt(nature_of_payment_id) === 53) {
      if (cash_withdrawal_amtp <= 0 || cash_withdrawal_option === '') {
        printErrMsg('cashWithdrawErr', 'Excess of cash is required');
        printErrMsg('cashWithdrawErrs', 'Excess of cash is required');
        return false;
      } else {
        printErrMsg('errorExcess', '');
        printErrMsg('cashWithdrawErr', '');
        printErrMsg('cashWithdrawErrs', '');
        return true;
      }
    } else if (parseInt(nature_of_payment_id) === 36) {
      if (cash_withdrawal_amtp <= 0) {
        return false;
      } else {
        printErrMsg('errorExcess', '');
        return true;
      }
    }

    return true;
  }

  const autoPopulateDeductionDate = (date, setFieldValue, values) => {
    if (moment(date, 'DD/MM/YYYY', true).isValid() && autoChangeCall) {
      let quarter_start_date = moment(headerDataDetails.quarter_start_date).unix();
      let payment_date = moment(date, "DD/MM/YYYY").unix();
      if (payment_date >= quarter_start_date) {
        setFieldValue('deduction_date', date);
        values.deduction_date = date;
        $('#deductionDateDiv').find('input').val(date);
      }
    }
    tdsRateCalculation(values, date, 'payment_date', setFieldValue);
  };

  const liableHandleChange = (value, setFieldValue) => {
    if (value === '0' || value === '') {
      setFieldValue('tds_challan_no', '');
      setFieldValue('tds_payment_date', '');
      $('#tdsPaymentDate').find('input').val('');
    }
  };

  const resetPanStatusAndComplianceStatus = (setFieldValue) => {
    setTimeout(() => {
      setFieldValue('deductee_id', '');
      setFieldValue('deductee_pan', '');
      setFieldValue('deduction_reason', '');
      setToggleAllOption(true);
      setPanStatus((null))
      setPanStatusColor(null);
      setPanComplianceStatus(null);
      setPanAsOnDate(null);
    }, 100)

  }

  const listNoDataRender = (element, setFieldValue, values) => {
    const noData = (
      <span>
        <h4 style={{ fontSize: '1em' }} onMouseDown={(e) => e.stopPropagation()}>
          <span className="k-icon k-i-warning" style={{ fontSize: '1.6em', top: '0px', paddingBottom: '3px' }} />
          <div className=''>No data found</div>
          <hr style={{ margin: '4px 0px  0px 0px' }} />
          <span
            onClick={() => { showform(setFieldValue, values); resetPanStatusAndComplianceStatus(setFieldValue) }}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className="btn btn-default" style={{ padding: '0px', color: '#31719b', backgroundColor: 'none', border: 'none !important', textTransform: 'capitalize' }}>
            <span className='addnewded_icon'>
              <img src={Addbtnround} width={16} alt='' />
              <span className='addnewded_textfs pl-2'>Add new deductee</span>
            </span>

          </span>
        </h4>
      </span>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }

  const itemRender = (li, itemProps, setFieldValue, values) => {
    const index = itemProps.index;

    const itemChildren = (
      <span>
        <span style={{ color: "#00F" }}>{li.props.children}</span><br /><br />
        {index + 1 === deducteeData.length && (
          <>
            <li className="k-button addinnerbtnbgclr" onClick={() => { showform(setFieldValue, values); resetPanStatusAndComplianceStatus(setFieldValue); }}
              style={{ textAlign: 'center' }}>
              <span className='addnewded_icon pr-2'>
                <img src={Addbtnround} width={16} alt='' />
              </span>
              <span className='addnewded_textfs'>Add new deductee</span>
            </li>
          </>
        )
        }
      </span>
    )

    return React.cloneElement(li, li.props, itemChildren);

  }

  const showform = (setFieldValue, values) => {
    setTimeout(function () {
      values.deductee_name = '';
      values.deductee_id = '';
      setFieldValue('deductee_name', '');
      setFieldValue('deductee_id', '');
      setDeducteeName(null);
      setShowCreateForm(true);
    });
  }

  const HandleClose = (setFieldValue, values) => {
    let NewAddedDeductee = JSON.parse(localStorage.getItem('getNewAddedDeductee'));
    if (NewAddedDeductee) {
      let getDeducteeAllData = NewAddedDeductee[0];
      let getDeducteeID = NewAddedDeductee[1];
      values.deductee_id = getDeducteeID.deductee_id;
      values.deductee_name = `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`;
      setFieldValue('deductee_id', getDeducteeID.deductee_id);
      setFieldValue('deductee_name', `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`);
      setDeducteeName(`${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan}`);
    }

    setShowCreateForm(false);
    localStorage.removeItem('getNewAddedDeductee');
    setTimeout(function () {
      $('#deductee_name').focus();
    });
  }


  const prevDeductee = () => {
    let selectPage = 0;
    if (currentDeductee > 0) {
      selectPage = currentDeductee - 1;
    }
    if (currentDeductee !== selectPage) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfos(deducteeTransactionsList[selectPage]);
    }
    else {
      onClose();
    }
  }

  const nextDeductee = () => {
    const selectPage = currentDeductee + 1;
    if (currentDeductee !== selectPage && selectPage < deducteeTransactionsList.length) {
      setCurrentDeductee(selectPage);
      updateDeducteeInfos(deducteeTransactionsList[selectPage]);
    }
    else {
      onClose();
    }
  }

  useEffect(() => {
    if (deducteeTransactionFormInfo) {
      const calculationOfTotalTds = Number(deducteeTransactionFormInfo?.tds_amtp ?? 0) +
        Number(deducteeTransactionFormInfo?.surcharge_paid_amtp ?? 0) +
        Number(deducteeTransactionFormInfo?.education_cess_amtp ?? 0);
      setTotalTdsAmount((prev) => {
        prev = calculationOfTotalTds
        return prev
      });
      setNatureOfPaymentIDVal((prev) => {
        prev = deducteeTransactionFormInfo?.nature_of_payment_id
        return prev
      });
      setTdsRateB100(deducteeTransactionFormInfo?.tds_rate_b100);
      setActualSectionRate(deducteeTransactionFormInfo?.tds_rate_b100);
      const cin = deducteeTransactionFormInfo?.cin ?? null;
      if (cin) {
        const selectedChallan = challanOption.filter(v => v.cin === cin);
        setChallanVals(selectedChallan[0]);
      }
      if (deducteeTransactionFormInfo?.deductee_id) {
        const deductionReason = deducteeTransactionFormInfo?.deduction_reason ?? null;
        const natureOfPaymentId = deducteeTransactionFormInfo?.nature_of_payment_id ?? null;
        if (natureOfPaymentId && deductionReason) {
          let toggleAllOption = true;
          const lessOptions = getReasonListV2(headerDataDetails, natureOfPaymentId, true); //get less options
          if (lessOptions.length > 0) {
            let selectedOption = lessOptions.filter((v) => v.value === deductionReason);
            if (selectedOption.length === 0) {
              toggleAllOption = false;
            }
          }
          setToggleAllOption(toggleAllOption);
        }

      }
    }
    // eslint-disable-next-line
  }, [deducteeTransactionFormInfo])

  const getComplianceStatusForPan = (pan) => {
    setPan((prev) => {
      prev = pan
      return prev
    })
    setSection((prev) => {
      prev = 'COMPLIANCE_CHECK'
      return prev
    })
    setStartConnector(true);
  }

  const panStatuscolorMapping = (status) => {
    if (status === null || status === '' || status === undefined) return;
    if (status.toLowerCase().includes('active')) {
      setPanStatusColor('22c91d');
    } else {
      setPanStatusColor('ff0000');
    }
  }

  const getPanStatus = (values, value, form_type, setFieldValue) => {
    const panData = value.split("-");
    const selectedPan = (panData?.[panData.length - 1] ?? '').trim();
    if (selectedPan !== '') {
      if (deducteeData.length > 0) {
        let selectedItem = null;
        deducteeData.forEach((item) => {
          if (item.pan === selectedPan) {
            selectedItem = item;
            setPanStatus((prev) => {
              prev = item?.pan_status
              return prev
            });
            setPanStatusColor((prev) => {
              prev = panStatuscolorMapping(item?.pan_status?.text)
              return prev
            })
            setPanComplianceStatus((prev) => {
              prev = item?.compliance_status
              return prev
            })
            setPanAsOnDate((prev) => {
              prev = item?.processing_date
              return prev
            })
          }
        })

        if ((form_type === '24Q' || form_type === '26Q') && selectedPan === 'PANNOTAVBL') {
          setToggleAllOption(false);
        }

        if (form_type === '27EQ' && selectedItem?.compliance_status === 'NON FILER') {
          setTimeout(() => {
            setFieldValue('deduction_reason', 'I');
            tdsRateCalculation(values, 'I', 'deduction_reason', setFieldValue, selectedItem?.compliance_status);
          }, 100)
        }
        if (form_type === '26Q' && selectedItem?.compliance_status === 'NON FILER') {
          setTimeout(() => {
            setFieldValue('deduction_reason', 'U');
            tdsRateCalculation(values, 'U', 'deduction_reason', setFieldValue, selectedItem?.compliance_status);
          }, 100)

        }
      }
    }
  }

  const getVerifyStatusForPan = (pan) => {
    setPan((prev) => {
      prev = pan
      return prev
    })
    setSection((prev) => {
      prev = 'VERIFY_PAN'
      return prev
    })
    setStartConnector(true);
  }


  const getDefaultLinksHtml = (values) => {
    // if (values.deductee_pan === '' || values.deductee_pan === null) return (null);
    if ((values.deductee_pan === '' || values.deductee_pan === null) && (values.deductee_name === '' || values.deductee_name === null)) return (null)
    return (
      <>
        <div className={'row'}>
          <div class="updatedeductee_trans_compst_bgclr">
            <div class="row">
              <div class="col-md-12 text-right">
                <span class="reverifypan-textclr" style={{ cursor: 'pointer' }} onClick={(e) => {
                  getVerifyStatusForPan(values.deductee_pan)
                }}>
                  Verify PAN from TRACES
                </span>
                {panAsOnDate && panAsOnDate !== '' ? <br /> : null}
                <span class="px-2 lineclr_grey">|</span>
                <span class="reverifypan-textclr" style={{ cursor: 'pointer' }} onClick={() => {
                  getComplianceStatusForPan(values.deductee_pan)
                }}>
                  Get Compliance Status
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  const getStatuColorCode = (pan_status) => {

    if (!pan_status) return { label: 'Unverified', color: 'f54337' };

    if (pan_status === 'PAN_AVAILABLE_AND_VERIFIED') return { label: 'Operative', color: '8bc34a' };

    if (pan_status === 'PAN_AVAILABLE_BUT_UNVERIFIED') return { label: 'Unverified', color: 'f54337' };

    if (pan_status === 'PAN_AVAILABLE_BUT_INACTIVE') return { label: 'Inoperative', color: 'f54337' };

    if (pan_status === 'PAN_INVALID') return { label: 'Invalid', color: 'f54337' };

    if (pan_status === 'PAN_NOT_AVAILABLE') return { label: 'PANNOTAVBL', color: 'fdd735' };

    const status = pan_status?.toLowerCase();

    if (status?.includes('valid') && status?.includes('inoperat')) {
      return { label: 'Inoperative', color: 'f54337' };
    } else if (status?.includes('valid') && status?.includes('operat')) {
      return { label: 'Operative', color: '8bc34a' };
    } else if (status?.includes('invalid')) {
      return { label: 'Invalid', color: 'f54337' };
    } else if (status?.includes('not found')) {
      return { label: 'PANNOTAVBL', color: 'fdd735' };
    } else {
      return { label: 'Unverified', color: 'f54337' };
    }

  }

  const getHtml2 = (form_type, values, panStatus, panComplianceStatus) => {

    if (deducteeTransactionID === undefined && panStatus === undefined) return;

    if (panComplianceStatus === null && (panStatus === null || panStatus === undefined || panStatus === '')) {
      return getDefaultLinksHtml(values);
    }
    panStatuscolorMapping(panStatus);
    const pan_status = getStatuColorCode(panStatus);
    return (
      <>
        {
          pan_status?.label === 'PANNOTAVBL'
            ?
            <div className={'row'} style={{ margin: '5px 0px 10px 0px', textAlign: 'left' }}>
              <div class="updatedeductee_trans_compst_bgclr">
                <div class="row">
                  <div class="col-md-8">
                    <span class="status-textfs" style={{ fontSize: '11px' }}>Status:</span>
                    <span class="pr-1" style={{ color: `#${pan_status?.color}`, fontSize: '10px' }}>
                      {' '} {pan_status?.label}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className={'row'} style={{ margin: '5px 0px 10px 0px', textAlign: 'left' }}>
              <div class="updatedeductee_trans_compst_bgclr">
                <div class="row">
                  <div class="col-md-6">
                    <span class="status-textfs">Status:</span>
                    <span class="pr-1" style={{ color: `#${pan_status?.color}`, fontSize: '10px' }}>
                      {' '} {pan_status?.label}
                    </span>
                    <span class="reverifypan-textclr" style={{ cursor: 'pointer', fontSize: '9px', fontStyle: 'italic' }} onClick={(e) => {
                      getVerifyStatusForPan(values.deductee_pan)
                    }}>
                      Verify PAN from TRACES
                    </span>
                  </div>

                  <div class="col-md-6 text-right">

                    <span class="nonflier-textclr pl-2 pr-1" style={{ color: (panComplianceStatus.includes('REGULAR') || panComplianceStatus.includes('Regular')) ? '#8bc34a' : '', fontWeight: '500' }}>
                      {panComplianceStatus} {panAsOnDate && panAsOnDate !== '' ? `(as on ${panAsOnDate})` : null}
                    </span>
                    {panAsOnDate && panAsOnDate !== '' ? <br /> : null}
                    <span class="getstatus-textclr" style={{ cursor: 'pointer', fontStyle: 'italic' }} onClick={() => {
                      getComplianceStatusForPan(values.deductee_pan)
                    }}>
                      Get Compliance Status
                    </span>

                    {/* {
                      (panComplianceStatus !== null && panComplianceStatus !== undefined)
                        ?
                        <>
                          <span class="nonflier-textclr pl-2 pr-1" style={{ color: (panComplianceStatus.includes('REGULAR') || panComplianceStatus.includes('Regular'))  ? '#22c91d' : '' }}>
                            {panComplianceStatus} {panAsOnDate && panAsOnDate !== '' ? `(as on ${panAsOnDate})` : null}
                          </span>
                        </>
                        :
                        <>
                          <span class="getstatus-textclr" style={{ cursor: 'pointer', fontStyle: 'italic' }} onClick={() => {
                            getComplianceStatusForPan(values.deductee_pan)
                          }}>
                            Get Compliance Status
                          </span>
                        </>
                    } */}

                  </div>

                </div>
              </div>
            </div>
        }

      </>
    )
  }


  const getNatureOfPaymentOptionLabelById = (nature_of_payment_id) => {
    if (nature_of_payment_id === '') return null;
    let natureOfPaymentText = null;
    paymentCodesArray.forEach((item) => {
      if (parseInt(item.nature_of_payment_id) === parseInt(nature_of_payment_id) && item.description.length > 80) {
        natureOfPaymentText = item.description
      }
    })
    return natureOfPaymentText;
  }

  const calculateLateInterest = (values) => {
    const paymentDate = values?.payment_date;
    const deductionDate = values?.deduction_date;
    const tdsAmount = values?.tds_amtp
    const interestAmount = interestCalculationOnLateDeduction(paymentDate, deductionDate, tdsAmount);
    return (
      `Late Deduction Interest : ${formatNumber(interestAmount)}`
    )
  }

  const validateRateAndTdsAmountDifference = (field, value, values) => {
    let paid_amtp = values?.paid_amtp;
    let tds_amtp = values?.tds_amtp;
    let tds_rate = values?.tds_rate_b100;

    if (field === 'tds_amtp') {
      tds_amtp = value;
    } else if (field === 'tds_rate_b100') {
      tds_rate = value;
    } else if (field === 'paid_amtp') {
      paid_amtp = value;
    }
    let showWarning = false;

    if (actualSectionRate !== null && parseFloat(paid_amtp) > 0) {

      showWarning = parseInt(actualSectionRate) !== parseInt(tds_rate);

      if (field === 'tds_amtp') {
        const actualTdsAmount = (parseInt(actualSectionRate) / 100) * parseFloat(paid_amtp);
        showWarning = parseFloat(tds_amtp) !== parseFloat(actualTdsAmount);
      }
    }
    setIsTdsRateDifference(showWarning);
  }

  const setInOerativePanStatusRate = (setFieldValue) => {
    if (panStatus === "PAN_AVAILABLE_BUT_INACTIVE") {
      setFieldValue('tds_rate_b100', INOPERATIVE_PAN_STATUS_RATE);
      setTdsRateB100(INOPERATIVE_PAN_STATUS_RATE);
    }
  }

  let width1 = document.body.clientWidth;

  const keyPress = useCallback(
    (e) => {
      /* enter */
      if (e.keyCode === 13) {
        e.preventDefault();
        $("#save-deductee-trn").trigger('click');
      }
      /* alt+s */
      if (e.altKey && !e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        $('#save-deductee-trn').trigger('click');
        $('#save-deductee-trn').attr('disabled', true);
      }
      /* alt+shift+s */
      if (e.altKey && e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        $('#save-deductee-trn-new').trigger('click');
        $('#save-deductee-trn-new').attr('disabled', true);
      }
      /* Esc */
      if (e.keyCode === 27) {
        //$('#close-deductee-trn-new').trigger('click');
        onClose();
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);


  const resetDeductionReason = (values, setFieldValue) => {
    if ((headerDataDetails.form_type === '24Q' || headerDataDetails.form_type === '26Q') && values.selected_pan === 'PANNOTAVBL') {
      setFieldValue('deduction_reason', "C");
      setToggleAllOption(false);
    }
  }

  const getDeducteeLabel = (formType) => {
    switch (formType) {
      case '24Q': return 'Employee';
      case '27EQ': return 'Collectee';
      default: return 'Deductee';
    }
  }

  // const handleValdationOnBlur = (e, setFieldError, values) => {
  //   const { name, value } = e.target;

  //   if (name === 'tds_amtp') {

  //     let paid_amtp = values?.paid_amtp ?? 0;
  //     let tds_amtp = value;
  //     let tds_rate = values?.tds_rate_b100 ?? 0;
  //     let actualTdsAmount = 0;
  //     if (actualSectionRate !== null && parseFloat(paid_amtp) > 0) {
  //       if (tds_rate > 0) {
  //         actualTdsAmount = (parseInt(tds_rate) / 100) * parseFloat(paid_amtp);
  //       } else {
  //         actualTdsAmount = (parseInt(actualSectionRate) / 100) * parseFloat(paid_amtp);
  //       }

  //       setTaxCalculatedMismatched('');
  //       if (parseFloat(tds_amtp) < parseFloat(actualTdsAmount)) {
  //         //setFieldError('tds_amtp', 'Amount can not be less than Tax calculated');
  //         setTaxCalculatedMismatched('Amount can not be less than Tax calculated');
  //       }
  //     }
  //   }
  // }

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {startConnector
        ? (<ConnectorAdapter
          show={startConnector}
          handleClose={() => setStartConnector(false)}
          setPort={setPort}
          handleProceed={() => {
            if (section === 'VERIFY_PAN') {
              setCheckTracesCredentials(true)
            } else if (section === 'COMPLIANCE_CHECK') {
              setComplianceCheck(true)
            }
          }}
        />)
        : null}

      {checkTracesCredentials
        ? <TracesConnector
          deductorId={deductorId}
          returnId={null}
          setCaptchaRef={setCaptchaRef}
          handleProceed={(res) => {
            setCheckTracesCredentials(false);
            setVerifySinglePan(true);
          }}
          port={port}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }
      {verifySinglePan && captchaRef && port
        ? <SinglePanVerify
          deductorId={deductorId}
          port={port}
          captchaRef={captchaRef}
          setError={setErrors}
          setVerifyPANResult={setVerifyPANResult}
          pan={pan}
          setLoading={setLoading}
          setTracesPanData={(res) => {
            const status = res.status;
            setPanStatus(status);
            if (status && status.toLowerCase() === 'active') {
              setPanStatusColor('22c91d');
            } else if (status && (status.toLowerCase() === 'invalid' || status.toLowerCase() === 'not found')) {
              setPanStatusColor('ff0000');
            }
          }}
          handleProceed={() => null}
        />
        : null
      }

      {
        complianceCheck && port
          ?
          <InsightsConnectorWithCaptcha
            deductorId={deductorId}
            returnId={null}
            port={port}
            pan={pan}
            handleProceed={(res) => {
              if (res?.data && res.data.length > 0) {
                const statusData = res.data;
                statusData.forEach(item => {
                  if (item.panAadhaarLinkStatus !== '') {
                    const status = item.aplicableFlag;
                    setPanComplianceStatus((prev) => {
                      prev = (status === 'N') ? 'REGULAR FILER' : ' NON FILER'
                      return prev
                    })
                  }
                })
              }
              setPanAsOnDate((prev) => {
                prev = res.lastUpdateDate
                return prev
              })
              setComplianceCheck(false)
            }}
            setInsightsCaptchaRef={setInsightsCaptchaRef}
            setVerifyCreds={() => null}
            creds={null}
            setParentLoading={setLoading}
            captchaVerify={true}
            handleClose={() => {
              //reset all values to start again
              setStartConnector(false);
              setCheckTracesCredentials(false);
              setVerifySinglePan(false);
              setComplianceCheck(false);

            }}
            setParentError={setError}
          />
          : null
      }

      {confirmPopup &&
        <ConfirmAlertPopup
          show={true}
          handleClose={() => { setConfirmPopup(false); setShowEditForm(false) }}
          handleProceed={() => {
            setConfirmPopup(false);

          }}
          confirmText={`This transaction was split during mapping, any change in this transaction will delink this transaction from the Original Linked Transaction, Do you wish to proceed?`}
          handleProceed2={() => { setConfirmPopup(false); setShowEditForm(false) }}
          title={"Alert"}
          posclass="editdedtrans_alertboxposition"
        />
      }

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={deducteeTransactionFormInfo}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={deducteeTransactionsFormValidationSchema(headerDataDetails.form_type)}
        validate={values => {
          const errors = {};
          // let paid_amtp = values?.paid_amtp ?? 0;
          // let tds_amtp = values?.tds_amtp ?? 0;
          // let tds_rate = values?.tds_rate_b100 ?? 0;
          // let actualTdsAmount = 0;
          // if (actualSectionRate !== null && parseFloat(paid_amtp) > 0) {
          //   if (tds_rate > 0) {
          //     actualTdsAmount = (parseInt(tds_rate) / 100) * parseFloat(paid_amtp);
          //   } else {
          //     actualTdsAmount = (parseInt(actualSectionRate) / 100) * parseFloat(paid_amtp);
          //   }
          //   // if (parseFloat(tds_amtp) < parseFloat(actualTdsAmount)) {
          //   //   errors.tds_amtp = 'Amount can not be less than Tax calculated';
          //   // }
          // }

          // if (values.deduction_reason === 'A' && Number(values.tds_rate_b100) === 0 && headerDataDetails.form_type !== '24Q') {
          //   errors.tds_rate_b100 = 'Rate cannot be 0 if Reason of Deduction is A (Lower Deduction).';
          // }

          return errors;
        }}
        onSubmit={(values) => {
        }}
      >
        {({
          errors,
          values,
          validateForm,
          setErrors,
          resetForm,
          setFieldValue,
          setFieldError,
        }) => {
          const submitAndResetForm = () => {

            setCustomError(() => null);
            if (
              [76, 77, 78, 80].includes(Number(values?.nature_of_payment_id))
              &&
              (
                String(values?.deductee_name).indexOf('PANNOTAVBL') !== -1 ||
                String(values?.deductee_name).indexOf('PANINVALID') !== -1 ||
                String(values?.deductee_name).indexOf('PANAPPLIED') !== -1
              )
            ) {
              setCustomError((prev) => {
                prev = 'Deductee should have a valid PAN. Default PAN values, such as, PANNOTAVBL, PANAPPLIED, PANINVALID are not allowed.'
                return prev
              });
              return false;
            }

            validateForm().then(async (data) => {
              if (Object.keys(data).length > 0) {
                setErrors(data);
              } else {
                let payment_date = values.payment_date;
                let deduction_date = values.deduction_date;
                const result = await createDeducteeTransactions(
                  values,
                  false,
                );
                if (result) {
                  resetForm(deducteeTransactionsFormFields);
                  setFieldValue('payment_date', '', false);
                  setFieldValue('tds_rate_b100', '0', false);
                  setFieldValue('payment_date', payment_date, false);
                  setFieldValue('deduction_date', deduction_date, false);
                  setFieldValue('challan_id ', '', false);
                  setFieldValue('tds_amtp ', 0, false);
                  setTdsRateB100(0);
                  setTdsAmount(0);
                  setChallanVals('');
                  setNatureOfPaymentIDVal('');
                  setTotalTdsAmount('0');
                  setChallanUnutilizedValue('0');
                  setTimeout(function () {
                    $('#nature_of_payment_id').focus();
                    $('.deductee-form').scrollTop(0);
                  });
                }
              }
              $('#save-deductee-trn-new').attr('disabled', false);
              $('#save-deductee-trn').attr('disabled', false);
            });
          };
          const submitForm = (values, payment_date) => {
            if ((tdsAmount && tdsAmount !== undefined) || (tdsRateB100 && tdsRateB100 !== undefined)) {
              values.tds_amtp = tdsAmount;
              values.tds_rate_b100 = tdsRateB100;
            }
            //values.challan_id = challanVals;
            values.challan_id = challanVals?.challan_id;
            const dateVal = dateValidation(values, payment_date);

            setCustomError(() => null);
            if (
              [76, 77, 78, 80].includes(Number(values?.nature_of_payment_id))
              &&
              (
                String(values?.deductee_name).indexOf('PANNOTAVBL') !== -1 ||
                String(values?.deductee_name).indexOf('PANINVALID') !== -1 ||
                String(values?.deductee_name).indexOf('PANAPPLIED') !== -1
              )
            ) {
              setCustomError((prev) => {
                prev = 'Deductee should have a valid PAN. Default PAN values, such as, PANNOTAVBL, PANAPPLIED, PANINVALID are not allowed.'
                return prev
              });
              return false;
            }

            if (dateVal) {
              validateForm().then(async (data) => {
                if (Object.keys(data).length > 0) {
                  setErrors(data);
                } else {
                  await createDeducteeTransactions(
                    values,
                    true,
                  );
                }
                $('#save-deductee-trn-new').attr('disabled', false);
                $('#save-deductee-trn').attr('disabled', false);

              });
            }

          };
          return (<>
            {showCreateForm
              ? (
                <AddDeductee
                  onClose={() => HandleClose(setFieldValue, values)}
                  deductorId={deductorId}
                  showDeducteeFormLeft={true}
                  componentImport={false}
                  left={width1 - 1850}
                />
              )
              : null}

            <Form key={1} autoComplete="off" id="editdeducteeform">
              <ErrorFocus />
              <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '15px 0px 5px 0px' }}>
                  {
                    ldcListStatus === true
                      ?
                      <div className="row" style={{ padding: '0 15px' }}>
                        <div className="col-md-11 col-sm-11 col-lg-11 col-xl-11 text-left">
                          <h6 style={{ fontSize: '1rem', cursor: 'default', padding: '1px 0px 6px 0px' }}>
                            Update {getDeducteeLabel(formType)} Transaction
                          </h6>
                        </div>
                      </div>
                      :
                      deducteeTransactionID
                        ?
                        <div className="row">
                          <div className="col-md-9 col-sm-10 col-lg-9 col-xl-9">
                            <div className='row'>
                              <div className="col-md-5 col-sm-2 col-lg-4 col-xl-4 text-left" style={{ position: 'relative', top: '-6px' }}>
                                <span style={currentDeductee > 0 ? { cursor: 'pointer', color: '#0000ff' } : { cursor: 'default', fontSize: '14px', fontWeight: '500' }} onClick={() => { return currentDeductee > 0 ? prevDeductee() : null }} className="pr-1">&lt; Previous</span>
                              </div>

                              <div className="col-md-7 col-sm-8 col-lg-8 col-xl-8 text-center" style={{ paddingTop: '0px' }}>
                                <h6 style={{ fontSize: '1rem', cursor: 'default', fontWeight: '500', padding: '1px 0px 6px 0px' }}>
                                  Update {getDeducteeLabel(formType)} Transaction
                                </h6>
                              </div>
                            </div>

                          </div>

                          <div className="col-md-8 col-sm-2 col-lg-2 col-xl-3 text-right" style={{ cursor: 'pointer', position: 'relative', top: '-6px', color: '#007bff' }} >
                            <span style={currentDeductee < (deducteeTransactionsList?.length - 1) ? { cursor: 'pointer', color: '#0000ff', paddingRight: '10px' } : { cursor: 'default', paddingRight: '10px', fontSize: '14px', fontWeight: '500', color: '#000' }} onClick={() => { return currentDeductee < (deducteeTransactionsList?.length - 1) ? nextDeductee() : null }} className="pl-1">Next &gt;</span>
                            {'  '}
                            {' '}
                          </div>

                        </div>
                        :
                        <div className="row" style={{ padding: '0 15px' }}>
                          <div className="col-md-11 col-sm-11 col-lg-11 col-xl-11 text-left">
                            <h6 style={{ fontSize: '1rem', cursor: 'default', padding: '1px 0px 6px 0px' }}>
                              Create {getDeducteeLabel(formType)} Transaction
                            </h6>
                          </div>
                        </div>
                  }
                </kendo-dialog-titlebar>}
                onClose={() => onClose(false, '')}
                initialWidth={700}
                initialTop={50}
                totalPopups={1}
                initialLeft={width1 - 715}
                isModalView={true}
                initialHeight={'auto'}
                className={'create-deductee-trans-form'}
              >

                <div className="cdf mt-1">
                  <div className="deductordetails">
                    <div className="row" style={{ paddingLeft: '15px', paddingRight: '15px' }}>


                      <div className={'form-group col-md-8 mb-0'} >
                        <label htmlFor="inputEmail4" className="labpb4">
                          {
                            //(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collectee Name' : 'Deductee Name'
                            formType === '27EQ'
                              ? 'Collectee Name'
                              : formType === '24Q' ? 'Employee Name' : 'Deductee Name'
                          }
                          {' '}
                          <span className="red_text">*</span>
                        </label>
                        <div>
                          <div className=" dninpboxsm">
                            <AutoComplete
                              data={deducteeData}
                              id="deductee_name"
                              textField="text"
                              dataItemKey="id"
                              value={values.deductee_name}
                              className={`form-control dt_dnameinputbox ${(errors?.deductee_name || customError || errors.deductee_id) ? 'input-error1' : ''}`}
                              popupSettings={{ className: 'autoCompDTF downslewidth', animate: { closeDuration: 500 } }}
                              onChange={(e) => {
                                handleDeducteeNameChange(e);
                                setFieldValue(
                                  'deductee_name',
                                  e.target.value,
                                );
                                if (e.target.value.length < 1) {
                                  setFieldValue('deductee_id', '');
                                  setFieldValue('deductee_pan', '');
                                  setFieldValue('deduction_reason', '');
                                  setToggleAllOption(true);
                                  tdsRateCalculation(values, e.target.value, 'deductee_name', setFieldValue);
                                  setPanStatus(null)
                                  setPanStatusColor(null);
                                  setPanComplianceStatus(null);
                                  setPanAsOnDate(null);
                                }
                                setFieldValue('cash_withdrawal_option', '');
                                getPanStatus(values, e.target.value, headerDataDetails.form_type, setFieldValue);

                                setDeducteeNonResidentStatus(false);
                              }}
                              onClose={(e) => {
                                selectedDeducteeName(e.target.value, setFieldValue, values);
                              }}
                              listNoDataRender={(element) => { return listNoDataRender(element, setFieldValue, values); }}
                              itemRender={(li, itemProps) => { return itemRender(li, itemProps, setFieldValue, values); }}
                              placeholder={(formType === '27EQ') ? 'Enter Collectee Name' : formType === '24Q' ? 'Enter Employee Name' : 'Enter Deductee Name'}
                            />
                            <input
                              type="hidden"
                              name="deductee_name"
                              id="deductee_name"
                              value={values.deductee_name}
                            />
                            <input
                              type="hidden"
                              name="deductee_id"
                              id="deductee_id"
                              value={values.deductee_id}
                              onClick={(e) => {
                                setFieldValue(
                                  'deductee_id',
                                  e.target.value,
                                );
                              }}
                            />
                          </div>
                        </div>
                        {
                          customError
                            ?
                            <>
                              <span className="error_message clrbtnfrmDeducteetrans">
                                {customError}
                              </span>
                            </>
                            :
                            <>
                              {errors.deductee_name ? (

                                <span className="error_message clrbtnfrmDeducteetrans">
                                  <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                  {errors.deductee_name}
                                </span>
                              ) : null}
                              {!errors.deductee_name && errors.deductee_id ? (
                                <span className="error_message clrbtnfrmDeducteetrans">
                                  <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                  {errors.deductee_id}
                                </span>
                              ) : null}
                            </>
                        }

                        {/* <!-- new status heml --> */}
                        {getHtml2(headerDataDetails.form_type, values, panStatus, panComplianceStatus)}
                        {/* <!-- new status heml --> */}

                      </div>
                      <div className={'form-group col-sm-4 mb-0'} >
                        <div className="form-group">
                          <label
                            htmlFor="inputEmail4"
                            className="labpb4"
                          >
                            {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}
                            <span className="red_text">*</span>
                          </label>
                          <div className="sltWidt">
                            <Select
                              className={`react-select-container ${errors?.nature_of_payment_id ? 'input-error1' : ''}`}
                              classNamePrefix="react-select"
                              title={getNatureOfPaymentOptionLabelById(natureOfPaymentIDVal)}
                              value={paymentCodesArray[paymentCodesArray.findIndex((item) => String(item.nature_of_payment_id) === String(values.nature_of_payment_id))] ?? null}
                              onChange={(selected) => {
                                setNatureOfPaymentIDVal(selected.nature_of_payment_id);
                                onChangeDeductionReasonForSpecific(values.deduction_reason, parseInt(selected.nature_of_payment_id));
                                setFieldValue(
                                  'nature_of_payment_id',
                                  selected.nature_of_payment_id,
                                );
                                setFieldValue('nature_of_remittance', [9, 10, 11, 54, 71].includes(Number(selected.nature_of_payment_id)) ? 'INTEREST_PAYMENT' : values.nature_of_remittance)
                                tdsRateCalculation(
                                  values,
                                  selected.nature_of_payment_id,
                                  'nature_of_payment_id',
                                  setFieldValue,
                                  panComplianceStatus
                                );
                                Number(selected.nature_of_payment_id) === 10 && values?.deduction_reason === "C" && setFieldValue('deduction_reason', "");
                                setIsTdsRateDifference(false);
                                //setToggleAllOption(true);
                                setInOerativePanStatusRate(setFieldValue);
                                resetDeductionReason(values, setFieldValue);

                              }
                              }
                              options={paymentCodesArray}
                              placeholder="Select"
                              openMenuOnFocus={true}
                            />
                          </div>
                          {errors.nature_of_payment_id ? (
                            <span className="overflowtooltipsurcharge">
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Section is required"
                                style={{ color: 'red', fontSize: '11px' }}
                                className="error_message"
                              >
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.nature_of_payment_id}
                              </span>
                            </span>

                          ) : null}

                        </div>
                      </div>


                      <div className={'form-group col-md-4 frDt grcolmb0'} id="paymentDateDiv" >
                        <label
                          htmlFor="inputEmail4"
                          className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Received Date' : 'Payment Date'}
                          {' '}
                          <span className="red_text">*</span>
                          {' '}
                        </label>
                        <DatePicker
                          selectedDate={values.payment_date ? values.payment_date : null}
                          maxDate={headerDataDetails.quarter_end_date ? new Date(headerDataDetails.quarter_end_date) : new Date()}
                          placeHolder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Amt Received Date' : 'Enter Payment Date'}
                          onChange={(date) => {
                            setFieldValue('payment_date', date);
                            autoPopulateDeductionDate(date, setFieldValue, values);
                            setAutoChangeCall(1);
                            calculateLateInterest(values);
                            setFieldValue('cash_withdrawal_option', '');
                          }}
                          errors={errors.payment_date ? errors.payment_date : errors.paymentDate ? errors.paymentDate : ""}
                        />
                        {errors.payment_date ? (

                          <div className="overflowtooltipsurcharge datepay_errorsmsbox" >
                            <span data-toggle="tooltip" data-placement="top" title="Date of Payment is required" style={{ color: 'red', fontSize: '11px' }} className="error_message clrbtnfrmDeducteetrans" >
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.payment_date}
                            </span>
                          </div>
                        ) : null}
                        {errors.paymentDate ? (
                          <span className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.paymentDate}
                          </span>
                        ) : null}
                      </div>

                      <div className={'form-group col-md-4 flDt msnd'} id="deductionDateDiv">
                        <div className={values.tds_rate_b100 <= 0 && values.tds_amtp <= 0 ? 'hideDiv1' : ''}>
                          <label htmlFor="inputEmail4" className="labpb4">
                            {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Date' : 'Deduction Date'}
                          </label>
                          <DatePicker
                            selectedDate={values.deduction_date ? values.deduction_date : null}
                            onChange={(date) => {
                              setFieldValue('deduction_date', date);
                              tdsRateCalculation(values, date, 'deduction_date', setFieldValue);
                              calculateLateInterest(values);
                            }}
                            minDate={
                              headerDataDetails.quarter_start_date
                                ? new Date(
                                  headerDataDetails.quarter_start_date,
                                )
                                : new Date()
                            }
                            maxDate={new Date()}
                            errors={errors.deduction_date ? errors.deduction_date : errors.deductionDate ? errors.deductionDate : ""}
                            placeHolder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Collection Date' : 'Enter Deduction Date'}
                          />
                          {errors.deduction_date ? (
                            <span className="overflowtooltipsurcharge datepay_errorsmsbox" >
                              <span data-toggle="tooltip" data-placement="top" title="Date of Deduction is required" style={{ color: 'red', fontSize: '11px' }} className="error_message clrbtnfrmDeducteetrans">
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.deduction_date}
                              </span>
                            </span>
                          ) : null}
                          {errors.deductionDate ? (
                            <span className="error_message">
                              <br />
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.deductionDate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      {headerDataDetails.form_type === '27Q' &&
                        <div className={'form-group col-md-4 tdsratebox tdsrateboxdd'}>
                          <label htmlFor="inputEmail4" className="labpb4">
                            Rate (%)
                            <span className="red_text">*</span>
                          </label>
                          {values.applicable_tds_rate && values.applicable_tds_rate === 'B' ? (
                            <NumericInputWithDecimalTwo
                              type="text"
                              className={`form-control text-left ${errors?.tds_rate_b100 ? 'input-error1' : ''}`}
                              id="tds_rate_b100"
                              value={values.tds_rate_b100 !== '' ? values.tds_rate_b100 : ''}
                              placeholder="Enter TDS Rate"
                              onChange={(e) => {
                                handleOnChange(
                                  'tdsRateB100',
                                  e.target.value,
                                );
                                setFieldValue(
                                  'tds_rate_b100',
                                  e.target.value.trim(),
                                );
                                formValueChange2(e.target.value.trim(), setFieldValue, 'tds_amtp');
                                validateRateAndTdsAmountDifference('tds_rate_b100', e.target.value.trim(), values);
                              }}
                            // onBlur={(e) => {
                            //   if(!values.deduction_reason && Number(e.target.value) === 0){
                            //       setError('Rate cannot be 0 if Reason of Deduction is not specified.');
                            //   }
                            // }}

                            />
                          ) : (
                            <>
                              <TdsRateInput
                                value={tdsRateB100 !== '' ? tdsRateB100 : ''}
                                onChange={(v) => {
                                  setFieldValue('tds_rate_b100', v.trim());
                                  handleOnChange('tdsRateB100', v.trim());
                                  formValueChange2(v.trim(), setFieldValue, 'tds_amtp');
                                  validateRateAndTdsAmountDifference('tds_rate_b100', v.trim(), values);
                                }}
                                data={
                                  ['0', '5', '7.5', '10', '18.75',
                                    '20', '22.5', '30', '40', '60',
                                  ]
                                }
                                errors={errors.tds_rate_b100 ? errors.tds_rate_b100 : ""}
                              // onBlur={(v) => {
                              //   if(!values.deduction_reason && Number(v.trim()) === 0){
                              //       setError('Rate cannot be 0 if Reason of Deduction is not specified.');
                              //   }
                              // }}
                              />

                            </>
                          )}
                          {errors.tds_rate_b100 ? (
                            <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.tds_rate_b100}
                            </span>
                          ) : null}
                        </div>
                      }

                      {headerDataDetails.form_type !== '24Q' && headerDataDetails.form_type !== '27Q' ?
                        <div className={'form-group col-md-4 tdsratebox tdsrateboxdd'}>
                          <label htmlFor="inputEmail4" className="labpb4">
                            Rate (%)
                            <span className="red_text">*</span>
                          </label>
                          {headerDataDetails.form_type === '24Q' ? (
                            <select
                              className={`custom-select ${errors?.tds_rate_b100 ? 'input-error1' : ''}`}
                              id="TdsRateB100"
                              value={values.tds_rate_b100}
                              disabled={true}
                            >
                              <option value="0">NA</option>
                            </select>
                          ) : (
                            <>
                              <TdsRateInput
                                value={`${tdsRateB100}`}
                                onChange={(v) => {
                                  setFieldValue('tds_rate_b100', v.trim());
                                  handleOnChange('tdsRateB100', v.trim());
                                  if (parseInt(values.nature_of_payment_id) === 36 || parseInt(values.nature_of_payment_id) === 53) {
                                    excessWithdrawTdsCalculation(values, setFieldValue, 'tds_rate_b100', v.trim());
                                  }
                                  else {
                                    formValueChange2(v.trim(), setFieldValue, 'tds_amtp');
                                  }
                                  validateRateAndTdsAmountDifference('tds_rate_b100', v.trim(), values);
                                  //   if(!values.deduction_reason && Number(v.trim()) === 0){
                                  //     setError('Rate cannot be 0 if Reason of Deduction is not specified.');
                                  // }
                                }}
                                data={
                                  (headerDataDetails && headerDataDetails.form_type === '27EQ') ?
                                    ['0', '0.005', '0.075', '0.1', '0.75', '1', '1.5',
                                      '1.875', '2', '2.5', '3.75', '5', '10', '20']
                                    :
                                    ['0', '0.1', '0.75', '1', '1.5', '2', '3.75', '5', '7.5', '10', '15', '18.75',
                                      '20', '22.5', '25', '30', '40']
                                }
                                errors={errors.tds_rate_b100 ? errors.tds_rate_b100 : ""}

                              />

                            </>
                          )}
                          {deducteeTrnsactnInfo.tds_rate_b100_old ? (
                            <span style={{ color: `#${deducteeTrnsactnInfo.tds_rate_b100_color}`, float: 'right', fontSize: '10px' }}>
                              OLD RATE
                              {deducteeTrnsactnInfo.tds_rate_b100_old}
                            </span>
                          ) : null}
                          {' '}
                          {errors.tds_rate_b100 ? (
                            <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.tds_rate_b100}
                            </span>
                          ) : null}
                        </div>
                        : null}

                      {show27EQExtraFields && headerDataDetails.form_type !== '24Q' ? (
                        <div className={'form-group col-md-4'}>
                          <label htmlFor="purchase_amtp" className="labpb4">
                            Amt of Purchase
                            {' '}
                            <span className="red_text">*</span>
                          </label>
                          <NumericInputWithDecimalTwo
                            type="text"
                            className={`form-control text-right ${errors?.purchase_amtp ? 'input-error1' : ''}`}
                            id="purchase_amtp"
                            value={values.purchase_amtp}
                            placeholder="Enter Amount Purches"
                            onChange={(e) => {
                              setFieldValue('purchase_amtp', e.target.value);
                            }}
                          />

                          <div
                            style={{
                              position: 'absolute',
                              left: '19px',
                              top: '26px',
                              fontSize: '14px',
                            }}
                          >
                            ₹
                          </div>

                          {' '}

                          {errors.purchase_amtp ? (
                            <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.purchase_amtp}
                            </span>
                          ) : null}

                        </div>

                      ) : null}


                      <div className={'form-group col-md-4 grcolmb0'}>
                        <label htmlFor="paid_amtp" className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Received' : 'Amount Paid'}
                          {' '}
                          <span className="red_text">*</span>
                        </label>
                        <NumericInputWithDecimalTwo
                          type="text"
                          className={`form-control text-right ${errors?.paid_amtp ? 'input-error1' : ''}`}
                          id="paid_amtp"
                          value={values.paid_amtp}
                          placeholder="Enter Amount Paid"
                          maxLength={14}
                          onChange={(e) => {
                            handleOnChange(
                              'paidAmount',
                              e.target.value,
                            );
                            setFieldValue('paid_amtp', e.target.value);
                            setPaidAmount(e.target.value);
                            if ([36, 53, 81, 82].includes(parseInt(values.nature_of_payment_id))) {
                              excessWithdrawTdsCalculation(values, setFieldValue, 'paid_amtp', e.target.value);
                            }
                            else {
                              formValueChange(e, setFieldValue, values, 'tds_amtp');
                            }
                            validateRateAndTdsAmountDifference('paid_amtp', e.target.value, values);
                            if (e.target.value.trim().length > 0) {
                              setFieldError('tds_amtp', '');
                            }
                          }
                          }
                          onKeyUp={() => {
                            validateReasonSection(
                              values,
                              setFieldValue,
                              '',
                            );
                          }}
                        />

                        <div
                          style={{
                            position: 'absolute',
                            left: '19px',
                            top: '26px',
                            fontSize: '14px',
                          }}
                        >
                          ₹
                        </div>

                        {' '}

                        {errors.paid_amtp ? (
                          <span className="overflowtooltipsurcharge" >
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Amount paid should be greater than 0"
                              style={{ color: 'red', fontSize: '11px' }}
                              className="error_message"
                            >
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.paid_amtp}
                            </span>
                          </span>
                        ) : null}
                      </div>

                      <div className={'form-group col-md-4 grcolmb0'}>
                        <label htmlFor="tds_amtp" className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Tax collected' : 'Tax Deducted'}
                          <span className="red_text">*</span>
                        </label>
                        <NumericInputWithDecimalTwo
                          type="text"
                          className={`form-control text-right ${(isTdsRateDifference === true && headerDataDetails.form_type !== '24Q') ? 'warning' : ''} ${errors?.tds_amtp ? 'input-error1' : ''}`}
                          title={`${(isTdsRateDifference === true && headerDataDetails.form_type !== '24Q') ? 'TDS amount entered is not equal to  Rate X Amount Paid/Credited. Please check.' : ''}`}
                          id="tds_amtp"
                          name="tds_amtp"
                          maxLength={14}
                          value={values.tds_amtp}
                          placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Enter Tax collected' : 'Enter Tax Deducted'}
                          onChange={(e) => {
                            handleOnChange('tdsAmount', e.target.value);
                            setFieldValue('tds_amtp', e.target.value);
                            calculateLateInterest(values);
                            validateRateAndTdsAmountDifference('tds_amtp', e.target.value, values);
                            if (e.target.value.trim().length > 0) {
                              setFieldError('tds_amtp', '');
                            }
                          }}
                          onKeyUp={(e) => {
                            validateReasonSection(
                              values,
                              setFieldValue,
                              'errorMsgTds',
                            );
                            change24QTaxDeducted(setFieldValue, values.paid_amtp, values.deductee_name, 'tds_amtp', e.target.value);
                          }}
                        // onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                        />
                        <span className="overflowtooltipsurcharge" >
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={errors.tds_amtp}
                            id="errorMsgTds"
                            style={{ color: 'red', fontSize: '11px' }}
                          />
                        </span>

                        <span class="createdeductee_trans_subtextfs float-left">
                          {calculateLateInterest(values, setFieldValue)}
                        </span>

                        <div
                          style={{
                            position: 'absolute',
                            left: '19px',
                            top: '26px',
                            fontSize: '14px',
                          }}
                        >
                          ₹
                        </div>
                        {errors.tds_amtp ? (
                          <>
                            <br></br>
                            <span className="error_message">
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.tds_amtp}
                            </span>
                          </>
                        ) :
                          taxCalculatedMismatched
                            ?
                            <>
                              <br></br>
                              <span className="error_message">
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {taxCalculatedMismatched}
                              </span>
                            </>
                            :
                            null}
                        {' '}
                      </div>

                      <div className={'form-group col-md-4 grcolmb0'}>
                        <label
                          htmlFor="surchargePaid"
                          className="labpb4"
                        >
                          Surcharge
                          <span className="red_text">*</span>
                        </label>
                        <NumericInputWithDecimalTwo
                          type="text"
                          className={`form-control text-right ${errors?.surcharge_paid_amtp ? 'input-error1' : ''}`}
                          id="surcharge_paid_amtp"
                          maxLength={14}
                          value={values.surcharge_paid_amtp}
                          placeholder="Enter Surcharge Paid"
                          onChange={(e) => {
                            handleOnChange(
                              'surchargePaidAmtp',
                              e.target.value,
                            );
                            setFieldValue(
                              'surcharge_paid_amtp',
                              e.target.value,
                            );
                            excessWithdrawTdsCalculation(values, setFieldValue, 'surcharge_paid_amtp', e.target.value);

                          }}
                          onKeyUp={(e) => {
                            validateReasonSection(
                              values,
                              setFieldValue,
                              'errorMsgSurcharge',
                            );
                          }}
                        />

                        <span className="overflowtooltipconttdsamt">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Cess Amount cannot be greater than 0."
                            id="errorMsgSurcharge"
                            style={{ color: 'red', fontSize: '11px' }}
                          />
                        </span>

                        <div
                          style={{
                            position: 'absolute',
                            left: '19px',
                            top: '26px',
                            fontSize: '14px',
                          }}
                        >
                          ₹
                        </div>
                        {' '}
                        {errors.surcharge_paid_amtp ? (
                          <span className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.surcharge_paid_amtp}
                          </span>
                        ) : null}
                      </div>

                      <div className={'form-group col-md-4 grcolmb0'}>
                        <label htmlFor="educationCess" className="labpb4">
                          Health and Education Cess
                          {' '}
                          <span className="red_text">*</span>
                        </label>
                        <NumericInputWithDecimalTwo
                          type="text"
                          className={`form-control text-right ${errors?.education_cess_amtp ? 'input-error1' : ''}`}
                          id="education_cess_amtp"
                          maxLength={14}
                          value={values.education_cess_amtp}
                          placeholder="Enter Health and Education Cess"
                          onChange={(e) => {
                            handleOnChange(
                              'educationCessAmtp',
                              e.target.value,
                            );
                            setFieldValue(
                              'education_cess_amtp',
                              e.target.value,
                            );
                            excessWithdrawTdsCalculation(values, setFieldValue, 'education_cess_amtp', e.target.value);
                          }}
                          onKeyUp={(e) => {
                            validateReasonSection(
                              values,
                              setFieldValue,
                              'errorMsgCess',
                            );
                          }}
                        />
                        <span className="overflowtooltipconttdsamt">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="For Section (194D, 194DA, 194I(a), 194I(b), 192A, 194, 194A, 194EE, 194K) and Reason of Deduction (B), TDS Rate, TDS Amount, Surcharge Amount & Cess Amount cannot be greater than 0."
                            id="errorMsgCess"
                            style={{ color: 'red', fontSize: '11px' }}
                          />
                        </span>

                        <div
                          style={{
                            position: 'absolute',
                            left: '19px',
                            top: '26px',
                            fontSize: '14px',
                          }}
                        >
                          ₹
                        </div>
                        {' '}
                        {errors.education_cess_amtp ? (
                          <span className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.education_cess_amtp}
                          </span>
                        ) : null}
                      </div>

                      <div className={'form-group col-md-8 grcolmb0'}  >
                        <label htmlFor="deduction_reason" className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Reason for Lower/Non Collection' : ['24Q', '26Q', '27Q'].includes(headerDataDetails.form_type) ? 'Reason for Lower/Non Deduction' : 'Reason of Deduction'}
                        </label>
                        <div>
                          <Select
                            value={getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption)[getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption).findIndex((item) => String(item?.value) === String(values.deduction_reason))] ?? null}
                            id="deduction_reason"
                            name="deduction_reason"
                            onChange={(e) => {
                              if (e?.value === "showmore" || e?.value === "showless") {
                                setToggleAllOption((prev) => !prev)
                                setOpen(true)
                              } else {
                                //setAbc(e.value);
                                setOpen(false)

                                setFieldValue('deduction_reason', e?.value);
                                onChangeDeductionReason(e?.value, setFieldValue);
                                onChangeDeductionReasonForSpecific(e?.value, parseInt(values.nature_of_payment_id));
                                tdsRateCalculation(
                                  values,
                                  e?.value,
                                  'deduction_reason',
                                  setFieldValue,
                                );
                                validateReasonSection(
                                  values,
                                  setFieldValue,
                                  ''
                                )
                                setFieldValue('cash_withdrawal_option', '');
                              }
                            }
                            }
                            options={getReasonListV2(headerDataDetails, values.nature_of_payment_id, toggleAllOption)}
                            placeholder="Select"
                            className={`react-select-container ${errors?.deduction_reason ? 'input-error1' : ''} dt-select-dropdown`}
                            classNamePrefix="react-select"
                            onMenuOpen={() => setOpen(true)}
                            openOnFocus={true}
                            menuIsOpen={open}
                            onMenuClose={() => setOpen(false)}
                            openMenuOnFocus={true}
                          />
                        </div>
                        <span className="overflowtooltipreason">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={sectionErrorTitleMsg ? sectionErrorTitleMsg : errors.nature_of_payment_id}
                            id="errorMsgSection"
                            style={{ color: 'red', fontSize: '11px' }}
                          />
                        </span>
                        {errors.deduction_reason ? (
                          <span className="overflowtooltipreason">
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Deduction Reason is required"
                              style={{ color: 'red', fontSize: '11px' }}
                              className="error_message"
                            >
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.deduction_reason}
                            </span>
                          </span>

                        ) : null}

                      </div>

                      <div className={'form-group col-md-4 '} >
                        <label htmlFor="lower_deduction_certificate_no" className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Lower Collection Certificate No' : 'Lower Deduction Certificate No'}
                        </label>
                        <AlphaNumericInput
                          type="text"
                          disabled={(headerDataDetails && headerDataDetails.form_type === '27Q') ? !(certificateEnable
                            && [3, 66, 67, 68, 69, 70, 71, 72, 73].includes(parseInt(values.nature_of_payment_id))) : !certificateEnable}
                          value={values.lower_deduction_certificate_no}
                          id="lower_deduction_certificate_no"
                          name="lower_deduction_certificate_no"
                          className={`form-control ${errors?.lower_deduction_certificate_no ? 'input-error1' : ''}`}
                          placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? "Enter Lower Collection Certificate No" : "Enter Lower Deduction Certificate No"}
                          style={values.lower_deduction_certificate_no ? { textTransform: 'uppercase' } : { textTransform: '' }}
                          maxLength={10}
                          onChange={(e) => {
                            handleOnChange(
                              'lowerDeductionCertificateNo',
                              e.target.value,
                            );
                            setFieldValue(
                              'lower_deduction_certificate_no',
                              e.target.value,
                            );
                          }}
                        />
                        {' '}
                        <span id="err_msg" style={{ color: 'red', fontSize: '11px' }} />
                        {errors.lower_deduction_certificate_no
                          ? (
                            <span data-toggle="tooltip" data-placement="top" title="Certificate no is required for reason A/B" style={{ color: 'red', fontSize: '11px' }} className="error_message" >
                              <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                              {errors.lower_deduction_certificate_no}
                            </span>
                          )
                          :
                          null
                        }
                      </div>

                      {show27EQExtraFields ? (
                        <React.Fragment>
                          <div className={'form-group col-md-4'}>
                            <label htmlFor="tds_liable" className="labpb4">
                              Collectee Is Liable To TDS?
                              <span className="red_text">*</span>
                            </label>
                            <Select
                              value={CollecteeIsLiableToTDS[CollecteeIsLiableToTDS.findIndex((item) => String(item.value) === String(values.tds_liable))] ?? null}
                              onChange={(selected) => {
                                setFieldValue('tds_liable', selected.value);
                                liableHandleChange(selected.value, setFieldValue);
                              }}
                              options={CollecteeIsLiableToTDS}
                              placeholder="Select"
                              className={`react-select-container ${errors?.tds_liable ? 'input-error1' : ''}`}
                              classNamePrefix="react-select"
                              id="tds_liable"
                              name="tds_liable"
                              isDisabled={liableFieldDisable}
                              openMenuOnFocus={true}
                            />

                            {errors.tds_liable ? (
                              <span className="error_message">
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.tds_liable}
                              </span>
                            ) : null}
                            {' '}
                          </div>
                          <div className={'form-group col-md-4'} >
                            <label htmlFor="tds_challan_no " className="labpb4">
                              Challan No.
                              <span className="red_text">*</span>
                            </label>
                            <NumericInput
                              type="text"
                              className={`form-control ${errors?.tds_challan_no ? 'input-error1' : ''}`}
                              placeholder="Enter TDS Challan No"
                              id="tds_challan_no "
                              name="tds_challan_no "
                              value={values.tds_challan_no}
                              disabled={(Number(values.tds_liable) === 1) ? false : true}
                              maxLength={5}
                              // minLength={1}
                              onChange={(e) => {
                                setFieldValue('tds_challan_no', e.target.value)
                              }}
                            />
                            {errors.tds_challan_no ? (
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Please select tds challan Number is required"
                                style={{ color: 'red', fontSize: '11px' }}
                                className="error_message"

                              >
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.tds_challan_no}
                              </span>
                            ) : null}
                            {' '}
                          </div>

                          <div className={'form-group col-md-4'} id="tdsPaymentDate" >
                            <label htmlFor="tds_amtp" className="labpb4">
                              Payment Date of TDS
                              <span className="red_text">*</span>
                            </label>
                            <DatePicker
                              selectedDate={
                                values.tds_payment_date
                                  ? values.tds_payment_date
                                  : null
                              }
                              maxDate={headerDataDetails.quarter_end_date ? new Date(headerDataDetails.quarter_end_date) : new Date()}
                              placeHolder="Enter Payment Date of TDS"
                              onChange={(date) => {
                                setFieldValue('tds_payment_date', date);
                              }}
                              disabled={(Number(values.tds_liable) === 1) ? false : true}
                              name="tds_payment_date"
                              errors={errors.tds_payment_date ? errors.tds_payment_date : ""}
                            />
                            {errors.tds_payment_date ? (
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Please select tds payment date is required"
                                style={{ color: 'red', fontSize: '11px', display: 'inline-block' }}
                                className="error_message"
                              >
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.tds_payment_date}
                              </span>
                            ) : null}
                            {' '}
                          </div>
                        </React.Fragment>
                      ) : null}

                      {headerDataDetails.form_type === '27Q' &&
                        <div className={'form-group col-md-8  grcolmb0'}                                  >
                          <label htmlFor="educationCesss" className="labpb4"> Applicability of TDS Rate</label>
                          <Select
                            value={ApplicabilityTDSRate[ApplicabilityTDSRate.findIndex((item) => String(item.value) === String(values.applicable_tds_rate))] ?? null}
                            onChange={(selected) => {
                              setFieldValue('applicable_tds_rate', selected.value);
                              setExcessTdsRate(values, setFieldValue);
                            }}
                            options={ApplicabilityTDSRate}
                            placeholder="Select"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            id="applicable_tds_rate"
                            name="applicable_tds_rate"
                            openMenuOnFocus={true}
                          />
                        </div>
                      }

                      {!show27EQExtraFields && headerDataDetails.form_type !== '24Q' ? (
                        <React.Fragment>
                          <div className={'form-group col-md-4 displaynonh grcolmb0'}>
                            <label htmlFor="educationCesss" className="labpb4" >
                              Cash Withdrawal in excess of
                            </label>
                            <Select
                              value={getCashWithdrawal(values.nature_of_payment_id)[getCashWithdrawal(values.nature_of_payment_id).findIndex((item) => String(item?.value) === String(values.cash_withdrawal_option))] ?? null}
                              onChange={(selected) => {
                                setFieldValue('cash_withdrawal_option', selected?.value);
                                setExcessTdsRate(values, setFieldValue, selected?.value ?? '');

                              }}
                              options={getCashWithdrawal(values.nature_of_payment_id)}
                              placeholder="Select"
                              className={`react-select-container ${errors?.cash_withdrawal_option ? 'input-error1' : ''}`}
                              classNamePrefix="react-select"
                              id="cash_withdrawal_option"
                              name="cash_withdrawal_option"
                              isDisabled={submitEnableFor194NF}
                              openMenuOnFocus={true}
                            />
                            {errors.cash_withdrawal_option ? (
                              <span className="error_message">
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.cash_withdrawal_option}
                              </span>
                            ) : null}

                          </div>

                          <div className={'form-group col-md-4 displaynonh grcolmb0'}>
                            <label
                              htmlFor="educationCesss"
                              className="labpb4"
                            >
                              {parseInt(values.nature_of_payment_id) === 36 ? 'Withdrawal Amount' : 'Excess Withdrawal Amount'}
                              {' '}
                            </label>
                            <div
                              style={{
                                position: 'absolute',
                                left: '19px',
                                top: '26px',
                                fontSize: '14px',
                              }}
                            >
                              ₹
                            </div>

                            <NumericInputWithDecimalTwo
                              type="text"
                              className={`form-control text-right ${errors?.cash_withdrawal_amtp ? 'input-error1' : ''}`}
                              id="cash_withdrawal_amtp"
                              name="cash_withdrawal_amtp"
                              value={values?.cash_withdrawal_amtp}
                              disabled={(parseInt(values.nature_of_payment_id) === 36 || parseInt(values.nature_of_payment_id) === 81) ? submitDisable : submitEnableFor194NF}
                              placeholder="Amount of cash"
                              onChange={(e) => {
                                setFieldValue('cash_withdrawal_amtp', e.target.value);
                                excessWithdrawTdsCalculation(values, setFieldValue, 'cash_withdrawal_amtp', e.target.value);
                              }}
                            />
                            {errors.cash_withdrawal_amtp ? (
                              <span className="error_message">
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.cash_withdrawal_amtp}
                              </span>
                            ) : null}
                            <span id="errorExcess" className='error_message' />



                          </div></React.Fragment>
                      ) : null}

                      {headerDataDetails.form_type === '27Q' ? (
                        <>
                          <div className={'form-group col-md-4  grcolmb0'}>
                            <label htmlFor="educationCesss" className="labpb4">
                              Nature of Remittance
                              <span className="red_text">*</span>
                            </label>
                            <Select
                              value={updatedNatureOfRemittanceArray[updatedNatureOfRemittanceArray.findIndex((item) => String(item.value) === String(values.nature_of_remittance))] ?? null}
                              onChange={(selected) => {
                                setFieldValue('nature_of_remittance', selected.value)
                                tdsRateCalculation(
                                  values,
                                  selected.value,
                                  'nature_of_remittance',
                                  setFieldValue,
                                  panComplianceStatus
                                );
                              }}
                              options={updatedNatureOfRemittanceArray}
                              placeholder="Select option"
                              className={`react-select-container ${errors?.nature_of_remittance ? 'input-error1' : ''} dt-select-dropdown`}
                              classNamePrefix="react-select"
                              openMenuOnFocus={true}
                            />
                            <span className="overflowtooltipconttdsamt">
                              {errors.nature_of_remittance && <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title={errors.nature_of_remittance}
                                style={{ color: 'red', fontSize: '11px', whiteSpace: 'wrap' }}
                              >
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.nature_of_remittance}
                              </span>}

                            </span>
                          </div>

                          <div className={'form-group col-md-4  grcolmb0 '}                                  >
                            <label htmlFor="educationCesss" className="labpb4">  15CA Acknowledgment No   </label>
                            <Field
                              type="text"
                              className={`form-control ${errors?.acknowledgment_no_15ca ? 'input-error1' : ''}`}
                              id="acknowledgment_no_15ca"
                              placeholder=" 15CA Acknowledgment No"
                              name="acknowledgment_no_15ca"
                              maxLength={15}
                              onChange={(e) => {
                                e.target.value.match(/^[a-zA-Z0-9]*$/) && setFieldValue('acknowledgment_no_15ca', e.target.value)
                              }}

                            />
                            <span className="overflowtooltipconttdsamt">
                              {errors.acknowledgment_no_15ca && <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Enter 15CA Acknowledgment Number "
                                id="tool"
                                style={{ color: 'red', fontSize: '11px' }}
                                className="error_message"
                              >
                                <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                                {errors.acknowledgment_no_15ca}
                              </span>}
                            </span>

                          </div>

                          <div className={'form-group col-md-4'}>
                            <label htmlFor="educationCesss" className="labpb4">  Grossing Up Indicator  </label>
                            <Select
                              value={GrossingUpIndicator[GrossingUpIndicator.findIndex((item) => String(item.value) === String(values.grossing_up_indicator))] ?? null}
                              onChange={(selected) => {
                                setFieldValue('grossing_up_indicator', selected.value);
                                setExcessTdsRate(values, setFieldValue);
                              }}
                              options={GrossingUpIndicator}
                              placeholder="Select"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              id="applicable_tds_rate"
                              name="applicable_tds_rate"
                              openMenuOnFocus={true}
                            />
                          </div>
                        </>
                      ) : null}



                      <div className={'form-group col-md-8 displaynonh grcolmb0'}>
                        <label htmlFor="inputEmail4">
                          Mapped Challan
                        </label>
                        <span style={{ float: 'right' }}>Total Rem. Bal: {' '} ₹
                          <span style={{ color: '#31719b', fontWeight: '700' }}>
                            {' '} {formatNumber(challanUnutilizedValue)}
                          </span>
                        </span>
                        <div>
                          <MultiColumnComboBox
                            placeholder="Map challan now (optional) or enter challan number to search"
                            className={`mapped_challan_combobox ${errors?.challan_id ? 'input-error1' : ''}`}
                            iconClassName={`k-icon k-i-arrow-s`}
                            data={filter ? filterBy(challanOption, filter) : challanOption}
                            columns={columns}
                            textField={"text"}
                            value={challanVals ? challanVals : ''}
                            itemRender={itemRender1}
                            clearButton={challanVals === '' ? false : true}
                            filterable={true}
                            onFilterChange={handleFilterChange}
                            style={{
                              width: "100%",
                              height: 'calc(1.2em + 0.75rem + 2px)',
                              fontWeight: 500,
                              fontSize: 12
                            }}
                            onChange={(item) => {
                              if (item?.value?.challan_id) {
                                handleChalanValueChange(
                                  item.value.challan_id,
                                );
                                setChallanIdValue(item.value.challan_id);
                                setFieldValue(
                                  'challan_id',
                                  item.value.challan_id,
                                );
                                const selectedChallan = challanOption.filter(v => v.challan_id === item.value.challan_id);
                                setChallanVals(selectedChallan[0]);
                              } else {
                                setFieldValue('challan_id', '');
                                setChallanVals('');
                              }
                            }}
                            popupSettings={{ width: '600px' }}
                          />
                        </div>
                        {errors.challan_id ? (
                          <span className="error_message">
                            <img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />
                            {errors.challan_id}
                          </span>
                        ) : null}

                      </div>
                      <div className={'form-group col-md-4 grcolmb0'}>
                        <label htmlFor="tds_amtp" className="labpb4">
                          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Total Tax Collected' : 'Total Tax Deducted'}
                        </label>
                        <NumericInputWithDecimalTwo
                          type="text"
                          className="form-control text-right"
                          id="total_tds_amtp"
                          value={Number(totalTdsAmount)}
                          onChange={() => null}
                          placeholder={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Total Tax Collected' : 'Total Tax Deducted'}
                          readonly
                        />
                        <div
                          style={{
                            position: 'absolute',
                            left: '19px',
                            top: '26px',
                            fontSize: '14px',
                          }}
                        >
                          ₹
                        </div>
                      </div>


                      {/* <div className="row col-sm-12">&nbsp;</div> */}

                      <div className="row col-sm-12 mt-4 text-center">
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <button
                            className="dark_color_bg btnbgpadd1 mr-2 mb-1 btn_blue"
                            type="submit"
                            onClick={() => {
                              submitForm(values, values.payment_date);
                            }}
                            id="save-deductee-trn"
                          >
                            Save
                          </button>
                          {!deducteeTransactionID ? (
                            <button
                              disabled={submitDisable}
                              className="light_color_bg btnbgpadd2 mr-2 mb-1 btn_corol"
                              id="save-deductee-trn-new"
                              onClick={() => submitAndResetForm()}
                            >
                              Save and New
                            </button>
                          ) : null}
                        </div>
                      </div>

                      <div className="row col-sm-12 text-left" style={{ padding: '0 15px' }}>
                        <div style={{ width: '100%', textAlign: 'left', paddingLeft: '15px' }}>
                          {!deducteeTransactionID ? (
                            <small className="hviewhide">
                              Use Alt + S to Save
                              {' '}
                              <br />
                              Use Alt + Shift + S for Save & New
                              <br />
                              Esc to Cancel
                            </small>
                          ) : (
                            <small className="hviewhide">
                              Use Alt + S to Save
                              {' '}
                              <br />
                              Esc to Cancel
                            </small>
                          )}
                        </div>
                      </div>

                    </div >
                  </div>
                </div>
              </WindowWrapperFixed>
            </Form>
          </>
          );
        }}
      </Formik >
    </>
  );
}
import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatNumber } from '../../../utils/UtilityFunctions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { checkConso } from '../../../api/returnAPI';
import RaisedFileImportPopup from './RaisedFileImportPopup';
import CorrectionConfirmationPopup from './CorrectionConfirmationPopup';
import ShowAlert from '../../../components/ShowAlert';
import SkeletonStickyNotes from '../../../components/Skeleton/SkeletonStickyNotes';

const StickyNotes = (props) => {

    const { returnsData, statsData, deductorId, financialYear, defaultData, successProcedSumm, indiviualQuarterSync, skeletonList } = props;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [importFilePopup, setImportFilePopup] = useState(false);
    const [returnPayload, setReturnPayload] = useState({});
    const [consoFileAlready, setConsoFileAlready] = useState(false);


    let settings = {
        infinite: false,
        speed: 200,
        slidesToShow: statsData?.length > 2 ? 3 : statsData?.length === 2 ? 2 : 1,
        slidesToScroll: 1,
        dots: false,
        customPaging: 10
    };

    const clickNotesWorkOnThis = (dataItem) => {
        if (dataItem.return_type === "REGULAR" && dataItem.return_id) {
            indiviualQuarterSync(dataItem)
        } else if (dataItem.return_type === "CORRECTION" && dataItem.return_id) {
            let efiling_status = "";
            let ack_no = "";
            let filing_date = "";
            const index = returnsData.findIndex(item => item.return_id === dataItem.return_id);
            if (index !== -1) {
                efiling_status = returnsData[index].efiling_status;
                ack_no = returnsData[index].ack_no;
                filing_date = returnsData[index].filing_date;
            }

            const Payload = {
                deductor_id: deductorId,
                form_type: dataItem.form_type ?? "",
                quarter: dataItem.quarter ?? "",
                return_type: "CORRECTION",
                year: financialYear,
                return_id: dataItem?.return_id ?? "",
                efiling_status: efiling_status,
                ack_no: ack_no,
                filing_date: filing_date
            }
            setReturnPayload(Payload);
            clickCorrectionCheckConsoOnServer(Payload)
        } else {
            setError("Return Not Created");
        }
    }

    const clickCorrectionCheckConsoOnServer = async (returnItem) => {
        setLoading(true);
        let returnID = returnItem.return_id;

        try {
            const request = `?deductor_id=${deductorId}&return_id=${returnID}`;
            const results = await checkConso(request);
            openConsoFileFoundInSystem(results, returnID);

        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(''), 1500);
        } finally {
            setLoading(false);
        }
    };

    const openConsoFileFoundInSystem = (results = {}, returnID = null) => {
        if (['CONSO_IMPORTED', 'REQUEST_SUBMITTED', 'IMPORT_IN_PROGRESS'].includes(results?.data?.conso_file_status) && results?.data?.file_found) {
            setConsoFileAlready(true);

        } else {
            setImportFilePopup(true);
        }

    };


    return (
        <>
            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />

            {importFilePopup &&
                <RaisedFileImportPopup
                    show={importFilePopup}
                    handleClose={() => setImportFilePopup(false)}
                    deductorId={deductorId}
                    returnPayload={returnPayload}
                    returnId={returnPayload.return_id}
                    financialYear={financialYear}
                />}

            {consoFileAlready &&
                <CorrectionConfirmationPopup
                    show={consoFileAlready}
                    handleClose={() => setConsoFileAlready(false)}
                    createNewReturn={() => {
                        setImportFilePopup(true);
                    }}
                    returnId={returnPayload.return_id}
                    allReturnData={defaultData}
                    returnPayload={returnPayload}
                    successProced={() => {
                        successProcedSumm()

                    }}
                    deductorId={deductorId}
                    financialYear={financialYear}
                />
            }

            {!loading && loading && <LoadingIndicator />}

            <div className='col-md-12'>
                <div className="strickysliderbox">
                    <fieldset className="fieldset returnin_inprogress_fs notes_mrgintop">
                        <legend className="returnin_inprogress_leg">
                            <h4>Returns in Progress</h4>
                        </legend>

                        <div className="row d-flex">
                            <div className={`col-12 ${statsData?.length === 2 ? "slidestwo" : "slicesfontsize"}`}>
                                {
                                    skeletonList ? <SkeletonStickyNotes />
                                        :
                                        statsData?.length >= 2 ? <Slider {...settings}>
                                            {statsData?.length > 0 && statsData.map((item) => {
                                                const return_type = item?.return_type?.toLowerCase() ?? "";
                                                return (
                                                    <div className="flexrow_slider_innerbox align-items-center justify-content-center px-1 planexpiredalert"
                                                        onClick={() => {
                                                            clickNotesWorkOnThis(item)
                                                        }}
                                                    >
                                                        <div className={`card strickcard_borderclr ${return_type === "regular" ? "regularsticky" : "correctionsticky"}`}>
                                                            <div className="deductdash_striknote">
                                                                <div className={`deductdash_striknote_paper  px-1`}>
                                                                    <div className="deductdash_striknote_pin">
                                                                        <div className="deductdash_striknote_shadow"></div>
                                                                        <div className="deductdash_striknote_metal"></div>
                                                                        <div className="deductdash_striknote_bottom-circle"></div>
                                                                    </div>
                                                                    <p className="clickheretextfs  mb-3 pt-3 text-center" style={{ textTransform: "capitalize" }}>
                                                                        {`${return_type} | Form ${item.form_type ?? ""} | ${item.quarter}`}
                                                                    </p>
                                                                    <p className="clickheretextfs textcolor_blue mb-1">
                                                                        No. of Transaction:	<span className="float-right">{item?.cnt_transaction ?? ""}</span>
                                                                    </p>
                                                                    <p className="clickheretextfs textcolor_blue mb-3">
                                                                        Unmapped Amt:	<span className="float-right">{formatNumber(item?.total_challan_unmapped_amtp ?? 0)}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                            : statsData?.length === 1 ?
                                                <div className="stickynotestwodesign">

                                                    <div className=" flexrow_slider_innerbox align-items-center justify-content-center px-1 planexpiredalert"
                                                        onClick={() => {
                                                            clickNotesWorkOnThis(statsData?.[0] ?? {})
                                                        }}
                                                    >
                                                        <div className={`card strickcard_borderclr ${statsData?.[0]?.return_type === "REGULAR" ? "regularsticky" : "correctionsticky"}`}>
                                                            <div className="deductdash_striknote">
                                                                <div className="deductdash_striknote_paper px-1">
                                                                    <div className="deductdash_striknote_pin">
                                                                        <div className="deductdash_striknote_shadow"></div>
                                                                        <div className="deductdash_striknote_metal"></div>
                                                                        <div className="deductdash_striknote_bottom-circle"></div>
                                                                    </div>
                                                                    <p className="clickheretextfs  mb-3 pt-3 text-center" style={{ textTransform: "capitalize", fontSize: '16px', fontWeight: '700' }}>
                                                                        {`${statsData?.[0]?.return_type === "REGULAR" ? "Regular" : "Correction"} | Form ${statsData?.[0]?.form_type ?? ""} | ${statsData?.[0]?.quarter}`}
                                                                    </p>
                                                                    <p className="clickheretextfs textcolor_blue mb-1" style={{ fontSize: '16px' }}>
                                                                        No. of Transaction:	<span className="float-right" style={{ fontWeight: '700' }}>{statsData?.[0]?.cnt_transaction ?? ""}</span>
                                                                    </p>
                                                                    <p className="clickheretextfs textcolor_blue mb-3" style={{ fontSize: '16px' }}>
                                                                        Unmapped Amt:	<span className="float-right" style={{ fontWeight: '700' }}>{formatNumber(statsData?.[0]?.total_challan_unmapped_amtp ?? 0)}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="notesnotfound" >
                                                    No Record Found
                                                </div>
                                }
                            </div>

                        </div>
                    </fieldset>
                </div>
            </div>
        </>
    )
}

export default StickyNotes
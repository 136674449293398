import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';
import NODataImg from '../../../images/docu_icons.png';
import AddButton from '../../../images/icons/add_btn_round.svg';

class LastFiveQtrsData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: props.data,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false, // Set to false for vertical bars                        
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: props.quarterLabel,
                },
                yaxis: {
                    title: {
                        text: 'Total Liability'
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val; // + "K"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetX: 0
                }
            },


        };
    }
    render() {
        return (
            <>
                <div id="chart" className="returndashboard_liabilitychartbox" style={{ top: 0 }}>
                    {this.props.data && this.props.data.length > 0 ?
                        <ReactApexChart
                            height={"280px"}
                            width={"550px"}
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            className="returndashboard_liabilitychartbox_width"
                        />
                        :
                        <div className="container mt-2">
                            <div className="d-flex justify-content-center">
                                <div className="browserbutton_box mt-4">
                                    <div className="clickaablebtn_box">
                                        <span className="clickablebtn" style={{ display: 'ruby-text' }}>
                                            <img src={NODataImg} alt="No data" width="100" onClick={() => this.props.setStartFilingPopup(true)} />
                                        </span>
                                        <p className="No_Records_to_Display_textfs mb-2 pt-3" style={{ display: 'flex', position: 'relative', paddingLeft: '6.6rem' }}>
                                            No Records to Display
                                        </p>
                                        <p className="No_Records_to_Display_textfs" onClick={() => this.props.setStartFilingPopup(true)}>
                                            To add records, use the <img src={AddButton} alt="" width={'16px'} style={{ cursor: 'pointer' }} /> button and Create/Import Return
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </>

        );
    }
}

export default LastFiveQtrsData;
import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { VALIIDATION_SCHEMA } from '../salaryHelper';
import { getHouseRentAnnualExemptAmt,  } from '../../../components/salaryDetailsForm/allowancesCalculationScema';

const HouseRentAllowance = (props) => {
    const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, onlyView = false } = props;
    const index = info.index ?? 0;
    const propsdata = info.poppupData ?? {};

    const annualSalaryAmtp = mainData?.salary_breakup_amtp?.details?.length > 0 ?
        mainData?.salary_breakup_amtp?.details.reduce((curr, prev) => {
            if (["BASIC", "DA"].includes(prev.particular_code)) {
                curr += prev.amount_amtp ?? 0;
            }
            return curr;
        }, 0) : 0;

    const defaultObjYear = {
        whether_metro_city: 0,
        hra_received_amtp: 0,
        rent_paid_amtp: 0,
        annual_salary_amtp: annualSalaryAmtp,
        exempt_amtp: 0
    }

    const width1 = document.body.clientWidth;
    const popupWidth = 700;
    const additionalGap = 10;
    const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));

    const [formError, setFormError] = useState({});
    const [houseRentAllwnce, setHouseRentAllwnce] = useState({
        details: {},
        type: "YEAR"
    });

    const calculateExemptYearAmt = (values) => {
        let exempt_amtp = getHouseRentAnnualExemptAmt(values);  
        values.details.exempt_amtp = parseFloat(exempt_amtp);
    }

    const handleYearTabChange = (parent, child, value) => {
        if (!houseRentAllwnce?.[parent]) houseRentAllwnce[parent] = {};
        houseRentAllwnce[parent][child] = value;
        calculateExemptYearAmt(houseRentAllwnce);
        setHouseRentAllwnce({ ...houseRentAllwnce });
    }

    
    
    const saveData = () => {
        let valid = {};
        if (houseRentAllwnce.type === "YEAR") {
            valid = VALIIDATION_SCHEMA.houseRentYearlyDataValidationSchema(houseRentAllwnce.details);
        }
        if (!valid.validation) {
            setFormError(valid.errObj);
            return;
        }
        if (!mainData?.allowances?.[index]) mainData.allowances[index] = {};
        mainData.allowances[index].allowance_details = houseRentAllwnce;
        if (houseRentAllwnce.type === "YEAR") {
            mainData.allowances[index].received_amtp = houseRentAllwnce?.details?.hra_received_amtp ?? "";
            mainData.allowances[index].exempt_amtp = houseRentAllwnce?.details?.exempt_amtp ?? "";
        }
        updateEmployeeDetail(mainData, onClose);
    };

    useEffect(() => {
        const data = info.poppupData ?? {};
        setHouseRentAllwnce((prev) => {
            prev.type = data.type ?? "YEAR";

            defaultObjYear.hra_received_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
            defaultObjYear.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";

            if (!data.type) {
                prev.details = defaultObjYear;
            } else {
                if(data?.details){
                    if(data.type === "YEAR"){
                        data.details.hra_received_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
                        data.details.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";
                        data.details.annual_salary_amtp = annualSalaryAmtp;
                    }
                } 
                prev.details = data.details ?? defaultObjYear;
            }
            return { ...prev };
        });
        //eslint-disable-next-line
    }, [info])


    return (
        <>
            <WindowWrapperFixed
                title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <span className="taxregim_textfs pr-3">
                                House Rent Allowance u/s 10(13A)
                            </span>
                        </div>
                    </div>
                </kendo-dialog-titlebar>}
                onClose={onClose}
                initialHeight={'auto'}
                initialWidth={popupWidth}
                initialTop={50}
                totalPopups={1}
                isModalView={true}
                initialLeft={initialLeft}
                className={'salarary_detail_popup'}
            >
                <div className="pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <nav className="salary_taxreg_allowance_nav">
                                <div className="nav nav-tabs salary_taxreg_allowance_tabs" id="nav-tab" role="tablist">
                                    <span className={`nav-item nav-link  salary_taxreg_allowance_tabmonthyr ${houseRentAllwnce.type === "YEAR" ? "active" : ""} `}
                                        onClick={() => {
                                            if (houseRentAllwnce.type !== "YEAR") {
                                                
                                                houseRentAllwnce.type = "YEAR";
                                                defaultObjYear.hra_received_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
                                                defaultObjYear.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";

                                                if(propsdata.type === "YEAR"){
                                                    if (propsdata.details) {
                                                        propsdata.details.hra_received_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
                                                        propsdata.details.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";
                                                        propsdata.details.annual_salary_amtp = annualSalaryAmtp;
                                                    }
                                                    houseRentAllwnce.details = propsdata?.details ?? defaultObjYear;
                                                    
                                                }else{
                                                    houseRentAllwnce.details = defaultObjYear;
                                                }
                                                setHouseRentAllwnce({ ...houseRentAllwnce });
                                            }
                                        }}
                                    >
                                        Yearly
                                    </span>
                                </div>
                            </nav>
                        </div>

                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                            <div className="card salary_taxreg_allowance">
                                <div className="tab-content" id="nav-tabContent">

                                    <div className={`tab-pane fade  ${houseRentAllwnce.type === "YEAR" ? "active show" : ""}`} role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form form-row salary_taxreg_allowance_form">
                                                    <div className="form-group col-md-3">
                                                        <label className="salary_taxreg_allowance_labelfs" for="">Whether Metro City</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled={onlyView}
                                                            value={houseRentAllwnce?.details?.whether_metro_city}
                                                            onChange={(e) => { 
                                                                const value = convertNum(e.target.value);
                                                                handleYearTabChange("details", "whether_metro_city", value);
                                                            }}
                                                        >
                                                            <option value={1}>Yes</option>
                                                            <option value={0}>No</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6"></div>
                                                    <div className="form-group col-md-3 ">
                                                        <label className="salary_taxreg_allowance_labelfs" for="">H.R.A Received</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                                            placeholder="" data-toggle="tooltip"
                                                            data-placement="right" data-original-title={formError?.hra_received_amtp ? formError?.hra_received_amtp : ""}
                                                            value={FormatNumberDecimal(houseRentAllwnce?.details?.hra_received_amtp, ...Array(3))}
                                                            onChange={(e) => {
                                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                                handleYearTabChange("details", "hra_received_amtp", value);
                                                            }}
                                                            disabled={onlyView}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-3 ">
                                                        <label className="salary_taxreg_allowance_labelfs" for="">Rent Paid</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                                            placeholder="" data-toggle="tooltip"
                                                            data-placement="right" data-original-title={formError?.rent_paid_amtp ? formError?.rent_paid_amtp : ""}
                                                            value={FormatNumberDecimal(houseRentAllwnce?.details?.rent_paid_amtp, ...Array(3))}
                                                            onChange={(e) => {
                                                                const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                                                                handleYearTabChange("details", "rent_paid_amtp", value);
                                                            }}
                                                            disabled={onlyView}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-3 ">
                                                        <label className="salary_taxreg_allowance_labelfs" for="">Annual Salary</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox"
                                                            placeholder="" data-toggle="tooltip"
                                                            data-placement="right" data-original-title={formError?.annual_salary_amtp ? formError?.annual_salary_amtp : ""}
                                                            value={FormatNumberDecimal(houseRentAllwnce?.details?.annual_salary_amtp, ...Array(3))}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-3 ">
                                                        <label className="salary_taxreg_allowance_labelfs" for="">Exempt Amt</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="text" className="form-control salary_taxreg_allowance_inputbox" disabled
                                                            placeholder="" data-toggle="tooltip"
                                                            data-placement="right" data-original-title={formError?.exempt_amtp ? formError?.exempt_amtp : ""}
                                                            value={FormatNumberDecimal(houseRentAllwnce?.details?.exempt_amtp, ...Array(3))}
                                                        />
                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="col-md-12 pt-2 text-center"> 
                            <button className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                                onClick={() => saveData()}
                            >Save & Exit</button>
                        </div>
                    </div>
                </div>
            </WindowWrapperFixed>
        </>
    )
}

export default HouseRentAllowance
import React, { useState } from 'react'
import BlurOnChangeInput from '../../../components/BlurOnChangeInput'
import { FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import AllowancesDetails from './AllowancesDetails';
import AllowancesLite from './AllowancesLite';
import RetirementBenfitDetails from './RetirementBenfitDetails';
import RetirementBenfitLite from './RetirementBenfitLite';
import ConfirmPopup from '../../../components/ConfirmPopup';

const Salary17one = (props) => {

    const { data, headingCodeLIst17one, handleParentChildArrayInputChange, updateEmployeeDetail, fixedkey17one, handleArrayNumberInputOnBlur,
        deleteIcon, handleDelete17one, drowdownList, handleDrpdwn17one, headingCodeListAllowance, handleParentChildArrayNumberInputOnBlur,
        calcIcon, showCalcPopupsfunc, handleArrayTypeInputChange, handleArrayObjDel, allowanceObj, handleArrayObjADD,
        headingCodeListRetirbenfit, retirmentBenfitObj, USER_TYPE, taxRegime
    } = props;
    // Placeholder item
    const [confirmPopup, setconfirmPopup] = useState(false);

    return (
        <>
          <ConfirmPopup
                confirmText={"By entering the value in this feild, the breakup values entered inside the calculator will be deleted. Do you wish to proceed?"}
                confirmButtonText="Yes"
                confirmButtonText2="No"
                showCancel={false}
                show={(confirmPopup || confirmPopup === 0)}
                handleProceed={() => {
                    handleArrayTypeInputChange("allowances", confirmPopup, "allowance_details", null);
                    setconfirmPopup(false);
                }}
                handleProceed2={() => {
                    setconfirmPopup(false);
                }}
                className="btnclrcssyesno"
            />
            
            <tr className="">
                <th width="10" id="accordion" className="accordion">
                    <span className="salarytaxregime_edit_collapse collapsed" data-toggle="collapse" href="#salarytaxregime_edit_collap01" aria-expanded="false"></span>
                </th>
                <th width="550" className="text-left">
                    Salary 17(1)
                </th>
                <th width="120" className="text-right">
                    <BlurOnChangeInput
                        type="text"
                        className={`form-control salarytaxregime_edit_inputbox noneditinputbox_bgclr text-right disabled`}
                        value={FormatNumberDecimal(data.salary_amtp)}
                        data-toggle="tooltip" data-placement="top" title={""}
                        placeholder=""
                    />
                </th>

            </tr>

            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_collap01" className="in collapse">
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>
                                    {data?.salary_breakup_amtp?.details?.length > 0 &&
                                        data?.salary_breakup_amtp?.details.map((item, index) => {
                                            return (
                                                <tr className="" key={index}>
                                                    <td width="50"></td>
                                                    <td width="550" className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft  ${index === 0 && "salarytaxreg_innertb_bordertop"}`}>
                                                        {headingCodeLIst17one?.get(item?.particular_code)?.description ?? ""}
                                                    </td>
                                                    <td width="140"
                                                        className={`salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright ${index === 0 && "salarytaxreg_innertb_bordertop"}`}>
                                                        <span className="d-flex">
                                                            <BlurOnChangeInput
                                                                type="text"
                                                                className={`form-control salarytaxregime_edit_inputbox text-right`}
                                                                value={FormatNumberDecimal(item.amount_amtp, ...Array(2), false)}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={""}
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    const value = FormatNumberDecValue1(e.target.value, 10, ...Array(3), e)
                                                                    handleParentChildArrayInputChange("salary_breakup_amtp", "details", index, "amount_amtp", value);
                                                                }}
                                                                onBlur={(e) => {
                                                                    handleParentChildArrayNumberInputOnBlur("salary_breakup_amtp", "details", index, "amount_amtp", item.amount_amtp)
                                                                }}
                                                            />
                                                            {USER_TYPE === "Details" && <span className="deleicon_pos">
                                                                {!fixedkey17one?.[item.particular_code] &&
                                                                    <img src={deleteIcon} alt="addicon"
                                                                        width="16"
                                                                        onClick={() => {
                                                                            handleDelete17one("salary_breakup_amtp", "details", index);
                                                                        }}
                                                                        style={{ cursor: "pointer", verticalAlign: "baseline" }}
                                                                    />
                                                                }
                                                            </span>}
                                                        </span>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {drowdownList?.salary17one?.length > 0 && USER_TYPE === "Details" &&
                                        <tr className="salarytaxregime_edit_alternetbgclr_white">
                                            <td width=""></td>
                                            <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderleft salarytaxreg_innertb_borderbottom align-bottom">
                                                <span className="" style={{ display: "flex" }}>
                                                    <DropDownList
                                                        data={drowdownList?.salary17one ?? []}
                                                        className="header_select headerselect w-100 drpdwnlist_changedesign"
                                                        popupSettings={{ className: 'headerselectoption' }}
                                                        textField="description"
                                                        value={{ description: "Add additional detail", particular_code: "" }}
                                                        onChange={(e) => {
                                                            handleDrpdwn17one(e.target.value);
                                                        }}

                                                        dataItemKey="particular_code"
                                                        id="yearDropDownPopup"
                                                    />
                                                </span>
                                            </td>

                                            <td width="" className="salarytaxreg_innertb_bgclr_lightyell salarytaxreg_innertb_borderright salarytaxreg_innertb_borderbottom">
                                                <BlurOnChangeInput
                                                    type="text"
                                                    className={`form-control salarytaxregime_edit_inputbox text-right disabled`}
                                                    data-toggle="tooltip" data-placement="top" title={""}
                                                    placeholder=""
                                                    disabled
                                                />
                                            </td>


                                        </tr>}
                                    
                                    {USER_TYPE === "Details" &&
                                        <AllowancesDetails
                                            data={data} headingCodeListAllowance={headingCodeListAllowance} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                            calcIcon={calcIcon} deleteIcon={deleteIcon} USER_TYPE={USER_TYPE} allowanceObj={allowanceObj} taxRegime={taxRegime}
                                            showCalcPopupsfunc={showCalcPopupsfunc} handleArrayTypeInputChange={handleArrayTypeInputChange} drowdownList={drowdownList}
                                            handleArrayObjDel={handleArrayObjDel} handleArrayObjADD={handleArrayObjADD} updateEmployeeDetail={updateEmployeeDetail}
                                            setconfirmPopup={setconfirmPopup}
                                        />}

                                    {USER_TYPE === "Lite" && taxRegime === "OLD" &&
                                        <AllowancesLite
                                            data={data} headingCodeListAllowance={headingCodeListAllowance} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                            calcIcon={calcIcon} deleteIcon={deleteIcon} USER_TYPE={USER_TYPE} allowanceObj={allowanceObj}
                                            showCalcPopupsfunc={showCalcPopupsfunc} handleArrayTypeInputChange={handleArrayTypeInputChange} drowdownList={drowdownList}
                                            handleArrayObjDel={handleArrayObjDel} handleArrayObjADD={handleArrayObjADD} updateEmployeeDetail={updateEmployeeDetail}
                                            setconfirmPopup={setconfirmPopup}
                                        />
                                    }

                                    {USER_TYPE === "Details" &&
                                        <RetirementBenfitDetails
                                            data={data} headingCodeListRetirbenfit={headingCodeListRetirbenfit} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                            calcIcon={calcIcon} deleteIcon={deleteIcon} USER_TYPE={USER_TYPE} retirmentBenfitObj={retirmentBenfitObj}
                                            showCalcPopupsfunc={showCalcPopupsfunc} handleArrayTypeInputChange={handleArrayTypeInputChange} drowdownList={drowdownList}
                                            handleArrayObjDel={handleArrayObjDel} handleArrayObjADD={handleArrayObjADD} updateEmployeeDetail={updateEmployeeDetail}
                                            setconfirmPopup={setconfirmPopup}
                                        />}

                                    {USER_TYPE === "Lite" &&
                                        <RetirementBenfitLite 
                                            data={data} headingCodeListRetirbenfit={headingCodeListRetirbenfit} handleArrayNumberInputOnBlur={handleArrayNumberInputOnBlur}
                                            calcIcon={calcIcon} deleteIcon={deleteIcon} USER_TYPE={USER_TYPE} retirmentBenfitObj={retirmentBenfitObj}
                                            showCalcPopupsfunc={showCalcPopupsfunc} handleArrayTypeInputChange={handleArrayTypeInputChange} drowdownList={drowdownList}
                                            handleArrayObjDel={handleArrayObjDel} handleArrayObjADD={handleArrayObjADD} updateEmployeeDetail={updateEmployeeDetail}
                                            setconfirmPopup={setconfirmPopup}
                                        />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default Salary17one
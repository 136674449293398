/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import { useSelector } from 'react-redux';
import NumericInputWithDecimalTwo from '../NumericInputWithDecimalTwo';
import NumericInput from '../NumericInput';
import { travelConcessionValidationSchema } from './validationSchema';
import { selectHeaderData } from '../../features/header/headerSlice';
import { getTravelExemptAmt } from './allowancesCalculationScema';


const TravelConcessionForm = (props) => {

    const headerData = useSelector(selectHeaderData);
    const { fieldValue, setFieldValue, allowance_index, handleClose, getTotalAllowanceAmtp } = props;
    const [travelConcessionData, setTravelConcessionData] = useState(false);


    useEffect(() => {
        if (fieldValue.allowances[allowance_index].allowance_details) {
            setTravelConcessionData(fieldValue.allowances[allowance_index].allowance_details);
        }
        else {
            setTravelConcessionData({
                whether_cent_gov_emp: headerData.deductor_category && (headerData.deductor_category === 'A' || headerData.deductor_category === 'D' || headerData.deductor_category === 'G' || headerData.deductor_category === 'L') ? 1 : 0,
                whether_cash_allowance: 0,
                transport_mode: "Air Route",
                air_route_amtp: 0,
                family_person: 0,
                rail_connect_route_amtp: 0,
                actual_exp_paid_amtp: 0,
                other_route_amtp: 0,
                exempt_amtp: 0,
            });
        }
    }, [fieldValue]);

    const saveData = (values) => {
        setTravelConcessionData(values);
        let allowances = fieldValue.allowances;
        allowances[allowance_index].allowance_details = values;
        allowances[allowance_index].exempt_amtp = values.exempt_amtp;
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(fieldValue, allowances, setFieldValue);
        handleClose(-1);
    }

    const calculateExemptAmt = (field, value, values, setFieldValue) => {
        let old_value = values[field];
        values[field] = value;
        let exempt_amtp = getTravelExemptAmt(values);
        setFieldValue('exempt_amtp', exempt_amtp);
        values[field] = old_value;
    }


    if (travelConcessionData) {
        return (<Formik
            initialValues={travelConcessionData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={travelConcessionValidationSchema}
            onSubmit={(values) => {
                const submitValues = { ...values };
                saveData(submitValues);
            }}
        >
            {({
                errors, values, setFieldValue,
            }) => {
                return (
                    <Form key={1} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12 pt-2">
                                <div className="card allowance">
                                    <div className="table-responsive">
                                        <table className="table table-striped allowanceWSDTB">
                                            <thead>
                                                <tr>
                                                    <td style={{ width: '177px' }}>Whether Cent Govt Employ</td>
                                                    <td>
                                                        <select
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_cent_gov_emp}
                                                            name="whether_cent_gov_emp"
                                                            onChange={(e) => {
                                                                setFieldValue('whether_cent_gov_emp', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_cent_gov_emp', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                    <td style={{ width: '164px' }}>Whether Cash Allowance</td>
                                                    <td>
                                                        <select
                                                            name="whether_cash_allowance"
                                                            className="custom-select slecttextfs"
                                                            value={values.whether_cash_allowance}
                                                            onChange={(e) => {
                                                                setFieldValue('whether_cash_allowance', parseInt(e.target.value));
                                                                calculateExemptAmt('whether_cash_allowance', e.target.value, values, setFieldValue);
                                                            }}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Mode of Transport</td>
                                                    <td>Fare</td>
                                                </tr>
                                                <tr>
                                                    <td>Air Route</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.air_route_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="air_route_amtp"
                                                            value={values.air_route_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('rail_connect_route_amtp', 0);
                                                                setFieldValue('other_route_amtp', 0);
                                                                setFieldValue('air_route_amtp', e.target.value);
                                                                setFieldValue('transport_mode', 'Air Route');
                                                                calculateExemptAmt('air_route_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>No. of Family Person</td>
                                                    <td>
                                                        <NumericInput
                                                            type="text"
                                                            className={errors.family_person ? "form-control error_input" : "form-control"}
                                                            placeholder="0"
                                                            name="family_person"
                                                            value={values.family_person}
                                                            onChange={(e) => {
                                                                setFieldValue('family_person', e.target.value);
                                                                calculateExemptAmt('family_person', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rail Connect Route</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.rail_connect_route_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="rail_connect_route_amtp"
                                                            value={values.rail_connect_route_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('air_route_amtp', 0);
                                                                setFieldValue('other_route_amtp', 0);
                                                                setFieldValue('rail_connect_route_amtp', e.target.value);
                                                                setFieldValue('transport_mode', 'Rail Connect Route');
                                                                calculateExemptAmt('rail_connect_route_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Actual Exp. Paid</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.actual_exp_paid_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="actual_exp_paid_amtp"
                                                            value={values.actual_exp_paid_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('actual_exp_paid_amtp', e.target.value)
                                                                calculateExemptAmt('actual_exp_paid_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Other Route</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className={errors.other_route_amtp ? "form-control error_input" : "form-control"}
                                                            placeholder="0.00"
                                                            name="other_route_amtp"
                                                            value={values.other_route_amtp}
                                                            onChange={(e) => {
                                                                setFieldValue('air_route_amtp', 0);
                                                                setFieldValue('rail_connect_route_amtp', 0);
                                                                setFieldValue('other_route_amtp', e.target.value);
                                                                setFieldValue('transport_mode', 'Other Route');
                                                                calculateExemptAmt('other_route_amtp', e.target.value, values, setFieldValue);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>Exempt Amt</td>
                                                    <td>
                                                        <NumericInputWithDecimalTwo
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0.00"
                                                            name="exempt_amtp"
                                                            value={values.exempt_amtp}
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="btn-group mt-2 mb-1">
                                    <button type="submit" id="allowance_submit_btn" className="btn btn-default monthwsdetilssavebtn">Save & Exit</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        );
    }
    else {
        return false;
    }
};

export default TravelConcessionForm;

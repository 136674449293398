import React, { useEffect, useRef, useState } from 'react'
import { ADD_CLIENT_AUTO_SCAN, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { getProcessIdStatus, runSearchtdstxtConnector, searchtdstxt } from '../../api/localConnectorAPI';
import { selectHeaderData } from '../header/headerSlice';
import { useSelector } from 'react-redux';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import { clearAuthToken, getAuthToken } from '../../utils/AuthToken';
import AutoScanImportStats from './AutoScanImportStats';

const AutoScan = (props) => {

    const { setShowAutoScanButton = null, runAutoScan = null, setJsonData, setRunAutoScan } = props;
    
    const headerData = useSelector(selectHeaderData);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [isRunScan, setIsRunScan] = useState(false);

    const timer = useRef(null);

    const autoScanProgressId = localStorage.getItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`) ? localStorage.getItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`) : null;

    const [processId, setProcessId] = useState(autoScanProgressId);
    const [showStats, setShowStats] = useState(false);
    const [showStatsData, setShowStatsData] = useState({})

    const runAutoScanPrgress = async (port, processId) => {
        const autoScanProgressId = localStorage.getItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`) ? localStorage.getItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`) : null;
        if (!port || !autoScanProgressId) {
            return false;
        }

        // Validate auth token - dont fail if invalid
        if (!getAuthToken(false)) {
            clearAuthToken();
            return false
        }

        try {
            const result = await getProcessIdStatus(port, processId);
            const connectorResult = result?.data?.data;      
            setShowStatsData(connectorResult?.data);

            if (connectorResult) {
                if (connectorResult?.process_status !== "COMPLETE" && connectorResult?.process_status !== "FAILURE") {
                        setShowAutoScanButton(false)
                        setRunAutoScan(true);                
                    timer.current = setTimeout(() => runAutoScanPrgress(port, processId), 5000);
                }else {
                    localStorage.removeItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`);
                    setRunAutoScan(false);
                    setIsRunScan(false)
                    setProcessId(null)
                    if (connectorResult?.process_status === "COMPLETE") {
                        setJsonData(connectorResult?.data?.list ?? []);
                        setShowStats(true);

                    }
                }
            }
        } catch (err) {
            localStorage.removeItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`);
        }

    };



    const getProgressId = async (portNumber) => {
        async function fetchEverything() {
            try {
                const serverApiResult = await searchtdstxt();
                const EncryptLoginData = serverApiResult?.data
                const EncryptLoginDataHeader = serverApiResult?.headers ?? null;
                const connectorRequest = {
                    "data": EncryptLoginData?.data
                };
                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    const actionStatusResult = await runSearchtdstxtConnector(portNumber, connectorRequest, extraHeader);

                    if (Number(actionStatusResult.status) === 1) {

                        const connectorResult = actionStatusResult.data;
                        localStorage.setItem(`${ADD_CLIENT_AUTO_SCAN}${headerData?.mobile_number}`, connectorResult?.process_id);

                        setProcessId(connectorResult?.process_id);
                        setShowAutoScanButton(false)
                    }
                }
                catch (err) {
                    // setError(err.message);
                    setPort(0);

                }
            } catch (err) {
                // setError(err);
            }

        }
        fetchEverything();
    }

    useEffect(() => {

        if (autoScanProgressId || runAutoScan) {
            setShowConnnectorCall(true);
        } else {
            setShowAutoScanButton(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoScanProgressId, runAutoScan])

    useEffect(() => {
        if (port && isRunScan && !processId) {
            getProgressId(port);
            return;
        }
        else if (!(port && isRunScan && processId)) { return; }
        runAutoScanPrgress(port, processId);
        //eslint-disable-next-line
    }, [port, isRunScan && processId])

    useEffect(() => {
        return () => clearTimeout(timer.current);
    }, []);

    return (
        <>
            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={(port) => {
                        setShowConnnectorCall(false);
                        if (!port && runAutoScan) setRunAutoScan(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => {
                        setIsRunScan(true);
                    }}

                />
                : null}

                {showStats && 
                    <AutoScanImportStats 
                        onClose={() => setShowStats(false)}
                        data={showStatsData}
                    />
                }
        </>
    )
}
export default AutoScan
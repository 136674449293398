import { getAuthToken } from "./AuthToken";

// Plan Feature Codes
export const PLNFEAT_SAL_IMP_V1 = "sal_imp_v1";
export const PLNFEAT_SAL_IMP_V2 = "sal_imp_v2";
export const PLNFEAT_SAL_V2 = "sal_v2";
export const PLNFEAT_SAL_EXP_V2 = "sal_exp_v2";

// Plan Parameter Codes
export const PLNPARM_SAL_IMP_V1_SZMB = "sal_imp_v1_szmb";
export const PLNPARM_SAL_IMP_V2_SZMB = "sal_imp_v2_szmb";

const DEFAULT_PLNPARM_VALS = {
  PLNPARM_SAL_IMP_V1_SZMB : 5,
  PLNPARM_SAL_IMP_V2_SZMB : 5,
}

export function getUserData() {
  try {
    const authToken = getAuthToken(true);
    const decodedString = atob(authToken.split(".")[1]); // Decoding the token - to decode the base64-encoded string
    const decodedData = JSON.parse(decodedString); // Check if the decoded string is an object or an array
    const decodedSubData = JSON.parse(decodedData?.sub);
    if (decodedSubData && decodedSubData.length > 0) {
      return decodedSubData;
    }

    return [];
  } catch (error) {
    return [];
  }
}

export function isDIY() {
  return (getUserData()?.[8] ?? '') === 'CORPORATE';
};

export function isDIFM() {
  return (getUserData()?.[8] ?? '') === 'CA';
};

export function isPlanFeatureEnabled(featureName) {
  return (getUserData()?.[16] ?? []).includes(featureName);
};

export function getPlanParameterValue(parameterName) {
  return (getUserData()?.[17]?.[parameterName] ?? DEFAULT_PLNPARM_VALS?.[parameterName] ?? 0);
};

import React, { useState, useEffect } from 'react';
//import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ShowAlert from '../../components/ShowAlert';
import { MAX_IMPORT_FILE_SIZE_MB } from '../../app/constants';
import "./BulkImport.css";
import { bulkImportDeductor, downloadFile } from '../../api/deductorAPI';
import WindowWrapperFixed from '../../components/grid/WindowWrapperFixed';

const BulkImport = ({ history, ...props }) => {
  const {
    handleClose, ifBulkImport, browseType, setImportData, setImportStatus, setShowProgressBar
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const {
    isDragActive, acceptedFiles, getRootProps, getInputProps, isDragReject,
  } = useDropzone({
    accept: browseType === 'excel' ? '.xls,.xlsx,.xlsm,' : '.txt,.tds'
  });
  const onlyOneFileAllowedExtensions = /(\.xlsx|\.xlsm|\.xls)$/i;

  const removeFile = (targetFile) => {
    const newFiles = [];
    files.forEach((file) => {
      if (file.path === targetFile.path) return;
      newFiles.push(file);
    });
    setFiles(newFiles);
  };
  const renderFiles = files.map((file) => {
    return (<li key={file.path} style={{ margin: '5px' }}>
      <span>
        {file.path}
        {' '}
        -
        {file.size}
        {' '}
        bytes
      </span>
      <Button
        variant="danger"
        style={{
          fontSize: '12px', padding: '0px 5px 2px 5px', marginLeft: '10px',
        }}
        onClick={() => removeFile(file)}
      >
        Remove
      </Button>
    </li>)
  });

  const uploadFiles = async () => {
    setLoading(true);
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      formData.append('files', file);
    });
    try {
      if (setShowProgressBar) setShowProgressBar(true);

      const result = await bulkImportDeductor(formData);
      if (result && result.status === 1) {
        if (browseType === "excel") {
          setImportData(result?.data?.excel ?? {});
        } else if (browseType === 'txt') {
          setImportData(result?.data?.tds_txt ?? {});
        }
        handleClose()
        setImportStatus(true);
        setFiles([]);

      } else {
        setError(result.message);
      }
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      if (setShowProgressBar) setShowProgressBar(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const newFiles = acceptedFiles;
    const uniqueFiles = [];
    const flags = [];
    let xlsmxfile = [];
    let fileSize = 0;
    const l = newFiles.length;
    let i;
    for (i = 0; i < l; i++) {
      if (flags[newFiles[i].path]) continue;
      flags[newFiles[i].path] = true;
      uniqueFiles.push(newFiles[i]);
      xlsmxfile = [...files, ...uniqueFiles];
    }
    let checkfile = [];
    xlsmxfile.forEach((file) => {
      fileSize += file.size;
      if (onlyOneFileAllowedExtensions.exec(file.name)) {
        checkfile = [...checkfile, file.name];
      }
    });
    if (checkfile.length > 1) {
      return setError('Only one Excel file (.xls, xlsx, .xlsm) can be uploaded at a time.');
    } else if (fileSize / (1024 * 1024) > MAX_IMPORT_FILE_SIZE_MB) {
      fileSize = 0;
      return setError(`Maximum size of the files that can be uploaded is ${MAX_IMPORT_FILE_SIZE_MB} MB.`);
    }

    if (files.length) {
      setFiles([...files, ...uniqueFiles]);
    } else {
      setFiles([...uniqueFiles]);
    }

    //eslint-disable-next-line
  }, [acceptedFiles]);




  const downloadExcelTemplates = async () => {
    const url = `/api/v1/deductor/download-template`
    try {
      await downloadFile(url, "BulkImport-ExcelFile.xlsx");
    } catch (err) {
      setError(err.toString());
    } finally {

    }
  };

  return (
    <>

      <WindowWrapperFixed
        title={<kendo-dialog-titlebar>
          <h4 className='my-3' style={{ fontSize: "16px" }}>
            {ifBulkImport ? browseType === 'excel' ? 'Import Status of Bulk Import via Excel' : "Import Deductor" : "Reimport"}
          </h4>
        </kendo-dialog-titlebar>}
        onClose={handleClose}
        totalPopups={0}
        initialTop={50}
        index={1}
        className={`bookVerify ${ifBulkImport ? "bulk_importcss" : ""}`}
        initialHeight={'auto'}
        style={{ maxHeight: 'auto', overflowX: 'hidden' }}
        initialWidth={700}
        initialLeft={(((window.innerWidth) - 700) / 2)}
        isModalView={true}
      >
        <ShowAlert
          success=""
          error={error}
          onClose={() => {
            setError(null);
          }}
          headerStyle={{
            position: 'absolute', top: 0, left: 0, width: '100%', fontSize: '13.5px',
          }}
        />
        {ifBulkImport && <div className="modal-body pt-1 pb-0">
          <div className="row">
            <div className="col-md-12">


              <div className="">
                <section className="">
                  <div {...getRootProps({ className: 'dropzone dropzonestyle' })}>
                    <input id="book_file" {...getInputProps({ accept: browseType === 'excel' ? '.xls,.xlsx,.xlsm' : '.txt,.tds', multiple: browseType === 'excel' ? false : true })} />
                    {browseType === 'excel' ?
                      <p className="font11Center">
                        {!isDragActive && 'Drag \'n\' drop Files/Folders here, or click to select File'}
                        {isDragActive && !isDragReject && 'Drop file here'}
                        {isDragReject && 'File type not accepted, sorry!'}
                      </p>

                      : <p className="font11Center">
                        {!isDragActive && 'Drag \'n\' drop Files/Folders here, or click to select Files/Folders'}
                        {isDragActive && !isDragReject && 'Drop files here'}
                        {isDragReject && 'File type not accepted, sorry!'}
                      </p>}


                  </div>
                </section>
              </div>
              <aside className='pt-2'>
                <div style={{ maxHeight: '152px', overflow: 'auto', }}>
                  <h6 style={{ fontSize: '12px' }}>{browseType === 'excel' ? 'File' : 'Files'}</h6>
                  <ul style={{ fontSize: '12px' }}>{renderFiles}</ul>
                </div>

              </aside>
              {browseType === 'excel' &&
                <div className="text-right">
                  <span className="font12Left">Don't have the Excel Template?</span>
                  <br />
                  <span className="clickheredowntext font12CursorPoint" onClick={() => downloadExcelTemplates()}> Click here to download. </span>
                </div>}
              <div className="text-center pb-1">
                <Button className="btn btn-default gstrfFbtnimport" variant="primary" onClick={() => uploadFiles()} disabled={loading || files.length === 0}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {' '}
                      Loading...
                    </>
                  ) : 'Import'}
                </Button>
              </div>
            </div>
          </div>
        </div>}
        {!ifBulkImport &&
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div role="button" tabindex="0" className=" selecttype-reimportdropzone">
                  <div {...getRootProps({ className: 'dropzone dropzonestyle' })}>
                    <input id="book_file" {...getInputProps({ accept: browseType === 'excel' ? '.xls,.xlsx,.xlsm' : '.txt,.tds', multiple: browseType === 'excel' ? false : true })} />
                    <p className="reimport_textfs12 text-center">Drag and Drop Files Here or click to select the file</p>
                  </div>
                </div>
                <p className="reimport_selecttypefileselectedtext">File Selected:
                </p>

              </div>
              <div className="col-md-12 text-center">
                <button className="btn btn-default reimport_conpgain-importbtnbgclr mt-2"> Import</button>
              </div>

            </div>

          </div>}
      </WindowWrapperFixed>
    </>
  );
};

export default BulkImport;

import React, { useEffect, useState } from 'react'
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { VALIIDATION_SCHEMA } from '../salaryHelper';
import { getLeaveSalaryEncashmentExemptAmt } from '../../../components/salaryDetailsForm/allowancesCalculationScema';

const LeaveSalaryEncashment = (props) => {
  const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, onlyView=false, headerData, USER_TYPE } = props;
  const index = info?.index ?? 0;
  const isCent_gov_emp = headerData.deductor_category && (headerData.deductor_category === 'A' || headerData.deductor_category === 'D' || headerData.deductor_category === 'G' || headerData.deductor_category === 'L') ? 1 : 0;
  
  const defaultObj = {
    whether_gov_emp: isCent_gov_emp,
    leaves_availed: 0,
    leaves_encas_rec_amtp: 0,
    service_period_year: 0,
    service_period_month: 0,
    unavailed_leaves: 0,
    avg_10_month_salary_amtp: 0,
    exempt_amtp: 0,
  }

  const width1 = document.body.clientWidth;
  const popupWidth = 700;
  const additionalGap = 10;
  const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));

  const [leaveSalEncashment, setLeaveSalEncashment] = useState({});
  const [formError, setFormError] = useState({});

  const calculateExemptAmt = (values) => {

    let exempt_amtp = getLeaveSalaryEncashmentExemptAmt(values);
    values.exempt_amtp = Number(exempt_amtp);

}

  const handleChange = (name, value) => {
    leaveSalEncashment[name] = value;
    calculateExemptAmt(leaveSalEncashment)
    setLeaveSalEncashment({ ...leaveSalEncashment });
  }

  const saveData = () => {
    const valid = VALIIDATION_SCHEMA.leaveSalaryEncashmentValidationSchema(leaveSalEncashment);
    if (!valid.validation) {
      setFormError(valid.errObj);
      return;
    }
    if (!mainData?.allowances?.[index]) mainData.allowances[index] = {};
    //  DIFM USER - paid to employee(key - gratuity_received_amtp) input not show
    if(USER_TYPE === "Details") mainData.allowances[index].received_amtp = leaveSalEncashment?.leaves_encas_rec_amtp ?? "";
    mainData.allowances[index].exempt_amtp = leaveSalEncashment?.exempt_amtp ?? "";
    mainData.allowances[index].allowance_details = leaveSalEncashment;
    
    updateEmployeeDetail(mainData, onClose);
  };

  useEffect(() => {
    const leaveEncashApiData = info.poppupData ?? defaultObj;
    leaveEncashApiData.whether_gov_emp = isCent_gov_emp;

    if(mainData?.allowances?.[index]){
      if(USER_TYPE === "Details") leaveEncashApiData.leaves_encas_rec_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
      leaveEncashApiData.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";
    };

    setLeaveSalEncashment({ ...leaveEncashApiData });
    //eslint-disable-next-line
  }, [info])

  return (
    <>
      <WindowWrapperFixed
        title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
          <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
            <div className="row">
              <span className="taxregim_textfs pr-3">
                Leave Salary Encashment u/s 10(10AA)
              </span>
            </div>
          </div>
        </kendo-dialog-titlebar>}
        onClose={onClose}
        initialHeight={'auto'}
        initialWidth={popupWidth}
        initialTop={50}
        totalPopups={1}
        isModalView={true}
        initialLeft={initialLeft}
        className={'salarary_detail_popup'}
      >
        <div className="pb-2 pt-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card salary_taxreg_allowance">
                <div className="form form-row salary_taxreg_allowance_form">
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Whether Cent Govt Employ</label>
                  </div>
                  <div className="form-group col-md-2">
                    <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled
                      value={leaveSalEncashment?.whether_gov_emp ? 1 : 0}
                      onChange={(e) => {
                        handleChange("whether_gov_emp", convertNum(e.target.value));
                      }}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Leave Availed</label>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.leaves_availed ? "input-error" : ""}`}
                    placeholder="" data-toggle="tooltip"
                    data-placement="right" data-original-title={formError?.leaves_availed ? formError?.leaves_availed : ""}
                       value={FormatNumberDecimal(leaveSalEncashment.leaves_availed, ...Array(2), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(3), false, e);
                        handleChange("leaves_availed", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Leave Encashment Rec</label>
                  </div>


                  <div className="form-group col-md-2 mb-0">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.leaves_encas_rec_amtp ? "input-error" : ""}`}
                     placeholder="" data-toggle="tooltip"
                     data-placement="right" data-original-title={formError?.leaves_encas_rec_amtp ? formError?.leaves_encas_rec_amtp : ""}
                       value={FormatNumberDecimal(leaveSalEncashment.leaves_encas_rec_amtp, ...Array(3))}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        handleChange("leaves_encas_rec_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="form-group col-md-3 mb-0">
                    <label className="salary_taxreg_allowance_labelfs" for="">Service Period</label>
                  </div>
                  <div className="form-group col-md-4 mb-0">
                    <div className="form form-row salary_taxreg_allowance_form">

                      <div className="form-group col-md-6 text-center">
                        <label className="salary_taxreg_allowance_labelfs text-center" for="">Year</label>
                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.service_period_year ? "input-error" : ""}`}
                         placeholder="" data-toggle="tooltip"
                         data-placement="right" data-original-title={formError?.service_period_year ? formError?.service_period_year : ""}
                           value={FormatNumberDecValue1(leaveSalEncashment.service_period_year ?? "", ...Array(3), false)}
                          onChange={(e) => {
                            const value = FormatNumberDecValue1(e.target.value, ...Array(3), false, e);
                            handleChange("service_period_year", value);
                          }}
                          disabled={onlyView}
                        />
                      </div>
                      <div className="form-group col-md-6 text-center">
                        <label className="salary_taxreg_allowance_labelfs text-center" for="">Month</label>
                        <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.service_period_month ? "input-error" : ""}`}
                         placeholder="" data-toggle="tooltip"
                         data-placement="right" data-original-title={formError?.service_period_month ? formError?.service_period_month : ""}
                           value={FormatNumberDecValue1(leaveSalEncashment.service_period_month ?? "", 2, ...Array(2), false)}
                          onChange={(e) => {
                            if (e.target.value === '' || parseFloat(e.target.value) <= 11) {
                            const value = FormatNumberDecValue1(e.target.value, 2, ...Array(2), false, e);
                            handleChange("service_period_month", value);
                            }
                          }}
                          disabled={onlyView}
                        />
                      </div>
                    </div>
                  </div>



                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Un-availed Leaves</label>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.unavailed_leaves ? "input-error" : ""}`}
                      placeholder="" data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.unavailed_leaves ? formError?.unavailed_leaves : ""}
                       value={FormatNumberDecValue1(leaveSalEncashment.unavailed_leaves ?? "", ...Array(3), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(3), false, e);
                        handleChange("unavailed_leaves", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Avg. of 10 Months salary</label>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.avg_10_month_salary_amtp ? "input-error" : ""}`}
                    placeholder="" data-toggle="tooltip"
                    data-placement="right" data-original-title={formError?.avg_10_month_salary_amtp ? formError?.avg_10_month_salary_amtp : ""}
                       value={FormatNumberDecimal(leaveSalEncashment.avg_10_month_salary_amtp, ...Array(3))}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        handleChange("avg_10_month_salary_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Exempt Amt</label>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.exempt_amtp ? "input-error" : ""}`} disabled
                    placeholder="" data-toggle="tooltip"
                    data-placement="right" data-original-title={formError?.exempt_amtp ? formError?.exempt_amtp : ""}
                      value={FormatNumberDecimal(leaveSalEncashment.exempt_amtp, ...Array(3))}

                    />
                  </div>


                </div>
              </div>
            </div>

            <div className="col-md-12 pt-2 text-center">
              <button className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                onClick={() => saveData()}
              >Save & Exit</button>
            </div>
          </div>
        </div>
      </WindowWrapperFixed>
    </>
  )
}

export default LeaveSalaryEncashment
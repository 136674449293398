import React, { useEffect, useState } from 'react'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import ConnectorAdapter from './ConnectorAdapter';
import { verfiyTracesloginCrediationalOnServer } from '../../api/ReturnRegister';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { updateFilingTracesHistoryOnPortal } from '../../api/localConnectorAPI';
import TracesConnector from './TracesConnector';
import ShowAlert from '../ShowAlert';

const TracesGovtPortal = (props) => {
    const { syncDeductor, setCheckTracesPortal } = props;

    const [error, setError] = useState(false);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchRef, setCaptchaRef] = useState("")


    const verfiyCredApi = async (port, syncdata, setCheckTracesPortal) => {
        if (!port) {
            return false;
        }
        setLoading(true);



        const verigyEfilingCreditional = `?deductor_id=${syncdata}`
        try {

            const loginVerified = await verfiyTracesloginCrediationalOnServer(verigyEfilingCreditional);

            if (loginVerified.data.status === 1) {
                let extraHeader = {};
                const LoginVerifiedResult = loginVerified.data
                const responseHeader = loginVerified.headers ?? null;

                const connectorRequest = {
                    "data": LoginVerifiedResult.data
                };

                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                try {
                    await updateFilingTracesHistoryOnPortal(port, connectorRequest, extraHeader);
                    setCheckTracesPortal(false);
                } catch (err) {
                    const errmsg = CONNECTOR_ERRORS_MAPPING[err.message] ?? CONNECTOR_ERRORS_MAPPING['insights.unknown_error'];
                    setError(errmsg);
                }

            }

        } catch (err) {
            setCheckTracesCredentials(true);
            // if (err.message) setError(err.message);
            // else setError(err);

        } finally {
            setCheckItCredentials(false);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi(port, syncDeductor, setCheckTracesPortal);
        }
    }, [port, checkItCredentials, syncDeductor, setCheckTracesPortal]);

    useEffect(() => {
        if (syncDeductor) {
            setShowConnnectorCall(true);
        }
    }, [syncDeductor])

    useEffect(() => {
        if (captchRef) {
            verfiyCredApi(port, syncDeductor, setCheckTracesPortal)
        }
        // eslint-disable-next-line
    }, [captchRef])


    return (
        <>
            {error &&
                <ShowAlert
                    error={error}
                    onClose={() => setError(null)}
                />
            }
            {loading ? <LoadingIndicator /> : null}

            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => {
                        setShowConnnectorCall(false);
                        setCheckTracesPortal(false);
                    }}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={syncDeductor}
                    returnId={null}
                    tracesPopupClose={() => {

                    }}
                    handleProceed={() => {
                        setShowConnnectorCall(false);
                        setCheckTracesCredentials(false);
                        setCheckTracesPortal(false);
                    }}
                    tan={syncDeductor?.itdportal_username ?? ""}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                />
                : null
            }

        </>
    )
}

export default TracesGovtPortal
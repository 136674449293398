import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import Form3CDTimeLine from './Form3CDTimeLine';
import queryString from 'query-string';
import downloadicon from "../../images/downloadicon_coral.png";
import { GetDataClause34c, exportform3cdReport } from '../../api/form3cdApi.js'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Spinner from 'react-bootstrap/Spinner';
import { BASE_PATH, HEADER_FILE_NAME } from '../../app/constants';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import moment from 'moment';
import { formatNumber } from '../../utils/UtilityFunctions.js'
import { Link } from 'react-router-dom';
import TransDataSourcePopup from './TransDataSourcePopup';
import sys_btn from "../../images/icons/sys_btn_icon.svg";
import './PanWiseTanDetails.scss';

const ReportClause34c = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [dataState, setDataState] = useState({});
    const [filingPopup, setFilingPopup] = useState(false);

    const getDataReportClause34c = async (data) => {
        setLoading(true);
        try {
            const result = await GetDataClause34c(data);

            if (result?.data && result?.data.length > 0) {

                // Use reduce to transform the data into the desired format
                let transformedData = result?.data.reduce((acc, obj) => {
                    // Check if TAN key already exists in accumulator, if not, initialize it
                    if (!acc[obj.tan]) {
                        acc[obj.tan] = [];
                    }
                    // Push the object into the array corresponding to the TAN key
                    acc[obj.tan].push(obj);
                    return acc;
                }, {});

                // Convert the transformedData object back to an array of objects
                let newResult = Object.entries(transformedData).map(([key, value]) => ({
                    [key]: value
                }));

                // setReportData(result?.data ?? []);
                setReportData(newResult ?? []);
            } else {
                setReportData([]);
            }

            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const rowRender = (trElement, props) => {
        // const dataItem = props.dataItem ?? {}; // Ensure dataItem is defined
        const { dataItem } = props;

        // Extract the TAN and items array
        const tan = Object.keys(dataItem)[0]; // Assuming there's only one key
        const items = dataItem[tan];

        return (
            <tr>
                {/* Render TAN only in the first row of each group */}
                <td className="text-center report34a_tancol_bglcr_white report34a_vertical form3cd_borderbottom_grey" style={{ backgroundColor: 'white', borderRight: '1px solid #0000001c' }}>
                    {tan}
                </td>
                <td colSpan={3} style={{ padding: '0px', background: 'none' }}>
                    <table className='table table-striped form3cd34c form3cd34ctbinner_bordertop ' style={{ border: '0px', backgroundColor: 'unset' }}>
                        {/* Iterate over items for each TAN */}
                        {items.map((item, index) => (
                            <tr>
                                <td className="text-right report34a_vertical report34c_rowbgclr_lgrey  ">
                                    {formatNumber(parseFloat(item.interest_payable_amtp).toFixed(2)) ?? ""}
                                </td>
                                
                                {(item?.interest_payment_details && item?.interest_payment_details?.length > 0) ? (
                                    <td colSpan={2} >
                                        <table className='report-grid34c-table' style={{ border: '0px', backgroundColor: 'unset' }} >
                                            {item.interest_payment_details.length > 0 && item.interest_payment_details.map((detail, idx) => (
                                                <tr key={idx}>
                                                    <td className="text-right">
                                                        {formatNumber(parseFloat(detail?.int_paid_amtp).toFixed(2)) ?? ""}
                                                    </td>
                                                    <td className="text-center">
                                                        {detail?.challan_date ? moment(detail?.challan_date, "YYYY-MM-DD").format('DD/MM/YYYY') : ""}
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </td>
                                )
                                    :
                                    <>
                                        <td width="218"> </td>
                                        <td width="218"> </td>

                                    </>
                                }
                            </tr>
                        ))}
                    </table>
                </td>
            </tr>
        );
    };

    const getParamsHistoryUrl = () => {
        const requestData = {
            pan: params?.pan ?? ""
        };

        if (params?.fy) {
            requestData.financial_year = params.fy;
        }

        if (params.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.sort_by ?? 'asc' }];
            requestData.sort_on = params.sort_on;
            requestData.sort_by = params.sort_by;
        }

        dataState.filter = { filters: [] };
        if (params.filters) {
            let frs = JSON.parse(atob(params.filters));
            dataState.filter.filters = frs;
            requestData.filters = JSON.parse(atob(params.filters));
        }

        setDataState(dataState);
        // setFilterPostData(requestData);
        return requestData;
    };


    useEffect(() => {

        const queryData = getParamsHistoryUrl();
        getDataReportClause34c(queryData);
        // eslint-disable-next-line
    }, [location.search]);


    const downloadDeducteeExcel = async () => {
        setDownloadLoading(true)
        const urlPayload = `?pan=${params?.pan}&financial_year=${params?.fy}`
        try {
            const result = await exportform3cdReport(urlPayload);
            const xlsxData = new Blob([result?.data]);
            const file_name = result.headers[HEADER_FILE_NAME] ?? 'ExpressTDS-Clause34Report.xlsx';
            const link = document.createElement('a');
            link.href = URL.createObjectURL(xlsxData);
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error('Error in export: ', err);
        } finally {
            setDownloadLoading(false)
        }
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };

    const createHistoryUrl = (dataState = []) => {
        let filterStr = `?pan=${params.pan}&fy=${params.fy}`;

        if(params.deductor_name){
            filterStr += `&deductor_name=${params.deductor_name ?? ""}`
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }

        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&sort_by=${dataState.sort[0].dir}`;
        }

        history.push(filterStr);
    };


    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                onClose={() => { setError(null); }}
            />

            {filingPopup &&
                <TransDataSourcePopup
                    show={filingPopup}
                    handleClose={() => {
                        setFilingPopup(false);
                    }}
                    params={params}
                />
            }

            
            <Form3CDTimeLine currentPage="report34c" getPan={params.pan} getFinYear={params.fy ?? ""} deductorName={params.deductor_name ?? ""} />
            
            <div className="container mt-1">
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="dropdown tds_form3CDRevamp_yr_dropdownbox">
                            <Link to={`${BASE_PATH}form3CD/incomplete-deductors?pan=${params.pan}&fy=${params.fy}`}>
                                <button className="btn btn-default form3cd_sync_btn mb-2">
                                    <span className="form3cd_returnreg_imgbox">
                                        <img src={sys_btn} alt='sys_btn' style={{ paddingRight: "3px", marginTop: "-2px" }} />
                                        <span className="form3cd_returnreg_textfs pl-1">Sync Now</span>
                                    </span>
                                </button>
                            </Link>
                            <span className="tds_form3CDRevamp_downloadicon pr-1 pl-2">
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={downloadicon} width="25" alt="Export Report" title='Download Clause 34 of Form 3CD Report' onClick={() => downloadDeducteeExcel()} />
                                }
                            </span>
                        </div>
                    </div>

                    <div className="col-md-12 text-center pt-2">
                        <div className="card tds_form3CDRevamp_report34a_cardbox mb-2">
                            <div className='row'>
                                <div className='col-12 col-xl-3 col-lg-4 col-md-3 col-sm-12 text-center'><span onClick={() => setFilingPopup(true)} className='tds_form3CDRevamp_underst_textfs'>Click here to understand the source of data</span></div>
                                <div className='col-12 col-xl-9 col-lg-8 col-md-9 col-sm-12'> <h4 className="report34a_clasuse34_title">
                                    Clause 34(c) - Whether the assessee is liable to pay interest under section 201(1A) or section 206C(7)
                                </h4>
                                </div>
                            </div>

                        </div>
                        <div className="card">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <Grid
                                    className="table table-striped report34c_tb mb-0"
                                    data={reportData}
                                    rowRender={rowRender}
                                    {...dataState}
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ]
                                    }}
                                    sortable={{
                                        allowUnsort: true,
                                    }}
                                >
                                    <Column
                                        width="150"
                                        field="tan"
                                        title='TAN'
                                        filter="text"
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        headerClassName={isColumnActive('tan') ? 'active cursor-pointer' : 'cursor-pointer'}
                                    />
                                    <Column
                                        width="140"
                                        field="interest_payable_amtp"
                                        title="Amount of Interest Payable"
                                        filter="numeric"
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                        headerClassName={isColumnActive('interest_payable_amtp') ? 'active non-sort inst-pybl cursor-pointer' : 'inst-pybl cursor-pointer'}
                                    />
                                   
                                    <Column
                                        width="120"
                                        field=""
                                        title="Interest Paid"
                                        headerClassName='non-sort text-right'
                                        sortable={false}
                                    />
                                    <Column
                                        width="120"
                                        field=""
                                        title="Date of Challan"
                                        headerClassName='non-sort'
                                        sortable={false}
                                    />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportClause34c;

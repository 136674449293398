import axios from 'axios';
import { BASE_URL, BASE_URL_GO, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleError, handleErrorWithCode, handleConnectorError } from './responseHandler';
import { getConfig } from './common';
import { getAuthToken } from '../utils/AuthToken';


export async function addDeductor(data) {
  const url = `${BASE_URL_GO}/api/v1/deductor`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}
export async function addDeductorFromPortal(data, headers) {
  const url = `${BASE_URL_GO}/api/v1/deductor/add-deductor`;

  try {
    const deductorData = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken(true)}`,
        ...headers,
      }
    });
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function editDeductor(data) {
  const url = `${BASE_URL_GO}/api/v1/deductor`;

  try {
    const deductorData = await axios.put(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function editCorrectionDeductor(data) {
  const url = `${BASE_URL}/api/v1/correction/deductor`;

  try {
    const deductorData = await axios.put(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return deductorData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDeductorById(deductorId, updateHeader = true) {
  const url = `${BASE_URL_GO}/api/v1/deductor/details?deductor_id=${deductorId}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    if (updateHeader) {
      return handleSuccess(deductorData.data);
    }
    return deductorData.data
  } catch (err) {
    throw handleError(err);
  }
}
export async function getCorrectionDeductorById(deductorId, returnId) {
  const url = `${BASE_URL}/api/v1/correction/deductor?deductor_id=${deductorId}&return_id=${returnId}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function downloadExcelTemplate(params) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/download-template${params}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDeductorTracesCredentials(filterStr, set_header = true) {
  const url = `${BASE_URL_GO}/api/v1/deductor/traces/credentials${filterStr}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data, set_header);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getDeductorEfilingCredentials(filterStr, set_header = true) {
  const url = `${BASE_URL_GO}/api/v1/deductor/efiling/credentials${filterStr}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data, set_header);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importChallanFromIncomeTaxPortal(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/efiling/get-challan-data`;

  try {
    const loginData = await axios.post(url, data, getConfig());
    if (Number(loginData.data.status) !== 1) {
      throw (loginData.data.message);
    }

    return loginData;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getPendingRequestList(data) {
  const url = `${BASE_URL_GO}/api/v1/raise-request/pending-deductor/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

export async function getInsightsCredentialsForVerificationByConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/insights/credentials-for-verification`;

  try {
    const response = await axios.post(url, data, getConfig());
    if (Number(response.data.status) !== 1) {
      throw (response.data.message);
    }
    return response;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getDeductorInsightsCredentialsForConnector(filterStr) {
  const url = `${BASE_URL_GO}/api/v1/usuc/insights/deductor-credentials${filterStr}`;

  try {
    const response = await axios.get(url, getConfig());
    if (Number(response.data.status) !== 1) {
      throw (response.data.message);
    }
    return response;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getPanToVerifyAtInsights(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/insights/pan-verify`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return deductorData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInProgressPanVerificationTask(filterStr, set_header = true) {
  const url = `${BASE_URL_GO}/api/v1/pan-verification/inprogress-task${filterStr}`;

  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data, set_header);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadExcelDeductorlist() {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL_GO}/api/v1/deductor/export`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'Deductor List.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
const connectorError = "Unable to connect to the Express Connector. Please ensure that the Expressor Connector is running. If the problem still persists, then Close the Browser completely and restart.";

export async function verifyItCrendital(port, data) {
  let throwError = { code: 404, message: connectorError }
  if (!port) throw throwError;

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/profile-data`;
  try {
    const result = await axios.post(url, data);
    return result;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw throwError;
    } else {
      throw response;
    }
  }
}

export async function bulkImportDeductor(data) {
  const url = `${BASE_URL_GO}/api/v1/deductor/bulkimport`;

  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadFile(urlPath, filename) {
  const goUrl = `${BASE_URL_GO}${urlPath}`
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(goUrl, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result.headers[HEADER_FILE_NAME] ?? `${filename}`);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadFilledExcelTemplate(params) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/download-filled-template${params}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function downloadJustificationReport(deductor_id, request_no) {
  const url = `${BASE_URL_GO}/api/v1/notice-order/download-justification-report?deductor_id=${deductor_id}&request_no=${request_no}`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function viewJustificationReport(deductor_id, request_no) {
  const url = `${BASE_URL_GO}/api/v1/notice-order/view-justification-report?deductor_id=${deductor_id}&request_no=${request_no}`;

  try {
    const response = await axios.get(url, getConfig());
    if (Number(response.data.status) !== 1) {
      throw (response.data.message);
    }
    return response;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getInsightsVerifyEfilingRegistration(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/efiling/insights/new-registration`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return deductorData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadExcelTemplateSalary(isDetailTemplate) {
  const url = `${BASE_URL_GO}/api/v2/salary/download-template-${isDetailTemplate ? "detailed" : "lite"}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function exportSalaryReturn(params) {
  const url = `${BASE_URL_GO}/api/v2/salary/export${params}`;
  try {
    const config = { ...getConfig() };
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getPanWiseList(data) {
  const url = `${BASE_URL_GO}/api/v1/deductor/pan-wise-list-all`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import Form3CDTimeLine from './Form3CDTimeLine';
import queryString from 'query-string';
import downloadimpticon from "../../images/importimg01.png";
//import checkIconGreen from "../../images/checkicon_green.png";
import { getTanWiseReturnSummary } from '../../api/form3cdApi.js';
import Form3CDBulkImport from '../createDeductor/Form3CDBulkImport';
import { BASE_PATH, FORM_3CD_START_YEAR } from '../../app/constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import RaiseRequestPopup from '../returnRaise/RaiseRequestPopup';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import { Link } from 'react-router-dom';
import IncompleteDeductors from './IncompleteDeductors';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import { deepClone } from "../../utils/UtilityFunctions";
import { process } from "@progress/kendo-data-query";
import sys_btn from "../../images/icons/sys_btn_icon.svg";
import './PanWiseTanDetails.scss';

const TanWiseReturnSummary = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [reportSummaryData, setReportSummaryData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [browseType, setBrowseType] = useState("null");
    const [isBulkImport, setIsBulkImport] = useState(false);
    const [dataState, setDataState] = useState({});
    const [raiseRequestPreData, setRaiseRequestPreData] = useState({ financial_year: null, form_type: null, quarter: null });
    const [deductorId, setDeductorId] = useState(false);
    const [tan, setTan] = useState(false);
    const [credentialAvailable, setCredentialAvailable] = useState(false);
    const [currentStep, setCurrentStep] = useState(null);
    const [showRaiseRequestPopup, setShowRaiseRequestPopup] = useState(false);
    const [isConnectorRunning, setIsConnectorRunning] = useState(false);
    const [portUpdate, setPortUpdate] = useState(0);
    const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);


    const fy = params?.finacial_year ? params?.finacial_year : FORM_3CD_START_YEAR;
    const uniqueTANs = reportData?.length > 0 ? [...new Set(reportData.map(item => item.tan))] : [];
    // Define quarters and forms
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const forms = ['24Q', '26Q', '27Q', '27EQ'];
    const importSource = {
        "RAISE_MANUAL": "Raise Manually",
        "CONSO": "Conso Imported",
        "REQ_RAISED": "Request Raised",
        "TXT": "TXT imported",
        "TXT_NOT_IMP": "Txt Not Imported"
    };

    const getFilteredData = (data) => {

        const uniqueTANs = data?.length > 0 ? [...new Set(data.map(item => item.tan))] : [];
        const transformedData = [];

        uniqueTANs.forEach(tan => {
            forms.forEach(form => {
                let row = { tan, form };
                quarters.forEach(quarter => {
                    const item = data.find(d => d.tan === tan && (d.form_type && d.form_type === form) && (d.quarter && d.quarter === quarter));
                    if (item) {
                        row[quarter] = item?.trans_data_status;
                        row["deductor_id"] = item?.deductor_id;
                        row["deductor_name"] = item?.deductor_name;
                    }
                });
                // Only push the row if all quarters exist           
                transformedData.push(row);
            });
        });

        const filteredTransformedData = transformedData.filter(item => {
            return item.hasOwnProperty('Q1') ||
                item.hasOwnProperty('Q2') ||
                item.hasOwnProperty('Q3') ||
                item.hasOwnProperty('Q4');
        });

        return filteredTransformedData;
    }

    // Function to fetch tan-wise return summary
    const tanWiseReturnSummaryData = async (data) => {
        setLoading(true);
        try {
            const result = await getTanWiseReturnSummary(data.pan, data.financial_year);

            setReportSummaryData(result?.data ?? []);

            const transformedData = getFilteredData(result?.data)

            setReportData(transformedData);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    // Get parameters and trigger data fetch on mount or location change
    const getParamsHistoryUrl = () => {
        const postData = {
            pan: params.pan
        };
        if (params.fy) {
            postData.financial_year = params.fy;
        }

        postData.incomplete_deductors = true;
        setDataState(dataState);
        return postData;
    };

    useEffect(() => {

        if (!isBulkImport) {
            const queryData = getParamsHistoryUrl();
            tanWiseReturnSummaryData(queryData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, isBulkImport]);


    // Placeholder function for handling bulk import
    const handleBulkImport = () => {
        // Implement logic for handling bulk import here
        setBrowseType("txt");
        setIsBulkImport(true);
    };

    const raiseRequestBtn = async (data) => {

        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setCurrentStep(1)
        if (credentialAvailable) {
            setShowRaiseRequestPopup(true);
            return null;
        }

        setIsConnectorRunning(true);
        setRaiseRequestPreData((prev) => {
            prev.financial_year = data?.financial_year ?? null;
            prev.form_type = data?.form_type ?? "";
            prev.quarter = data?.quarter ?? "";

            return { ...prev }
        })
    };


    const getTotalCount = (tan) => {
        // Filter the data based on the presence of Q1, Q2, Q3, and Q4 keys
        const filteredData = reportData.filter(item => {
            return item.hasOwnProperty('Q1') ||
                item.hasOwnProperty('Q2') ||
                item.hasOwnProperty('Q3') ||
                item.hasOwnProperty('Q4');
        });

        // Count the occurrences of the specific 'tan'
        const count = filteredData.reduce((acc, item) => {
            return item.tan === tan ? acc + 1 : acc;
        }, 0);

        return count;
    }


    const rowRender = (trElement, props) => {
        const key = trElement._owner.index;
        const item = props.dataItem ?? {}; // Ensure dataItem is defined 

        const getItemClass = (value) => {
            if (value === "RAISE_MANUAL") {
                return "text-center report34a_vertical tdstanwise_textclr_blue tdstanwise_textclr_underline";
            }
            if (value === "CONSO" || value === "TXT") {
                return "text-center report34a_vertical tdstanwise_textclr_green";
            }
            if (value === "REQ_RAISED") {
                return "text-center report34a_vertical tdstanwise_textclr_yell";
            }
            if (value === "TXT_NOT_IMP") {
                return "text-center report34a_vertical tdstanwise_textclr_purpal";
            }
            return "text-center report34a_vertical";
        };

        const itemClassQ1 = getItemClass(item.Q1);
        const itemClassQ2 = getItemClass(item.Q2);
        const itemClassQ3 = getItemClass(item.Q3);
        const itemClassQ4 = getItemClass(item.Q4);

        const tCount = getTotalCount(item.tan);
        const isTanRepeat = (reportData?.[key - 1] && reportData[key - 1]?.tan === item.tan) ? true : false;

        return (
            <>
                <tr>

                    {key === 0 && (
                        <td rowSpan={reportData?.length} className=' custom-background' style={{ textAlign: 'center' }} >
                            <span className='vertical-text-center' > Completed Deductors </span>
                        </td>
                    )}

                    {!isTanRepeat &&
                        <td className="text-center report34a_vertical tan-summary-tancol-bglcr-white" rowSpan={tCount}>
                            {item.deductor_name ? (
                                <>
                                    {item.deductor_name}
                                    <br />
                                </>
                            ) : null}
                            {item.tan ?? ""}
                        </td>
                    }

                    <td className="text-center report34a_vertical" >
                        {item.form}
                    </td>
                    <td className={itemClassQ1}  >

                        {(item.Q1 === "RAISE_MANUAL") ?
                            <span style={{ cursor: 'pointer' }} onClick={() => raiseRequestBtn({
                                "deductor_id": item.deductor_id,
                                "financial_year": fy,
                                "form_type": item.form,
                                "quarter": "Q1"
                            })}>{importSource[item.Q1] ?? "-"}</span>
                            :
                            importSource[item.Q1] ?? "-"
                        }

                    </td>
                    <td className={itemClassQ2} >
                        {(item.Q2 === "RAISE_MANUAL") ?
                            <span style={{ cursor: 'pointer' }} onClick={() => raiseRequestBtn({
                                "deductor_id": item.deductor_id,
                                "financial_year": fy,
                                "form_type": item.form,
                                "quarter": "Q2"
                            })}>{importSource[item.Q2] ?? "-"}</span>
                            :
                            importSource[item.Q2] ?? "-"
                        }
                    </td>
                    <td className={itemClassQ3} >
                        {(item.Q3 === "RAISE_MANUAL") ?
                            <span style={{ cursor: 'pointer' }} onClick={() => raiseRequestBtn({
                                "deductor_id": item.deductor_id,
                                "financial_year": fy,
                                "form_type": item.form,
                                "quarter": "Q3"
                            })}>{importSource[item.Q3] ?? "-"}</span>
                            :
                            importSource[item.Q3] ?? "-"
                        }
                    </td>
                    <td className={itemClassQ4} >
                        {(item.Q4 === "RAISE_MANUAL") ?
                            <span style={{ cursor: 'pointer' }} onClick={() => raiseRequestBtn({
                                "deductor_id": item.deductor_id,
                                "financial_year": fy,
                                "form_type": item.form,
                                "quarter": "Q4"
                            })}>{importSource[item.Q4] ?? "-"}</span>
                            :
                            importSource[item.Q4] ?? "-"
                        }
                    </td>
                </tr>
            </>
        );
    };


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const dataStateChange = (dataState) => {
        if (dataState.filter || (dataState.sort && dataState?.sort?.length > 0)) {
            const filter_state = deepClone(dataState);
            if (filter_state?.filter?.filters?.length > 0) {
                filter_state.filter.filters.forEach((item) => {
                    if (item?.filters?.length > 0) {
                        item.filters.forEach((itemObj) => {
                            if (["gte", "lte", 'lt', 'gt', "neq"].includes(itemObj?.operator)) {
                                itemObj.value = Number(itemObj.value);
                            }
                            else if (["eq"].includes(itemObj?.operator) && Array.isArray(itemObj.value)) {
                                itemObj.value = itemObj.value?.[0] ?? "";
                            }
                        })
                    }
                })
            }
            const dataProcess = process(reportSummaryData, { ...filter_state });
            setDataState({ ...dataState });
            setReportData(getFilteredData(dataProcess.data) ?? []);

        } else {
            setDataState({});
            const defaultData = JSON.parse(JSON.stringify(reportSummaryData));
            setReportData(getFilteredData(defaultData) ?? []);
        }
    };

    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <Form3CDTimeLine currentPage="returnSummary" getPan={params.pan} getFinYear={params.fy} deductorName={params.deductor_name ?? ""} />

            {isBulkImport &&
                <Form3CDBulkImport
                    show={isBulkImport}
                    closeModal={() => { setIsBulkImport(false); }}
                    onHide={() => { setIsBulkImport(false); }}
                    setSuccess={setSuccess}
                    handleClose={() => {
                        setIsBulkImport(false);
                        setReportData([...reportData]); // just for re-render
                    }}
                    ifBulkImport={true}
                    browseType={browseType}
                    setImportedClientList={null}
                    setError={setError}
                    error={error}
                    setDefaultClientList={null}
                    // setImportData={setImportData}
                    // setImportStatus={setImportStatus}
                    // setShowProgressBar={setShowProgressBar}
                    reportData={reportData}
                    financialYear={params.fy ?? String(FORM_3CD_START_YEAR)}
                    uniqueTANs={uniqueTANs}
                />
            }

            {isConnectorRunning
                ? <ConnectorAdapter
                    show={isConnectorRunning}
                    handleClose={() => setIsConnectorRunning(false)}
                    setPort={setPortUpdate}
                    handleProceed={() => {
                        setVerifyTraceCredentials(true);
                    }
                    }
                />
                : null
            }

            {verifyTraceCredentials && portUpdate
                ?
                <>
                    <TracesConnector
                        dontLogin={currentStep === 1 ? true : false}
                        deductorId={deductorId}
                        returnId={null}
                        handleProceed={() => {
                            setIsConnectorRunning(false);
                            setVerifyTraceCredentials(false);
                        }}
                        port={portUpdate}
                        setVerifyCreds={() => {
                            setCredentialAvailable(true)
                            currentStep === 1 && setShowRaiseRequestPopup(true);
                            //currentStep === 2 && callStatusCheck();
                        }}
                        creds={null}
                        setCaptchaRef={setCaptchaRef}
                        callCaptchaRef={true}
                        tan={tan}
                    />
                </>
                : null
            }

            {showRaiseRequestPopup ?
                <RaiseRequestPopup
                    show={showRaiseRequestPopup}
                    handleClose={() => {
                        setShowRaiseRequestPopup(false);
                    }}
                    handleCallOnSuccess={() => {
                        setShowRaiseRequestPopup(false);
                        window.location.reload();
                    }}
                    deductorId={deductorId}
                    pan={params?.pan}
                    captchaRef={captchaRef}
                    port={portUpdate}
                    setSuccess={setSuccess}
                    preDefineRaiseData={raiseRequestPreData}
                    checkConso={true}
                />
                : null}



            <div className="container mt-2 mb-3">
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div className="dropdown tds_form3CDRevamp_yr_dropdownbox">
                            {/* <button className="tds_form3CDRevamp_imptaxtbluk_btn" onClick={() => handleBulkImport()}>
                                Import TXT in Bulk
                            </button> */}
                            <Link to={`${BASE_PATH}form3CD/incomplete-deductors?pan=${params.pan}&fy=${fy}`}>
                                <button className="btn btn-default form3cd_sync_btn mb-2">
                                    <span className="form3cd_returnreg_imgbox">
                                        <img src={sys_btn} alt='sys_btn' style={{ paddingRight: "3px", marginTop: "-2px" }} />
                                        <span className="form3cd_returnreg_textfs pl-1">Sync Now</span>
                                    </span>
                                </button>
                            </Link>
                        
                        </div>
                    </div>
                    <div className="col-md-12 text-center pt-3 ">

                        <div className='trbottom_border' style={{ float: 'left', paddingBottom: '10px' }}>
                            <div className="card tds_form3CDRevamp_genrepottyp_cardbox " style={{ padding: '0px' }}>

                                <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" >
                                    <Grid
                                        className="table table-striped  tanwise_returnsumm_tb scrollvisible incvisibleover mb-0"
                                        data={reportData}
                                        rowRender={rowRender}
                                        {...dataState}
                                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                                        filterOperators={{
                                            text: [
                                                { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            ],
                                        }}
                                        sortable
                                    >
                                        <Column
                                            width="23"
                                            title=''
                                        />
                                        <Column
                                            width="195"
                                            field="tan"
                                            title='Deductor Name(TAN)'
                                            filter="text"
                                            columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                            headerClassName={isColumnActive('tan') ? 'active cursor-pointer' : 'cursor-pointer'}
                                        />
                                        <Column
                                            width="100"
                                            field="form_type"
                                            sortable={false}
                                            title='Form Type' />
                                        <Column
                                            width="100"
                                            field="return_filing_due_date"
                                            sortable={false}
                                            title='Q1' />
                                        <Column
                                            width="100"
                                            field="return_filing_date"
                                            sortable={false}
                                            title="Q2"
                                        />
                                        <Column
                                            width="100"
                                            field=""
                                            className='long-column-title'
                                            sortable={false}
                                            title="Q3"
                                        />
                                        <Column
                                            width="100"
                                            field=""
                                            title="Q4"
                                            sortable={false}
                                            className='long-column-title' />

                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <div className="card tds_form3CDRevamp_genrepottyp_cardbox deductormasterlist incomplete_deductorlis_css" style={{ padding: '0px' }}>
                            <IncompleteDeductors
                                params={params}
                            />
                        </div>

                        <div className=''>
                            <div className='row align-items-center'>

                                <div className='col-11 col-md-12 mt-2'>
                                    <div className='card tds_form3CDRevamp_cardtxtfile_box' onClick={() => handleBulkImport()} >
                                        <span className="tds_form3CDRevamp_imprtimgbox">
                                            <img src={downloadimpticon} alt='Download' title='Import' width='25' className='cursor-pointer' />
                                        </span>
                                        <span className='tds_form3CDRevamp_cardtxtfile_textfs text-center'>
                                            If you have txt files available for All or Any quarter and Form, you can click here to import and
                                            system will automatically raise conso request.
                                            In the meantime, till the conso files are available you will be able to view
                                            data related to 3CD in timeline steps 3 to 5
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div >

            <div className='container-fluid mt-5 pt-2 mb-3'>
                <div className="row mt-3 mb-2">
                    <div className="col-md-12">
                        <div className='panwisereturn_bottomsection'>
                            <table className="table table-borderless tds_tanwise_returnsumm_legendtb mb-0">
                                <tr>
                                    <td width="130" className=" tdstanwise_textclr_blue tdstanwise_textclr_underline text-left">
                                        Raise Manually
                                    </td>
                                    <td width="" className="text-left">
                                        Raise request of conso if you do not have the original TXT or
                                        Latest conso or data is available in the system but receipt number not available
                                    </td>
                                </tr>
                                <tr>
                                    <td width="" className=" tdstanwise_textclr_green text-left">
                                    Txt/Conso Imported
                                    </td>
                                    <td width="" className="text-left">
                                    Txt/Conso Imported by raising request or by import
                                    </td>
                                </tr>
                                <tr>
                                    <td width="" className=" tdstanwise_textclr_yell text-left">
                                        Request Raised
                                    </td>
                                    <td width="" className="text-left">
                                        Request Raised for consolidated file manually or automatically
                                    </td>
                                </tr>
                                <tr>
                                    <td width="" className=" tdstanwise_textclr_purpal text-left">
                                        TXT not Imported
                                    </td>
                                    <td width="" className="text-left">
                                        TXT could not be Imported may be file was invalid or due to any technical reason
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TanWiseReturnSummary;

/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// -- Validation Rules
export const deducteeFormValidationSchema = Yup.object().shape({
  deductee_name: Yup.string()
    .trim()
    .min(1, 'Too Short!')
    .max(75, 'Too Long!')
    .required('This field is required'),

  email: Yup.string().nullable()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),

  deductee_type: Yup.string().nullable().required('This field is required'),

  tax_regime: Yup.string().nullable().required('This field is required'),

  pan: Yup.string()
    .required('This field is required')
    .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANAPPLIED)|(PANINVALID)|(PANNOTAVBL)$/, 'Invalid PAN'),

  employee_id: Yup.string().nullable()
    .matches(/^[A-Za-z0-9 ]+$/, 'Special Characters are not allowed'),

  mobile_no: Yup.string().nullable()
    // .min(1111111111, 'Invalid Mobile Number')
    // .matches(/^(?!0{10,16}$)(0?\d{10,15})$/, 'Invalid Mobile Number')
    .matches(/^(?!.*[^\d])(?!0{10,16}$)(0?\d{10,15})$/, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),

  address: Yup.string().nullable()
      .matches(/^[a-zA-Z0-9].*/, 'Invalid Address'),

  city: Yup.string().nullable()
      .matches(/^[a-zA-Z].*/, 'Invalid City'),  
    
  ref_number: Yup.string().nullable()
    .when('pan', {
      is: (value) => (value === 'PANAPPLIED' || value === 'PANINVALID' || value === 'PANNOTAVBL'),
      then: Yup.string().nullable().required('This field is required').length(10, 'Ref No should be 10 characters long'),
    }),
  tax_identification_no: Yup.string().nullable()
    .matches(/^(?![0]*$)[a-zA-Z0-9]+$/g, 'Invalid Tax Identification No.'),

  country_id: Yup.string().nullable()
    .when('state_id', {
      is: (value) => (value === 99),
      then: Yup.string().matches(/^(?!113).*$/, 'Select Other than India'),
    }).when('state_id', {
      is: (value) => (value && value !== 99),
      then: Yup.string().matches(/^(113).*$/, 'Select India '),
    }),
});

export const correctionDeducteeFormValidationSchema = Yup.object().shape({
    deductee_name: Yup.string()
        .trim()
        .min(1, 'Too Short!')
        .max(75, 'Too Long!')
        .required('This field is required'),

    email: Yup.string().nullable()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,'Invalid email'),

    deductee_type: Yup.string().nullable().required('This field is required'),

    pan: Yup.string()
        .required('This field is required')
        .matches(/(([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z]))|(PANAPPLIED)|(PANINVALID)|(PANNOTAVBL)$/, 'Invalid PAN'),
    
    employee_id: Yup.string().nullable()
        .matches(/^[A-Za-z0-9 ]+$/, 'Special Characters are not allowed'),
   
    mobile_no: Yup.string().nullable()
    // .min(1000111111, 'Invalid Mobile Number')
    .matches(/^(?!.*[^\d])(?!0{10,16}$)(0?\d{10,15})$/, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),


    address: Yup.string().nullable()
        .matches(/^[a-zA-Z0-9].*/, 'Invalid Address'),    
    
    city: Yup.string()
        // .matches(/^[A-Za-z& ]+$/, 'Invalid City Name'),
        .matches(/^[a-zA-Z].*/, 'Invalid City'),  

    ref_number: Yup.string().nullable()
        .when('pan', {
            is: (value) => (value === 'PANAPPLIED' || value === 'PANINVALID' || value === 'PANNOTAVBL'),
            then: Yup.string().nullable().required('Ref No is required').length(10, 'Ref No should be 10 characters long'),
        }),
    tax_identification_no: Yup.string().nullable()
        .matches(/^(?![0]*$)[a-zA-Z0-9]+$/g, 'Invalid Tax Identification No.'),
        
    country_id: Yup.string().nullable()
        .when('state_id', {
            is: (value) => (value === 99),
            then: Yup.string().matches(/^(?!113).*$/, 'Select Other than India'),
        }).when('state_id', {
            is: (value) => (value && value !== 99),
            then: Yup.string().matches(/^(113).*$/, 'Select India '),
        }),
});
import React, { useEffect, useState } from 'react'
import $ from "jquery";
import WindowWrapperFixed from '../../../components/grid/WindowWrapperFixed';
import { convertNum, FormatNumberDecimal, FormatNumberDecValue1 } from '../../../utils/UtilityFunctions';
import { VALIIDATION_SCHEMA } from '../salaryHelper';
import { getTravelExemptAmt } from '../../../components/salaryDetailsForm/allowancesCalculationScema';

const TravelConcession = (props) => {
  const { onClose, parentPopupWidth, info, mainData, updateEmployeeDetail, headerData, onlyView = false } = props;
  const index = info.index ?? 0;
  const isCent_gov_emp = headerData.deductor_category && (headerData.deductor_category === 'A' || headerData.deductor_category === 'D' || headerData.deductor_category === 'G' || headerData.deductor_category === 'L') ? 1 : 0;
  const defaultObj = {
    whether_cent_gov_emp: isCent_gov_emp,
    whether_cash_allowance: 0,
    transport_mode: "Air Route",
    air_route_amtp: 0,
    family_person: 0,
    rail_connect_route_amtp: 0,
    actual_exp_paid_amtp: 0,
    other_route_amtp: 0,
    exempt_amtp: 0,
  }
  const width1 = document.body.clientWidth;
  const popupWidth = 700;
  const additionalGap = 10;
  const initialLeft = width1 - (parentPopupWidth + (popupWidth + additionalGap));

  const [travelConcession, setTravelConcession] = useState({});
  const [formError, setFormError] = useState({});

  const calculateExemptAmt = (values) => {
    let exempt_amtp = getTravelExemptAmt(values);
    values['exempt_amtp'] = Number(exempt_amtp);
  }
  const handleChange = (name, value) => {
    travelConcession[name] = value;
    calculateExemptAmt(travelConcession);
    setTravelConcession({ ...travelConcession });
  }

  const saveData = () => {
    const valid = VALIIDATION_SCHEMA.travelConcessionValidationSchema(travelConcession);
    if (!valid.validation) {
      setFormError(valid.errObj);
      return;
    }
    if (!mainData?.allowances?.[index]) mainData.allowances[index] = {};
    mainData.allowances[index].received_amtp = travelConcession?.actual_exp_paid_amtp ?? "";
    mainData.allowances[index].exempt_amtp = travelConcession?.exempt_amtp ?? "";
    mainData.allowances[index].allowance_details = travelConcession;
    updateEmployeeDetail(mainData, onClose);
  };

  useEffect(() => {
    const travelApiData = info.poppupData ?? defaultObj;
    travelApiData.whether_cent_gov_emp = isCent_gov_emp;

    if(mainData?.allowances?.[index]){
      travelApiData.actual_exp_paid_amtp = mainData?.allowances?.[index]?.received_amtp ?? "";
      travelApiData.exempt_amtp = mainData?.allowances?.[index]?.exempt_amtp ?? "";
    };
    
    setTravelConcession({ ...travelApiData });
      //eslint-disable-next-line
  }, [info])

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  return (
    <>
      <WindowWrapperFixed
        title={<kendo-dialog-titlebar style={{ padding: '8px 0px 3px 0px', width: "1000%" }}>
          <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
            <div className="row">
              <span className="taxregim_textfs pr-3">
                Travel Concession or Assistance u/s 10(5)
              </span>
            </div>
          </div>
        </kendo-dialog-titlebar>}
        onClose={onClose}
        initialHeight={'auto'}
        initialWidth={popupWidth}
        initialTop={50}
        totalPopups={1}
        isModalView={true}
        initialLeft={initialLeft}
        className={'salarary_detail_popup'}
      >
        <div className="pb-2 pt-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card salary_taxreg_allowance">
                <div className="form form-row salary_taxreg_allowance_form">
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Whether Cent Govt Employ</label>
                  </div>
                  <div className="form-group col-md-2">
                    <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled
                      value={travelConcession?.whether_cent_gov_emp ? 1 : 0}
                    
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Whether Cash Allowance </label>
                  </div>
                  <div className="form-group col-md-4">
                    <select name="whether_gov_emp" className="custom-select salary_taxreg_allowance_selbox" disabled={onlyView}
                      value={travelConcession?.whether_cash_allowance ? 1 : 0}
                      onChange={(e) => {
                        handleChange("whether_cash_allowance", convertNum(e.target.value));
                      }}
                    >
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>

                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Mode of Transport</label>
                  </div>
                  <div className="form-group col-md-9">
                    <label className="salary_taxreg_allowance_labelfs" for="">Fare</label>
                  </div>


                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Air Route</label>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.air_route_amtp ? "input-error" : ""}`} placeholder=""
                      data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.air_route_amtp ? formError?.air_route_amtp : ""}
                      value={FormatNumberDecimal(travelConcession.air_route_amtp, ...Array(2), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        travelConcession.rail_connect_route_amtp = 0;
                        travelConcession.other_route_amtp = 0;
                        travelConcession.transport_mode = 'Air Route';
                        handleChange("air_route_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">No. of Family Person</label>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.family_person ? "input-error" : ""}`} placeholder=""
                      data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.family_person ? formError?.family_person : ""}
                      value={FormatNumberDecValue1(travelConcession.family_person ?? "", ...Array(3), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(3), false, e);
                        handleChange("family_person", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>



                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Rail Connect Route</label>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.rail_connect_route_amtp ? "input-error" : ""}`} placeholder=""
                      data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.rail_connect_route_amtp ? formError?.rail_connect_route_amtp : ""}
                      value={FormatNumberDecimal(travelConcession.rail_connect_route_amtp, ...Array(2), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        travelConcession.air_route_amtp = 0;
                        travelConcession.other_route_amtp = 0;
                        travelConcession.transport_mode = "Rail Connect Route"
                        handleChange("rail_connect_route_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Actual Exp. Paid</label>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.actual_exp_paid_amtp ? "input-error" : ""}`}
                      placeholder="" data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.actual_exp_paid_amtp ? formError?.actual_exp_paid_amtp : ""}
                      value={FormatNumberDecimal(travelConcession.actual_exp_paid_amtp, ...Array(2), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        handleChange("actual_exp_paid_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Other Route</label>
                  </div>
                  <div className="form-group col-md-2">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.other_route_amtp ? "input-error" : ""}`}
                      placeholder="" data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.other_route_amtp ? formError?.other_route_amtp : ""}
                      value={FormatNumberDecimal(travelConcession.other_route_amtp, ...Array(2), false)}
                      onChange={(e) => {
                        const value = FormatNumberDecValue1(e.target.value, ...Array(4), e);
                        travelConcession.air_route_amtp = 0;
                        travelConcession.rail_connect_route_amtp = 0;
                        travelConcession.transport_mode = 'Other Route';
                        handleChange("other_route_amtp", value);
                      }}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="salary_taxreg_allowance_labelfs" for="">Exempt Amt</label>
                  </div>
                  <div className="form-group col-md-4">
                    <input type="text" className={`form-control salary_taxreg_allowance_inputbox ${formError.exempt_amtp ? "input-error" : ""}`}
                      placeholder="" data-toggle="tooltip"
                      data-placement="right" data-original-title={formError?.exempt_amtp ? formError?.exempt_amtp : ""}
                      value={FormatNumberDecimal(travelConcession.exempt_amtp, ...Array(2), false)}
                      disabled
                    />
                  </div>



                </div>
              </div>
            </div>

            <div className="col-md-12 pt-2 text-center">
              <button className="btn btn-default salary_taxreg_allowance_savebtn" disabled={onlyView}
                onClick={() => saveData()}
              >Save & Exit</button>
            </div>
          </div>
        </div>
      </WindowWrapperFixed>
    </>
  )
}

export default TravelConcession
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import downloadIcon from "../../images/downloadpdf.png";
import viewIcon from "../../images/eyeiconcolr.png";
import refIcon from "../../images/refreshiconblue.png";
import { checkRaiseRequestFromConnector, gocallApi } from '../../api/issueCertificateAPI';
import ShowAlert from '../../components/ShowAlert';
import { BASE_PATH, HEADER_CONNECTOR_DIGEST_KEY, HEADER_FILE_NAME } from '../../app/constants';
import moment from 'moment';
import { downloadFile, downloadJustificationReport } from '../../api/deductorAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { QUARTER_LIST } from '../../app/constants';
import { formatAccountsNumber, getFinancialYears } from '../../utils/UtilityFunctions';
import "./NoticeOrder.scss";
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import NoticeSyncData from '../../components/NoticeSyncData';
import SemanticPagination from '../../components/pagination/SemanticPagination';
import RaiseRequestPopup from '../returnRaise/RaiseRequestPopup';
import excelIcon from '../../images/excel.png';
import raiseRequestIcon from '../../images/raise_request.png';


const NoticeOrders = () => {

    const FinancialYear = getFinancialYears();
    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);

    const [noticeOrderList, setNoticeOrderList] = useState([]);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null });
    const [port, setPort] = useState(0);
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [captchaRef, setCaptchaRef] = useState(null);
    const [deductorId, setDeductorId] = useState(false);
    const [tan, setTan] = useState(false);
    const [startConnector, setStartConnector] = useState(false);
    const [noticeOrderSummary, setNoticeOrderSummary] = useState(null)
    const [summaryStatus, setSummaryStatus] = useState(true);

    const [verifyTraceCredentials, setVerifyTraceCredentials] = useState(false);
    const [isConnectorRunning, setIsConnectorRunning] = useState(false);
    const [credentialAvailable, setCredentialAvailable] = useState(false);
    const [currentStep, setCurrentStep] = useState(null);
    const [showRaiseRequestPopup, setShowRaiseRequestPopup] = useState(false);
    //eslint-disable-next-line
    const [deductorPan, setDeductorPan] = useState('');
    const [portUpdate, setPortUpdate] = useState(0);

    const [raiseRequestPreData, setRaiseRequestPreData] = useState({ financial_year: null, form_type: "", quarter: "" });


    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const createHistoryUrl = (filters) => {

        let filterStr = `?page=1`

        if (filters.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
        }
        if (filters.sort && filters.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
        }


        history.push(filterStr);
    };

    const getParamsHistoryUrl = () => {

        if (params?.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
        }
        if (params?.filters?.length > 0) {
            dataState.filter = { filters: [] };
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'issued_at') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }
            dataState.filter.filters = frs;
        } else {
            dataState.filter = { filters: [] };
        }

        setDataState({ ...dataState });
    };

    const getNoticeOrderSummary = async () => {

        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            filters: params.filters ? filters : null
        }
        let url = `/api/v1/notice-order/summary`;

        try {
            const result = await gocallApi("post", url, payload)
            setNoticeOrderSummary(result?.data ?? 0);
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            //do nothing
        }
    }

    const dataStateChange = (data_state) => {
        createHistoryUrl(data_state);
        setDataState({ ...data_state });

        //Get Summary of Notice Order
        if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(true)
        } else if (data_state?.filter?.filters?.length === 0 && data_state?.sort?.length > 0) {
            setSummaryStatus(false)
        } else if (data_state?.filter?.filters?.length > 0 && data_state?.sort?.length === 0) {
            setSummaryStatus(true)
        } else {
            setSummaryStatus(true) //default true
        }
    };

    //downlaod justification report
    const downloadJustReport = async (dataItem) => {
        setLoading(true);
        try {
            const result = await downloadJustificationReport(dataItem?.deductor_id, dataItem?.jr_request_no);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS_Justification_Report.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error(err)
            setError(err);
        }
        setLoading(false);
    };

    const updateStatusBtn = async (data) => {
        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setCurrentStep(2)
        if (credentialAvailable && captchaRef) {
            setVerifyTraceCredentials(true);
            return null;
        }
        setIsConnectorRunning(true);
    };

    const raiseRequestBtn = async (data) => {

        setRaiseRequestPreData((prev) => ({
            ...prev,
            financial_year: data?.financial_year ?? null,
            form_type: data?.form_type ?? "",
            quarter: data?.quarter ?? ""
        }));

        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setCurrentStep(1)
        setIsConnectorRunning(true);
        if (credentialAvailable) {
            setShowRaiseRequestPopup(true);
            return null;
        }
    };

    const conditionalActionButton = (props) => {

        const dataItem = props?.dataItem ?? {}

        let requestNum = dataItem?.jr_request_no;

        if (dataItem?.jr_new_request === 1) {
            return <span data-toggle="tooltip" data-placement="top" title='Raise Request'>
                <img src={raiseRequestIcon} alt='raise request icon' className="downloadicon_pointer" width="19" onClick={() => raiseRequestBtn(dataItem)} />
            </span>
        } else if (requestNum && dataItem?.jr_status !== "AVAILABLE") {
            return <span data-toggle="tooltip" data-placement="top" title='Update Status'>
                <img src={refIcon} width="16" alt="Update Status" className="downloadicon_pointer" onClick={() => updateStatusBtn(dataItem)} />
            </span>
        } else if (requestNum && dataItem?.jr_status === "AVAILABLE") {
            return <span data-toggle="tooltip" data-placement="top" title='Download Report'>
                <img src={excelIcon} alt='download icon' className="downloadicon_pointer" width="19" onClick={() => downloadJustReport(dataItem)} />
            </span>
        } else {
            return <span>{'-'}</span>
        }
    };

    const rowRender = (trElement, props) => {

        const deductor_name = props?.dataItem?.deductor_name ? props?.dataItem?.deductor_name.toLowerCase() : "";
        const key = trElement._owner.index;

        return (
            <tr key={key} role='row'>
                <td className="text-left noticesorder_textoverflow">
                    <span className='transcaptilize' title={props?.dataItem?.deductor_name ?? ""}>
                        {deductor_name ?? ""}
                    </span>
                </td>
                <td className="text-center noticepaddright">
                    {props?.dataItem.financial_year ? `${props?.dataItem.financial_year} - ${String(props?.dataItem.financial_year + 1).slice(2)}` : "-"}
                </td>
                <td className="text-center noticepaddright">
                    {`${props?.dataItem?.form_type ? props?.dataItem?.form_type : "-"}${props?.dataItem.quarter ? " | " + props?.dataItem.quarter : ""}`}
                </td>
                <td className="text-center noticepaddright">
                    {props?.dataItem.issued_at ?? "-"}
                </td>

                <td className="text-left noticesorder_textoverflow notices_orders_rowbgclr_yell notices_orders_rowbordergrey">
                    <span className='transcaptilize' title={props?.dataItem.subject ?? "-"}>
                        {props?.dataItem.subject ?? "-"}
                    </span>
                </td>
                <td className="text-left noticesorder_textoverflow notices_orders_rowbgclr_yell notices_orders_rowbordergrey">
                    <span className='transcaptilize' title={props?.dataItem.description ?? "-"}>
                        {props?.dataItem.description ?? "-"}
                    </span>
                </td>
                <td className="text-right notices_orders_rowbgclr_pink notices_orders_rowbordergrey">
                    {formatAccountsNumber(props?.dataItem?.demand_amtp) ?? "-"}
                </td>

                <td className="text-center px-1" rel="noreferrer">
                    <span data-toggle="tooltip" data-placement="top" title='Download Intimation Report'>
                        <img src={downloadIcon} alt='download icon' className="downloadicon_pointer" width="19" onClick={() => {
                            if (props?.dataItem.request_no && props?.dataItem.status === "AVAILABLE") {
                                noticeOrderExcelList(props?.dataItem)
                            } else {
                                if (props?.dataItem.sync_status === "IN_PROGRESS") {
                                    setError("The requested file will be available soon. Please revisit shortly.");
                                } else {
                                    setSyncData(props?.dataItem, props.dataIndex)
                                }

                            }
                        }} />
                    </span>

                </td>
                <td className='text-center px-1'>
                    {conditionalActionButton(props)}
                </td>
                <td className='text-center px-1'>
                    {props?.dataItem?.jr_request_no && props?.dataItem?.jr_status === "AVAILABLE" &&
                        <Link className="btn btn-default tds_creatdeduct_pendbtnbgclr  planexpiredalert p-0" to={`${BASE_PATH}notice-orders-report?deductor_id=${props?.dataItem?.deductor_id}&request_no=${props?.dataItem?.jr_request_no}&form_type=${props?.dataItem?.form_type}`}>
                            <span data-toggle="tooltip" data-placement="top" title='View Justification Report'>
                                <img src={viewIcon} alt='view icon' className="downloadicon_pointer " width="16" />
                            </span>
                        </Link>
                    }
                    {(!props?.dataItem?.jr_request_no || props?.dataItem?.jr_request_no === null || (props?.dataItem?.jr_request_no && props?.dataItem?.jr_status !== "AVAILABLE")) &&
                        <span data-toggle="tooltip" data-placement="top" title='To view the report, raise the request'>
                            <span>{'-'}</span>
                        </span>

                    }
                </td>

            </tr>
        )
    };

    const setSyncData = (data, index) => {
        setDeductorId(data?.deductor_id);
        setTan(data?.tan);
        setStartConnector(true);
    }

    const noticeOrderExcelList = async (dataItem) => {
        setLoading(true);
        const urlPath = `/api/v1/notice-order/download-intimation?deductor_id=${dataItem?.deductor_id ?? ""}&ref_num=${dataItem?.ref_num ?? ""}&request_no=${dataItem?.request_no}`;

        try {
            await downloadFile(urlPath, true, "NoticeOrder.pdf")
        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);

        } finally {
            setLoading(false)
        }
    }

    const getRecentDeductorAllList = async (isloading) => {
        if (!isloading) setLoading(true);
        let filters = null;
        if (params?.filters?.length > 0) {
            filters = JSON.parse(atob(params.filters));
        }
        let payload = {
            filters: params.filters ? filters : null,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            page: params.page ? parseInt(params.page) : 1,
            limit: 12
        }
        let url = `/api/v1/notice-order/list`

        try {
            const result = await gocallApi("post", url, payload)
            if (result?.data?.data?.length > 0) {
                result?.data?.data.forEach((item) => {
                    if (item.issued_at) item.issued_at = moment(item.issued_at).format('DD/MM/YYYY')
                })
            }
            if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page ?? null;
            setNoticeOrderList(result?.data?.data ?? []);
            setCursor(result?.data?.cursor ?? { current_page: 1, next_page: null })

        } catch (err) {

            if (err.message) setError(err.message);
            else setError(err);
        } finally {
            if (!isloading) setLoading(false);
        }
    };

    const callStatusCheck = async () => {
        setLoading(true)
        try {
            const url = `/api/v1/usuc/traces/pending-raise-request`
            const result = await gocallApi(
                "post",
                url,
                { deductor_id: deductorId, captcha_ref: captchaRef, },
                true);
            const responseData = result?.data ?? null;
            const responseHeader = result?.headers ?? null;
            const connectorRequest = {
                "data": responseData?.data
            };
            let extraHeader = {};
            extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

            try {
                const connectorResponse = await checkRaiseRequestFromConnector(portUpdate, connectorRequest, extraHeader);
                if (Number(connectorResponse.status) === 1) {
                    setSuccess(connectorResponse?.message);
                    getRecentDeductorAllList();
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(true);
            }

        } catch (err) {
            // setError(err);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getParamsHistoryUrl();
        getRecentDeductorAllList();
        if (summaryStatus) {
            getNoticeOrderSummary(); //Get Summary of Notice Order
        }

        // eslint-disable-next-line
    }, [location.search]);


    return (
        <>

            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {isConnectorRunning
                ? <ConnectorAdapter
                    show={isConnectorRunning}
                    handleClose={() => setIsConnectorRunning(false)}
                    setPort={setPortUpdate}
                    handleProceed={() => {
                        setVerifyTraceCredentials(true);
                    }
                    }
                />
                : null
            }

            {verifyTraceCredentials && portUpdate
                ?
                <>
                    <TracesConnector
                        dontLogin={currentStep === 1 ? true : false}
                        deductorId={deductorId}
                        returnId={null}
                        handleProceed={() => {
                            setIsConnectorRunning(false);
                            setVerifyTraceCredentials(false);
                        }}
                        port={portUpdate}
                        setVerifyCreds={() => {
                            setCredentialAvailable(true)
                            currentStep === 1 && setShowRaiseRequestPopup(true);
                            currentStep === 2 && callStatusCheck();
                        }}
                        creds={null}
                        setCaptchaRef={setCaptchaRef}
                        callCaptchaRef={true}
                        tan={tan}
                    />
                </>
                : null
            }

            {showRaiseRequestPopup ?
                <RaiseRequestPopup
                    show={showRaiseRequestPopup}
                    handleClose={() => {
                        setShowRaiseRequestPopup(false);
                        getRecentDeductorAllList();
                    }}
                    deductorId={deductorId}
                    pan={deductorPan}
                    captchaRef={captchaRef}
                    port={portUpdate}
                    setSuccess={setSuccess}
                    preDefineRaiseData={raiseRequestPreData}
                    checkJustification={true}
                />
                : null}

            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckTracesCredentials(true)}
                />)
                : null
            }

            {checkTracesCredentials && port
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    handleProceed={() => {
                        setStartConnector(false);
                        setCheckTracesCredentials(false);
                    }}
                    tan={tan}
                    port={port}
                    setCaptchaRef={setCaptchaRef}
                    setVerifyCreds={() => null}
                    creds={null}
                />
                : null
            }

            {captchaRef && port
                ? (
                    <NoticeSyncData
                        deductorId={deductorId}
                        port={port}
                        captchaRef={captchaRef}
                        setSuccess={setSuccess}
                        setError={setError}
                        setShowSyncVerify={() => {
                            setStartConnector(false);
                            setCheckTracesCredentials(false);
                            setCaptchaRef(null);
                        }}
                        handleProceed={(syncStatus) => {

                            noticeOrderList.forEach((item) => {
                                if (tan === item.tan) {
                                    item.sync_status = syncStatus?.data?.action_status ?? item.syncStatus;
                                }
                            })
                            setNoticeOrderList([...noticeOrderList]);
                            setTimeout(() => {
                                getRecentDeductorAllList(true)
                            }, 10000)
                        }}

                    />
                )
                : null}

            {loading && <LoadingIndicator />}
            <div className='noticeOrderslistparentclass'>
                <div className="container-fluid">

                    <div className="row mt-2">
                        <div className="col-md-5">
                            <Link className="notice_order_backbtn" to={BASE_PATH}>&lt; Back to Deductor Master</Link>
                        </div>
                        <div className="col-md-7">
                            <h4 className="notice_order_titlfs">Notices & Orders</h4>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-md-12">
                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                <Grid
                                    className="table table-striped notices_orders_tb listofdedtb scrollvisible"
                                    data={noticeOrderList}
                                    {...dataState}

                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterEqOperator', operator: 'in' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                    }}
                                    rowRender={rowRender}
                                    sortable
                                >
                                    <Column
                                        width="130"
                                        field="deductor_name"
                                        title="Deductor Name"
                                        filter="text"
                                        columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="financial_year"
                                        title="FY"
                                        filter="dropdown"
                                        columnMenu={((props) => <ColumnMenu filterList={FinancialYear} hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('financial_year') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="quarter"
                                        title="Form | Qtr"
                                        filter="dropdown"
                                        columnMenu={((props) => <ColumnMenu filterList={QUARTER_LIST} hideSecondFilter {...props} />)}
                                        headerClassName={isColumnActive('quarter') ? 'active' : ''}
                                    />

                                    <Column
                                        width="100"
                                        field="issued_at"
                                        title="Date"
                                        filter="date"
                                        columnMenu={((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('issued_at') ? 'active' : ''}
                                    />
                                    <Column width="400" title="Communication Details" headerClassName='notices_orders_rowbgclr_yell' sortable={false}>
                                        <Column
                                            width="150"
                                            field="subject"
                                            title="Subject"
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('subject') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                            sortable={false}

                                        />
                                        <Column
                                            width="250"
                                            field="description"
                                            title="Description"
                                            filter="text"
                                            columnMenu={((props) => <ColumnMenu hideSecondFilter {...props} />)}
                                            headerClassName={isColumnActive('description') ? 'active notices_orders_rowbgclr_yell' : 'notices_orders_rowbgclr_yell'}
                                            sortable={false}

                                        />
                                    </Column>

                                    <Column
                                        width="100"
                                        field="demand_amtp"
                                        title="Demand Amount"
                                        filter="numeric"
                                        columnMenu={((props) => <ColumnMenu  {...props} />)}
                                        headerClassName={isColumnActive('demand_amtp') ? 'active notices_orders_rowbgclr_pink notices_orders_rowbordergrey' : 'notices_orders_rowbgclr_pink notices_orders_rowbordergrey'}
                                    />

                                    <Column width="150" title="Actions" sortable={false}>

                                        <Column
                                            width="20"
                                            title=""
                                            sortable={false}
                                        />

                                        <Column
                                            width="30"
                                            title=""
                                            sortable={false}
                                        />

                                        <Column
                                            width="30"
                                            title=""
                                            sortable={false}
                                        />

                                    </Column>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    {noticeOrderSummary?.summary_count > 0 &&
                        <SemanticPagination cursor={cursor} count="summary_count" pagelimit="15" summary={noticeOrderSummary} setSummaryStatus={setSummaryStatus} />
                    }
                </div>
            </div>

        </>
    )
}

export default NoticeOrders